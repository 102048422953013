import { Flexbox } from '@sede-x/shell-ds-react-framework';
import React from 'react';
export const DiffView = (props: {
  updatedCol: string;
  originalVal: string | number;
  updatedVal: string | number;
}) => {
  const { updatedCol, originalVal, updatedVal } = props;
  return (
    <Flexbox gap='10px 10px' style={styles.firstContent}>
      <fieldset>
        <legend>Hover on editable field to see values</legend>
        <div className='container'>
          <label className='label element'>
            <b className='label'>Field Name : </b>
            <input disabled className='fieldName element' value={updatedCol} />
          </label>
        </div>
        <div className='container'>
          <label className='label element'>
            <b className='label'>Original Value : </b>
            <input disabled className='originalVal element' value={originalVal} />
          </label>
        </div>

        <div className='container'>
          <label>
            <b className='label element'>Updated Value : </b>
            <input disabled className='updatedVal element' value={updatedVal} />
          </label>
        </div>
      </fieldset>
    </Flexbox>
  );
};
const styles = {
  firstContent: {
    marginRight: 'auto',
  },
  colChanged: {
    border: '2px solid #1cfc03',
  },
  helperInput: {
    width: '50px',
  },
};
