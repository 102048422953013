import React from 'react';
import {
  Body,
  ConfirmationState,
  StatusAlertParent,
  DataIngestionFailedParent,
  DataIngestionFailed,
  ADataLoad,
  DataHasFailedToLoadForRWrapper,
  DataHasFailedContainer,
  RegionAmericas,
  Americas,
  ForFurtherInformationOrToWrapper,
  ForFurtherInformationContainer,
  DstsApplicationSupportShell
} from './ReconIngetionFailure.style';

interface Log {
  ERROR_HEADING: string;
  ERROR_DESCRIPTION: string;
  REGION: string;
  COMMODITY : string;
}

interface DataIngestionAlertModalProps {
  log: Log
}

const ReconIngetionFailure: React.FC<DataIngestionAlertModalProps> = ({ log }) => {
  return (
    <Body>
      <ConfirmationState>
        <StatusAlertParent>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.2894 2.73608C12.7127 1.75463 11.2873 1.75464 10.7105 2.73608L0.704992 19.7632C0.109547 20.7765 0.867002 22 1.99444 22H22.0055C23.133 22 23.8904 20.7765 23.295 19.7632L13.2894 2.73608ZM2.8856 20L12 4.48942L21.1144 20H2.8856ZM11.5 18C11.2238 18 11 17.7761 11 17.5V16.5C11 16.2239 11.2238 16 11.5 16L12.5 16C12.7761 16 13 16.2239 13 16.5V17.5C13 17.7761 12.7761 18 12.5 18H11.5ZM12.5 9C12.7761 9 13 9.22386 13 9.5L13 13.5C13 13.7761 12.7761 14 12.5 14H11.5C11.2238 14 11 13.7761 11 13.5L11 9.5C11 9.22386 11.2238 9 11.5 9H12.5Z"
              fill="#DD1D21"
            />
          </svg>
          <DataIngestionFailedParent>
            <DataIngestionFailed>{log.ERROR_HEADING}</DataIngestionFailed>
            <ADataLoad>
              {log.ERROR_DESCRIPTION}
            </ADataLoad>
          </DataIngestionFailedParent>
          <DataHasFailedToLoadForRWrapper>
            <DataHasFailedContainer>
              <p>Data has failed to load for:</p>
              <RegionAmericas>
                <span>Region: </span>
                <Americas>{log.REGION}</Americas>
              </RegionAmericas>
              <RegionAmericas>
                <span>Commodity: </span>
                <Americas>{log.COMMODITY}</Americas>
              </RegionAmericas>
            </DataHasFailedContainer>
          </DataHasFailedToLoadForRWrapper>
        </StatusAlertParent>
        <ForFurtherInformationOrToWrapper>
          <ForFurtherInformationContainer>
            For further information or to raise an incident ticket with our support team, please email
            <DstsApplicationSupportShell> DST&SApplicationSupport@shell.com</DstsApplicationSupportShell>
          </ForFurtherInformationContainer>
        </ForFurtherInformationOrToWrapper>
      </ConfirmationState>
    </Body>
  );
};

export default ReconIngetionFailure;