import { ITableRowData } from '../../../Types/types';

export const mockIpuTableRows: ITableRowData[] = [
  {
    commodity: 'Pipeline Gas',
    delta: -440.362025159759,
    latest_estimate: 127.974510840241,
    plan: 568.336536,
    seIpuRegion: 'SE Americas',
  },
];

export const mockCommodityTableRows: ITableRowData[] = [
  {
    commodity: 'Pipeline Gas',
    delta: -440.362025159759,
    latest_estimate: 127.974510840241,
    plan: 568.336536,
    seIpuRegion: 'SE Americas',
  },
];
