import { IAnalyticsSnapshotReportType } from '../../Types/IAnalyticsSnapshotReportType';

export interface Country {
  COUNTRY_CODE: string;
  COUNTRY_NAME: string;
}
export const AnalyticsSnapshotReportInitialState: IAnalyticsSnapshotReportType = {
  error: {
    isError: false,
    message: '',
  },
  analyticsSnapshotReportChartData: [
    {
      YEAR: 0,
      QUARTER: '',
      MONTH: '',
      QTY: 0,
      REGION: '',
      Original_Transaction_Type: '',
    },
  ],
  analyticsSnapshotReportTableData: [
    {
      CPTY: '',
      CPTY_DESC: '',
      YEAR: 0,
      MONTH: '',
      QTY: 0,
      NCF_CATEGORY: '',
      DEAL_TYPE_NAME: '',
      NCF_SCOPE: '',
    },
  ],
  analyticsSnapshotReportFilteredValues: {
    year: [],
    quarter: [],
    month: [],
    region: [],
    countryCode: [],
    uom: 'TWh',
    commodity: ['PW'],
    reportStatus: 'APPROVED',
    snapshotReports: [],
    transactionType: 'NCF',
  },
  analyticsSnapshotReportFilterOptions: {
    year: [],
    quarter: [],
    region: [],
    countryCode: [],
    unitName: [],
    commodity: [],
    transactionType: [],
    regionCountryMapping: {} as Record<string, Country[]>,
    reportStatus: [],
    snapshotReports: [],
  },
  volumeByRegionChartData: {
    AMERICAS: [
      {
        PARAMETER_NAME: '',
        QTY: 0,
      },
    ],
  },
  volumeByRegionTableData: {
    AMERICAS: {
      data: [
        {
          YEAR: '',
          QUARTER: 1,
          MONTH: '',
        },
      ],

      totals: {
        TestParameter1: 0,
        TestParameter2: 0,
      },
    },
  },
  volumeByRegionDetailsTableData: [
    {
      YEAR: 0,
      QUARTER: '',
      MONTH: '',
      COMMODITY: '',
      TRANSACTION_TYPE: '',
      ORIGINAL_TRANSACTION_TYPE: '',
      NCF_CATEGORY: '',
      CPTY: '',
      CPTY_DESC: '',
      QTY: 0,
    },
  ],
  detailedViewParameter: {
    parameterName: '',
    region: '',
  },
};

export type IAnalyticsSnapshotReportDataState = typeof AnalyticsSnapshotReportInitialState;
