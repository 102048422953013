import React, { useContext, useEffect } from 'react';
import { ChartContainer } from './PPAInsightPieCharts.style';
import { GlassChart } from '@sede-x/glass-design-library';
import { AppContext } from '../../../../../Context/AppContext';
import { getVolumeByRegionData } from '../../PpaInsightUtils';
import { PPAInsightNoRecords } from '../../PPAInsightNoRecordsPage/PPAInsightNoRecords';

export const PPAInsightVolByRegionPieChart = () => {
  const { state: ppaInsightState, dispatch: ppaInsightDispatch } =
    useContext(AppContext).ppaInsight;
  useEffect(() => {
    getVolumeByRegionData(ppaInsightDispatch, ppaInsightState.filteredValues);
  }, [ppaInsightState.filteredValues]);

  const chartData = ppaInsightState.volumeByRegionData.map((item, index) => ({
    name: item.REGION,
    y: item.CAPACITY_EQUIVALENT,
    sliced: index === 0,
    selected: index === 0,
  }));

  const options = {
    title: {
      text: 'PPA Volume by Region',
      align: 'left',
    },
    subtitle: {
      text: 'An overview of volume by region',
      align: 'left',
    },
    chart: {
      type: 'pie',
    },
    series: [
      {
        name: 'Region',
        colorByPoint: true,
        data: chartData,
      },
    ],
  };
  return (
    <ChartContainer>
      {ppaInsightState.volumeByRegionData.length === 0 && <PPAInsightNoRecords />}
      {ppaInsightState.volumeByRegionData.length > 0 && <GlassChart options={options} />}
    </ChartContainer>
  );
};
