import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

export const StyledToastContainer = styled(ToastContainer)`
  &&&.Toastify__toast-container {
    width: fit-content;
  }
  .Toastify__toast {
    min-height: 0;
  }
  .Toastify__toast-body {
    padding: 0;
  }
  .Toastify__toast--success {
    font-size: 14px;
    font-family: Shell Font;
    border: 1px solid rgb(0, 133, 87);
    border-radius: 2px;
    color: rgb(52, 52, 52);
    background-color: rgb(239, 247, 242);
  }
  .Toastify__toast--error {
    font-size: 14px;
    font-family: Shell Font;
    color: rgb(52, 52, 52);
    border: 1px solid rgb(221, 29, 33);
    border-radius: 2px;
    color: rgb(52, 52, 52);
    background-color: rgb(255, 242, 240);
  }
  .Toastify__toast--info {
    background: rgba(241, 244, 249, 1);
  }
  .Toastify__toast--warning {
    background: rgba(255, 243, 229, 1);
  }
  .Toastify__toast-theme--light {
    color: rgba(52, 52, 52, 1);
  }
`;
