import { Flexbox, Grid, Modal, Text } from '@sede-x/shell-ds-react-framework';
import React, { useEffect, useState } from 'react';
import { FIVE_THOUSAND } from '../../../api/constants';

export const InternalServerErrorPage = () => {
  const [isShow, setIsShow] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShow(false);
    }, FIVE_THOUSAND); // 5000 milliseconds = 5 seconds

    // Clean up the timer when the component is unmounted
    return () => clearTimeout(timer);
  }, []);

  return (
    <Modal showHeader={false} open={isShow} onClose={() => setIsShow(false)}>
      <Grid justifyContent='center'>
        <Grid.Cell>
          <img src='../shell-logo.png' alt='secm' style={styles.img} />
        </Grid.Cell>
        <Grid.Cell>
          <Flexbox justifyContent='center'>
            <Text size='large'>Error</Text>
          </Flexbox>
        </Grid.Cell>
        <Grid.Cell>
          <Flexbox justifyContent='center'>
            Sorry, something went wrong. Please try again later.
          </Flexbox>
        </Grid.Cell>
      </Grid>
    </Modal>
  );
};

const styles = {
  title: {
    margin: '40px',
  },
  img: {
    height: '60px',
    margin: '20px auto',
    display: 'block',
  },
};
