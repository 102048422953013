import 'react-toastify/dist/ReactToastify.css';
import { StyledToastContainer } from './Toast.styles';

export const Toaster = () => (
  <StyledToastContainer
    position='bottom-right'
    autoClose={3000}
    hideProgressBar
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss={false}
    draggable={false}
    pauseOnHover={false}
    closeButton={false}
  />
);
