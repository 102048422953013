import { Flexbox, FormField, RangeDatePicker, Button } from '@sede-x/shell-ds-react-framework';
import {
  clearFilter,
  disabledFutureDate,
  getFilterFields,
  handleApplyFilter,
  handleDateChange,
} from './FilterFunctions';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import SelectFormField from './SelectFormField';
import {
  CommodityDetailsState,
  CommodityFilteredValue,
} from '../../redux/commodityDetails/commodityDetails.types';
import { useEffect, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FilterSection = () => {
  const [originalFilterData, setOriginalFilterData] = useState<CommodityFilteredValue[]>([]);
  const dispatch = useAppDispatch();
  const {
    carbonIntensityTarget,
    commodityVal,
    sauVal,
    evpVal,
    seIpuRegionVal,
    ipuVal,
    vpVal,
    bizVal,
    commodityOptions,
    sauOptions,
    evpOptions,
    seIpuRegionOptions,
    ipuOptions,
    vpOptions,
    businessOptions,
    disableHandleFilter,
    isFetchingEVP,
    isFetchingSeIpuRegion,
    isFetchingIPU,
    isFetchingVP,
    isFetchingBiz,
    isDisabledEVP,
    isDisabledSeIpuRegion,
    isDisabledSau,
    isDisabledIPU,
    isDisabledVP,
    isDisabledBiz,
    yearsRange,
    sauId,
    evpId,
    seIpuRegionId,
    ipuId,
    firstDate,
    lastDate,
    sauData,
    evpData,
    seIpuRegionData,
    ipuData,
    vpData,
    bizData,
    commodityFilteredValue,
    nonCommodityFilteredValue,
    masterCommodityFilteredValue,
  } = useAppSelector(
    (state: { commodityOverview: CommodityDetailsState }) => state.commodityOverview,
  );
  const {
    userRegionFlags,
    isCpmEvpFieldDisabled,
    isUserRegional,
    isCpmRegionFieldDisabled,
    userEvp,
  } = useAppSelector((state) => state.dashboard);
  // Call the getFilterFields function to get the filterFields array
  const filterFields = getFilterFields({
    commodityVal,
    sauVal,
    evpVal,
    seIpuRegionVal,
    ipuVal,
    vpVal,
    bizVal,
    commodityOptions,
    sauOptions,
    evpOptions,
    seIpuRegionOptions,
    ipuOptions,
    vpOptions,
    businessOptions,
    disableHandleFilter,
    isFetchingEVP,
    isFetchingSeIpuRegion,
    isFetchingIPU,
    isFetchingVP,
    isFetchingBiz,
    isDisabledEVP,
    isDisabledSeIpuRegion,
    isDisabledSau,
    isDisabledIPU,
    isDisabledVP,
    isDisabledBiz,
    firstDate,
    lastDate,
    carbonIntensityTarget,
    sauData,
    evpData,
    seIpuRegionData,
    ipuData,
    vpData,
    bizData,
    dispatch,
    nonCommodityFilteredValue,
    commodityFilteredValue,
    masterCommodityFilteredValue,
    sauId,
    evpId,
    seIpuRegionId,
    ipuId,
    userRegionFlags,
    isCpmEvpFieldDisabled,
    isUserRegional,
    isCpmRegionFieldDisabled,
    userEvp,
  });

  const applyFilter = () => {
    const filterData = originalFilterData.length ? originalFilterData : commodityFilteredValue;
    !originalFilterData.length && setOriginalFilterData(commodityFilteredValue);
    handleApplyFilter(filterData, firstDate, lastDate, dispatch);
  };

  useEffect(() => {
    // When onChange click, empty the originalFilterData.
    if (disableHandleFilter) {
      setOriginalFilterData([]);
    }
  }, [disableHandleFilter]);

  return (
    <Flexbox flexDirection='column'>
      <div className='filterName'>Filters</div>
      {filterFields.map((field) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <SelectFormField key={field.id} {...field} />
      ))}

      <FormField id='year' label='Year'>
        <RangeDatePicker
          disabledDate={disabledFutureDate}
          placeholder={['Start Year', 'End Year']}
          picker='year'
          format='YYYY'
          value={yearsRange}
          onChange={(e) => handleDateChange(e, dispatch)}
        />
      </FormField>

      <Button className='getDataBtn' onClick={applyFilter} disabled={disableHandleFilter}>
        Apply Filters
      </Button>

      <Button
        className='getDataBtn'
        variant='outlined'
        style={{ marginTop: '10px' }}
        onClick={() =>
          clearFilter(
            dispatch,
            masterCommodityFilteredValue,
            carbonIntensityTarget,
            isCpmEvpFieldDisabled,
            isCpmRegionFieldDisabled,
          )
        }
      >
        Clear Filters
      </Button>
    </Flexbox>
  );
};

export default FilterSection;
