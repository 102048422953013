import { debounce } from 'lodash';
import { I_ALIGNE_TABLE, THOUSAND, THOUSAND_FIVE_HUNDRED } from '../../../api/constants';
import {
  fetchConfigRuleReport,
  fetchCommodities,
  fetchStorage,
  fetchDealType,
  addConfigRule,
  fetchCptySearchResult,
  deleteConfigRule,
} from '../../../api/services';
import mockOptions from '../../../api/TableEditOptions';
import {
  RuleConfigDataI,
  IConfigRuleTableData,
  IConfigRuleNewRecord,
  Action,
  OptionI,
} from '../../../Types/types';
import { showSpinner, hideSpinner } from '../../Utils/utils';
import { Dispatch, SetStateAction } from 'react';
import { IEditFeatureDataState } from '../../../Reducers/InitialStates/EditFeatureInitialState';

export const updateConfigRulePaginationData = async (
  current: number,
  dispatch: Dispatch<Action>,
  setStateVars: {
    setLoadingMessage: Dispatch<SetStateAction<string>>;
    setPaginationNumber: Dispatch<SetStateAction<number>>;
    setConfigRulePaginationData: Dispatch<SetStateAction<IConfigRuleTableData[]>>;
    setTotalCount: Dispatch<SetStateAction<number>>;
    setIsServiceError: Dispatch<SetStateAction<boolean>>;
  },
  tradeTypeForDel?: string,
  cptyDescForDelete?: string,
  tradeTypeForDelete?: string,
  cptyForDelete?: string,
): Promise<void> => {
  const {
    setLoadingMessage,
    setPaginationNumber,
    setConfigRulePaginationData,
    setTotalCount,
    setIsServiceError,
  } = setStateVars;
  //console statement - updating table
  showSpinner(dispatch);
  setLoadingMessage('Sit tight while we are fetching configuration rules for you.');
  let delTradeType = '';
  if (tradeTypeForDel) {
    delTradeType = tradeTypeForDel;
  } else if (tradeTypeForDelete) {
    delTradeType = tradeTypeForDelete.toUpperCase() === 'POWER' ? 'PW' : 'NG';
  }
  const delCptyDesc = cptyDescForDelete ?? cptyForDelete ?? '';
  const options: RuleConfigDataI = {
    pageNumber: current,
    pageSize: 5,
    tradeType: delTradeType,
    cptyShortName: delCptyDesc,
  };
  setPaginationNumber(current);
  try {
    const viewConfigRuleData = await fetchConfigRuleReport(options);
    setConfigRulePaginationData(viewConfigRuleData.data.data);
    setTotalCount(viewConfigRuleData.data.count);
    hideSpinner(dispatch);
  } catch (e) {
    hideSpinner(dispatch);
    setIsServiceError(true);
  }
};

export const fetchAllTradeTypes = async (
  tableName: string,
  setTradeTypeOptions: Dispatch<SetStateAction<OptionI[]>>,
) => {
  const tradeTypeOptns = [];
  const tradeTypesResp = await fetchCommodities(tableName);
  const tradeTypeOptionsArray = tradeTypesResp.data;
  for (const tradeOption of tradeTypeOptionsArray) {
    tradeTypeOptns.push({
      value: setTradeTypeVal(tradeOption),
      label: setTradeTypeVal(tradeOption),
    });
  }
  setTradeTypeOptions(tradeTypeOptns);
};

export const setTradeTypeVal = (tradeVal: string) => {
  if (tradeVal.toUpperCase() === 'PW') {
    return 'Power';
  } else if (tradeVal.toUpperCase() === 'NG') {
    return 'Natural Gas';
  } else {
    return tradeVal;
  }
};

export const handleAddNewRecord = async (
  configRulePaginationData: IConfigRuleTableData[],
  dispatch: Dispatch<Action>,
  tableName: string,
  setStateVars: {
    setLoadingMessage: Dispatch<SetStateAction<string>>;
    setStorageOptions: Dispatch<SetStateAction<OptionI[]>>;
    setBuySellOption: Dispatch<SetStateAction<OptionI[]>>;
    setIsAddNewRecord: Dispatch<SetStateAction<boolean>>;
    setConfigRulePaginationData: Dispatch<SetStateAction<IConfigRuleTableData[]>>;
  },
) => {
  const data = [...configRulePaginationData];
  const {
    setLoadingMessage,
    setStorageOptions,
    setBuySellOption,
    setIsAddNewRecord,
    setConfigRulePaginationData,
  } = setStateVars;
  setIsAddNewRecord(true);
  showSpinner(dispatch);
  setLoadingMessage('Let us get the options for you to add new rule.');
  // api calls to retrieve drop down values for counter party short name, trade type, market, storage.
  try {
    const storageOption = [];
    const buySellOptions = [];

    const storageResp = await fetchStorage(tableName);
    const buySellResp = await fetchDealType(tableName);

    const storageOptns = storageResp.data;
    const buySellOptns = buySellResp.data;

    for (const storageOptionData of storageOptns) {
      storageOption.push({
        value: storageOptionData,
        label: storageOptionData,
      });
    }
    for (const buySellData of buySellOptns) {
      buySellOptions.push({
        value: buySellData.toUpperCase(),
        label: buySellData.toUpperCase(),
      });
    }

    setStorageOptions(storageOption);
    setBuySellOption(buySellOptions);
  } catch {
    //error statement
  }
  hideSpinner(dispatch);
  const newRow: IConfigRuleTableData = mockOptions.ruleConfigNewRecordObject;
  data.unshift(newRow);
  setConfigRulePaginationData(data);
};

export const handleDeleteRule = (
  setShowDeleteRuleFilters: Dispatch<SetStateAction<boolean>>,
  setShowRadioButton: Dispatch<SetStateAction<boolean>>,
) => {
  setShowDeleteRuleFilters(true);
  setShowRadioButton(true);
};

export const handleValAndSaveBtnClick = (
  stateVars: {
    newCptyShortName: string;
    newTradeType: string;
    newMarket: string;
    newStorage: string;
    newStartDate: string;
    newEndDate: string;
    newChangeAttribute: string;
    newChangeFrom: string;
    newChangeTo: string;
    isAddNewRecord: boolean;
  },
  setStateVars: {
    setIsValidationError: Dispatch<SetStateAction<boolean>>;
    setIsValAndSaveBtnClickedWithoutErrors: Dispatch<SetStateAction<boolean>>;
    setIsAllFieldsValidated: Dispatch<SetStateAction<boolean>>;
    setIsPrevAndNewMarketSame: Dispatch<SetStateAction<boolean>>;
    setNewRecord: Dispatch<SetStateAction<IConfigRuleNewRecord>>;
  },
  state: IEditFeatureDataState,
) => {
  const {
    newCptyShortName,
    newTradeType,
    newMarket,
    newStorage,
    newStartDate,
    newEndDate,
    newChangeAttribute,
    newChangeFrom,
    newChangeTo,
    isAddNewRecord,
  } = stateVars;

  const {
    setIsValidationError,
    setIsValAndSaveBtnClickedWithoutErrors,
    setIsAllFieldsValidated,
    setIsPrevAndNewMarketSame,
    setNewRecord,
  } = setStateVars;
  const isAnyFieldEmpty = [
    newCptyShortName,
    newTradeType,
    newMarket,
    newStorage,
    newStartDate,
    newEndDate,
    newChangeAttribute,
    newChangeFrom,
    newChangeTo,
  ].some((val) => val === '');
  const isAddNewRecordInvalid = isAddNewRecord && isAnyFieldEmpty;
  const isBothMarketEqual = newMarket === newChangeTo;

  if (isAddNewRecordInvalid || isBothMarketEqual) {
    setIsValidationError(true);
    setIsValAndSaveBtnClickedWithoutErrors(false);
    setIsAllFieldsValidated(false);
    if (isBothMarketEqual) {
      setIsPrevAndNewMarketSame(true);
    } else {
      setIsPrevAndNewMarketSame(false);
    }
  } else {
    setIsValidationError(false);
    setIsValAndSaveBtnClickedWithoutErrors(true);
    const finalNewTradeType = newTradeType.toLowerCase() === 'power' ? 'PW' : 'NG';
    const newData: IConfigRuleNewRecord = {
      region: 'EU',
      tableName: I_ALIGNE_TABLE,
      counterpartyShortName: newCptyShortName,
      tradeType: finalNewTradeType,
      market: newMarket,
      storage: newStorage,
      startDate: newStartDate,
      endDate: newEndDate,
      changeAttribute: newChangeAttribute,
      changeFrom: newChangeFrom,
      changeTo: newChangeTo,
      createdBy: state.userMail,
      updatedBy: state.userMail,
    };
    setNewRecord(newData);
    setIsAllFieldsValidated(true);
  }
};

export const resetStates = (setStateVars: {
  setNewCptyShortName: Dispatch<SetStateAction<string>>;
  setNewTradeType: Dispatch<SetStateAction<string>>;
  setNewMarket: Dispatch<SetStateAction<string>>;
  setNewStorage: Dispatch<SetStateAction<string>>;
  setNewChangeAttribute: Dispatch<SetStateAction<string>>;
  setNewChangeFrom: Dispatch<SetStateAction<string>>;
  setNewChangeTo: Dispatch<SetStateAction<string>>;
  setCheckedID: Dispatch<SetStateAction<number>>;
  setMarketSearchOptions: Dispatch<SetStateAction<string[]>>;
  setCptyOptions: Dispatch<SetStateAction<string[]>>;
  setIsValidationError: Dispatch<SetStateAction<boolean>>;
  setIsValAndSaveBtnClickedWithoutErrors: Dispatch<SetStateAction<boolean>>;
}) => {
  const {
    setNewCptyShortName,
    setNewTradeType,
    setNewMarket,
    setNewStorage,
    setNewChangeAttribute,
    setNewChangeFrom,
    setNewChangeTo,
    setCheckedID,
    setMarketSearchOptions,
    setCptyOptions,
    setIsValidationError,
    setIsValAndSaveBtnClickedWithoutErrors,
  } = setStateVars;
  // set all states to default values
  setNewCptyShortName('');
  setNewTradeType('');
  setNewMarket('');
  setNewStorage('');
  setNewChangeAttribute('');
  setNewChangeFrom('');
  setNewChangeTo('');
  setCheckedID(-1);
  setMarketSearchOptions([]);
  setCptyOptions([]);
  setIsValidationError(false);
  setIsValAndSaveBtnClickedWithoutErrors(false);
};

export const handlePublishDataBtnClick = async (
  dispatch: Dispatch<Action>,
  newRecord: IConfigRuleNewRecord,
  setStateVars: {
    setIsAddNewRecord: Dispatch<SetStateAction<boolean>>;
    setIsPublished: Dispatch<SetStateAction<boolean>>;
    setIsPublishFailure: Dispatch<SetStateAction<boolean>>;
    setIsAllFieldsValidated: Dispatch<SetStateAction<boolean>>;
    setNewCptyShortName: Dispatch<SetStateAction<string>>;
    setNewTradeType: Dispatch<SetStateAction<string>>;
    setNewMarket: Dispatch<SetStateAction<string>>;
    setNewStorage: Dispatch<SetStateAction<string>>;
    setNewChangeAttribute: Dispatch<SetStateAction<string>>;
    setNewChangeFrom: Dispatch<SetStateAction<string>>;
    setNewChangeTo: Dispatch<SetStateAction<string>>;
    setCheckedID: Dispatch<SetStateAction<number>>;
    setMarketSearchOptions: Dispatch<SetStateAction<string[]>>;
    setCptyOptions: Dispatch<SetStateAction<string[]>>;
    setIsValidationError: Dispatch<SetStateAction<boolean>>;
    setIsValAndSaveBtnClickedWithoutErrors: Dispatch<SetStateAction<boolean>>;
    setLoadingMessage: Dispatch<SetStateAction<string>>;
    setPaginationNumber: Dispatch<SetStateAction<number>>;
    setConfigRulePaginationData: Dispatch<SetStateAction<IConfigRuleTableData[]>>;
    setTotalCount: Dispatch<SetStateAction<number>>;
    setIsServiceError: Dispatch<SetStateAction<boolean>>;
  },
) => {
  const {
    setIsAddNewRecord,
    setIsPublished,
    setIsPublishFailure,
    setIsAllFieldsValidated,
    setLoadingMessage,
    setPaginationNumber,
    setConfigRulePaginationData,
    setTotalCount,
    setIsServiceError,
  } = setStateVars;
  showSpinner(dispatch);
  setLoadingMessage('It takes little while to save a new data.');
  try {
    await addConfigRule(newRecord);
    setIsAddNewRecord(false);
    setIsPublished(true);
    resetStates(setStateVars);
    updateConfigRulePaginationData(1, dispatch, {
      setLoadingMessage,
      setPaginationNumber,
      setConfigRulePaginationData,
      setTotalCount,
      setIsServiceError,
    });
  } catch {
    setIsPublishFailure(true);
  }
  hideSpinner(dispatch);
  setIsAllFieldsValidated(false);
};

export const setValAndSaveDisabled = (
  isAddNewRecord: boolean,
  isValAndSaveBtnClickedWithoutErrors: boolean,
) => {
  if (isAddNewRecord) {
    return isValAndSaveBtnClickedWithoutErrors;
  } else {
    return true;
  }
};

export const setPublishBtnDisabled = (
  isAddNewRecord: boolean,
  isValAndSaveBtnClickedWithoutErrors: boolean,
  checkedID: number,
) => {
  if (isAddNewRecord && isValAndSaveBtnClickedWithoutErrors) {
    return false;
  } else if (checkedID > 0 && isValAndSaveBtnClickedWithoutErrors) {
    return false;
  } else {
    return true;
  }
};

export const innerOnClose = (
  isPublishFailure: boolean,
  setIsPublishFailure: Dispatch<SetStateAction<boolean>>,
  isPublished: boolean,
  setIsPublished: Dispatch<SetStateAction<boolean>>,
  isServErrOnEditClick: boolean,
  setIisServErrOnEditClick: Dispatch<SetStateAction<boolean>>,
) => {
  if (isPublishFailure) {
    setIsPublishFailure(false);
  }
  if (isPublished) {
    setIsPublished(false);
  }
  if (isServErrOnEditClick) {
    setIisServErrOnEditClick(false);
  }
};

export const isShowPagination = (
  configRulePaginationData: IConfigRuleTableData[],
  isAddNewRecord: boolean,
) => {
  return configRulePaginationData.length > 0 && !isAddNewRecord;
};

export const handleApplyFilter = (
  dispatch: Dispatch<Action>,
  setStateVars: {
    setLoadingMessage: Dispatch<SetStateAction<string>>;
    setPaginationNumber: Dispatch<SetStateAction<number>>;
    setConfigRulePaginationData: Dispatch<SetStateAction<IConfigRuleTableData[]>>;
    setTotalCount: Dispatch<SetStateAction<number>>;
    setIsServiceError: Dispatch<SetStateAction<boolean>>;
    setRadioChecked: Dispatch<SetStateAction<number>>;
  },
) => {
  const {
    setLoadingMessage,
    setPaginationNumber,
    setConfigRulePaginationData,
    setTotalCount,
    setIsServiceError,
    setRadioChecked,
  } = setStateVars;
  updateConfigRulePaginationData(1, dispatch, {
    setLoadingMessage,
    setPaginationNumber,
    setConfigRulePaginationData,
    setTotalCount,
    setIsServiceError,
  });
  setRadioChecked(-1);
};

export const onCptySearch = debounce(
  async (
    query: string,
    setIsSearchingCpty: Dispatch<SetStateAction<boolean>>,
    setCptyOptions: Dispatch<SetStateAction<string[]>>,
  ) => {
    const options = {
      tableName: I_ALIGNE_TABLE,
      keyword: query.toUpperCase(),
    };
    if (query.length > 1) {
      try {
        setIsSearchingCpty(true);
        const cptyDescSearch = await fetchCptySearchResult(options);
        const cptySearchResult = cptyDescSearch.data;
        setCptyOptions(cptySearchResult);
      } catch (e) {
        //error statement
      }
      setIsSearchingCpty(false);
    }
  },
  THOUSAND,
);

export const handleCancelDeleteFlow = (
  dispatch: Dispatch<Action>,
  tradeTypeForDelete: string,
  cptyForDelete: string,
  setStateVars: {
    setShowDeleteRuleFilters: Dispatch<SetStateAction<boolean>>;
    setShowRadioButton: Dispatch<SetStateAction<boolean>>;
    setTradeTypeForDelete: Dispatch<SetStateAction<string>>;
    setCptyForDelete: Dispatch<SetStateAction<string>>;
    setRadioChecked: Dispatch<SetStateAction<number>>;
    setLoadingMessage: Dispatch<SetStateAction<string>>;
    setPaginationNumber: Dispatch<SetStateAction<number>>;
    setConfigRulePaginationData: Dispatch<SetStateAction<IConfigRuleTableData[]>>;
    setTotalCount: Dispatch<SetStateAction<number>>;
    setIsServiceError: Dispatch<SetStateAction<boolean>>;
  },
) => {
  const {
    setShowDeleteRuleFilters,
    setShowRadioButton,
    setTradeTypeForDelete,
    setCptyForDelete,
    setRadioChecked,
    setLoadingMessage,
    setPaginationNumber,
    setConfigRulePaginationData,
    setTotalCount,
    setIsServiceError,
  } = setStateVars;
  setShowDeleteRuleFilters(false);
  setShowRadioButton(false);
  setTradeTypeForDelete('');
  setCptyForDelete('');
  setRadioChecked(-1);
  updateConfigRulePaginationData(
    1,
    dispatch,
    {
      setLoadingMessage,
      setPaginationNumber,
      setConfigRulePaginationData,
      setTotalCount,
      setIsServiceError,
    },
    '',
    '',
    tradeTypeForDelete,
    cptyForDelete,
  );
};

export const handleFinalDelete = async (
  dispatch: Dispatch<Action>,
  radioChecked: number,
  tradeTypeForDelete: string,
  cptyForDelete: string,
  setStateVars: {
    setLoadingMessage: Dispatch<SetStateAction<string>>;
    setIsDeleteSuccess: Dispatch<SetStateAction<boolean>>;
    setShowConfirmDeleteModal: Dispatch<SetStateAction<boolean>>;
    setShowDeleteRuleFilters: Dispatch<SetStateAction<boolean>>;
    setShowRadioButton: Dispatch<SetStateAction<boolean>>;
    setTradeTypeForDelete: Dispatch<SetStateAction<string>>;
    setCptyForDelete: Dispatch<SetStateAction<string>>;
    setRadioChecked: Dispatch<SetStateAction<number>>;
    setPaginationNumber: Dispatch<SetStateAction<number>>;
    setConfigRulePaginationData: Dispatch<SetStateAction<IConfigRuleTableData[]>>;
    setTotalCount: Dispatch<SetStateAction<number>>;
    setIsServiceError: Dispatch<SetStateAction<boolean>>;
  },
) => {
  const {
    setLoadingMessage,
    setIsDeleteSuccess,
    setShowConfirmDeleteModal,
    setShowDeleteRuleFilters,
    setShowRadioButton,
    setTradeTypeForDelete,
    setCptyForDelete,
    setRadioChecked,
    setPaginationNumber,
    setConfigRulePaginationData,
    setTotalCount,
    setIsServiceError,
  } = setStateVars;
  showSpinner(dispatch);
  setLoadingMessage('Deleting Rule');
  try {
    await deleteConfigRule(radioChecked);
    setIsDeleteSuccess(true);
    setTimeout(() => {
      setIsDeleteSuccess(false);
    }, THOUSAND_FIVE_HUNDRED);
    handleCancelDeleteFlow(dispatch, tradeTypeForDelete, cptyForDelete, {
      setShowDeleteRuleFilters,
      setShowRadioButton,
      setTradeTypeForDelete,
      setCptyForDelete,
      setRadioChecked,
      setLoadingMessage,
      setPaginationNumber,
      setConfigRulePaginationData,
      setTotalCount,
      setIsServiceError,
    });
    setShowConfirmDeleteModal(false);
    updateConfigRulePaginationData(1, dispatch, {
      setLoadingMessage,
      setPaginationNumber,
      setConfigRulePaginationData,
      setTotalCount,
      setIsServiceError,
    });
  } catch (e) {
    //console error
  }
  hideSpinner(dispatch);
};
