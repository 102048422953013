import { Card, Flexbox, Grid } from '@sede-x/shell-ds-react-framework';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { UpdateInfo } from './cardUtils';
import { ActionButton } from './ActionButtons';
import { DownloadInProgressToast } from './DownloadInProgressToast';
import { SECTORAL_STEP01_DESC } from '../../../api/constants';

export const CptyListExtractCard = (props: {
  cardLog: string;
  setIsServerError: Dispatch<SetStateAction<boolean>>;
}) => {
  const [logInfo, setLogInfo] = useState('');
  const [isDownloadInProgress, setIsDownloadInProgress] = useState(false);
  return (
    <Card
      className='card'
      data-testid='cpty-list-extract-card'
      bodyPadding={true}
      header={{
        meta: 'Step 01',
        title: 'Counterparty List Extract',
        description: SECTORAL_STEP01_DESC,
      }}
      style={{
        borderRadius: '4px',
        background: 'var(--Background-Surface, #FFF)',

        /* Elevation/Level 2 */
        boxShadow: '0px 6px 24px 0px rgba(0, 0, 0, 0.06), 0px 2px 6px 0px rgba(0, 0, 0, 0.10)',
      }}
    >
      <Grid
        style={{
          borderTop: '1px solid var(--Border-Subtle, rgba(28, 28, 28, 0.04))',
          paddingTop: '16px',
        }}
      >
        <Grid.Cell>
          <Grid flow='column' justifyContent='space-between'>
            <Grid.Cell>
              {isDownloadInProgress && <DownloadInProgressToast />}
              <UpdateInfo text={logInfo !== '' ? logInfo : props.cardLog} />
            </Grid.Cell>
            <Grid.Cell>
              <Flexbox gap='20px' justifyContent='center'>
                <ActionButton
                  content='Download Entire CTPY list'
                  variants='outlined'
                  buttonKey='entire'
                  setIsServerError={props.setIsServerError}
                  setLogInfo={setLogInfo}
                  setIsStep01DownlaodInProgress={setIsDownloadInProgress}
                />
                <ActionButton
                  content='Download Unmapped CTPY list'
                  variants='filled'
                  buttonKey='unmapped'
                  setIsServerError={props.setIsServerError}
                  setLogInfo={setLogInfo}
                  setIsStep01DownlaodInProgress={setIsDownloadInProgress}
                />
              </Flexbox>
            </Grid.Cell>
          </Grid>
        </Grid.Cell>
      </Grid>
    </Card>
  );
};
