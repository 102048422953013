import React from 'react';
import { Grid, Flexbox, Divider } from '@sede-x/shell-ds-react-framework';
import 'leaflet/dist/leaflet.css';
import { TopCtpyFilterSection } from './TopCtpyFilterSection';
import DateSideCard from '../AnalyticsHome/DateSideCard/DateSideCard';
import AllCtpyTable from './TopCtpyCharts/TopCtpyTable/AllCtpyTable';
import NcfReportingHomeHeader from '../NcfReportingUtils/NcfReportingHomeHeader/NcfReportingHomeHeader';

export const AllCounterParties = () => {
  const breadcrumbItems = [
    { name: 'Ncf Reporting', path: '/ncf-reporting' },
    { name: 'All Counterparties' },
  ];
  return (
    <div style={{ height: '100%', background: '#F5F5F5' }} data-testid='top-counter-parties'>
      <Flexbox gap='24px 24px' style={{ marginLeft: '15px', justifyContent: 'start' }}>
        <TopCtpyFilterSection />
        <Grid gap='10px'>
          <Grid.Cell>
            <Flexbox justifyContent='space-between'>
              <NcfReportingHomeHeader
                title='All Counterparties'
                description='List of all Counterparties based on Quantity'
                breadcrumbItems={breadcrumbItems}
              />
              <DateSideCard />
            </Flexbox>
          </Grid.Cell>
          <Divider size='medium' direction='horizontal' />
          <Grid.Cell>
            <AllCtpyTable />
          </Grid.Cell>
        </Grid>
      </Flexbox>
    </div>
  );
};
