import React, { useContext, useEffect, useState } from 'react'; // Step 1: Import useState
import { Grid, Flexbox, Divider } from '@sede-x/shell-ds-react-framework';
import 'leaflet/dist/leaflet.css';
import { PptFilterSection } from './PptFilterSection';
import { AppContext } from '../../../../Context/AppContext';
import NcfReportingHomeHeader from '../NcfReportingUtils/NcfReportingHomeHeader/NcfReportingHomeHeader';
import PptReportingBarChart from './PptReportingCharts/PptReportingBarChart/PptReportingBarChart';
import { SnapshotFilterSection } from './PptReportingCharts/PptReportingSnpShtFltr/PptReportingSnpShtFltr';
import { PptInScopeNcfCatgChart } from './PptReportingCharts/PptReportingPieChart/PptInScopeNcfCatg';
import { PptInScopeVsOutScope } from './PptReportingCharts/PptReportingPieChart/PptInScopeVsOutScope';
import {
  pptNcfCategory,
  pptNcfVolume,
  pptReports,
  pptInScopeVsOutScopeVolume,
} from '../../../../api/services';
import Loader from '../../../Loader/Loader';

export const PptReportingHome = () => {
  const { state: appDataState, dispatch: appDataDispatch } = useContext(AppContext).appData;
  const { state: pptState, dispatch: pptDispatch } = useContext(AppContext).pptReports;
  const [isLoading, setIsLoading] = useState(true); // Step 3: Initialize isLoading state

  useEffect(() => {
    appDataDispatch({ type: 'setCurrentPage', payload: '' });
  }, [appDataDispatch]);

  useEffect(() => {
    if (appDataState.userRegion !== '') {
      pptDispatch({
        type: 'setPptfilteredValues',
        payload: {
          ...pptState.PptfilteredValues,
          region: [appDataState.userRegion],
        },
      });
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Step 4: Set isLoading to true at the start
      try {
        let options = { ...pptState.PptfilteredValues };

        if (appDataState.userRegion !== '') {
          options = { ...options, region: [appDataState.userRegion] };
        }

        const volumeResponse = await pptNcfVolume(options);
        const categoryResponse = await pptNcfCategory(options);
        const pptInScopeVsOutScopeVolumeResponse = await pptInScopeVsOutScopeVolume(options);

        pptDispatch({ type: 'setPptNcfVolumes', payload: volumeResponse.data });
        pptDispatch({
          type: 'setPptNcfCategories',
          payload: categoryResponse.data,
        });
        pptDispatch({
          type: 'setPptInScopeVsOutScopeVolume',
          payload: pptInScopeVsOutScopeVolumeResponse.data,
        });
      } catch (error) {
        // Log error once toast feature is implemented
      } finally {
        setIsLoading(false); // Step 4: Set isLoading to false after fetching data
      }
    };

    fetchData();
  }, [pptDispatch, pptState.PptfilteredValues]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Step 4: Set isLoading to true at the start
      try {
        let options = { ...pptState.PptfilteredValues };
        if (appDataState.userRegion !== '') {
          options = { ...options, region: [appDataState.userRegion] };
        }
        const reportsResponse = await pptReports(options);
        pptDispatch({
          type: 'setPptReportNames',
          payload: reportsResponse.data,
        });
      } catch (error) {
        // Log error once toast feature is implemented
      } finally {
        setIsLoading(false); // Step 4: Set isLoading to false after fetching data
      }
    };
    fetchData();
  }, []);




  const snapshotReports = pptState.PptfilteredValues.snapshotReports || [];

  const hasPower = snapshotReports.some(report => report.includes('_PW_'));
  const hasNaturalGas = snapshotReports.some(report => report.includes('_NG_'));
  const showBoth = snapshotReports.length === 0 || (hasPower && hasNaturalGas);

  return (
    <div style={{ height: '100%', background: '#F5F5F5' }} data-testid='report-breakdown'>
      {isLoading && <Loader loader={true} message={'Loading...'} />}

      <Flexbox gap='24px 24px' style={{ marginLeft: '15px', justifyContent: 'start' }}>
        <PptFilterSection />
        <Grid gap='10px'>
          <Grid.Cell>
            <Flexbox justifyContent='space-between'>
              <NcfReportingHomeHeader title='Report Breakdown' description='Detailed Report breakdown for Historic Gas and Power Volumes' />
            </Flexbox>
          </Grid.Cell>
          <Divider size='medium' direction='horizontal' />
          <Grid.Cell>
            <SnapshotFilterSection />
          </Grid.Cell>
          <Grid.Cell>
            <PptReportingBarChart />
          </Grid.Cell>
          <Grid.Cell>
            <Flexbox gap='10px'>
            {showBoth || hasNaturalGas ? <PptInScopeVsOutScope tradeName='Natural Gas' /> : null}
            {showBoth || hasPower ? <PptInScopeVsOutScope tradeName='Power' /> : null}
            </Flexbox>
          </Grid.Cell>
          <Grid.Cell>
            <Flexbox gap='10px'>
            {showBoth || hasNaturalGas ? <PptInScopeNcfCatgChart tradeName='Natural Gas' /> : null}
            {showBoth || hasPower ? <PptInScopeNcfCatgChart tradeName='Power' /> : null}
            </Flexbox>
          </Grid.Cell>
        </Grid>
      </Flexbox>
    </div>
  );
};
