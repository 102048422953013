import React from 'react';
import { Button, Sizes } from '@sede-x/shell-ds-react-framework';
import { unparse } from 'papaparse';
import { DOWNLOAD_ERROR } from '../../../../CarbonDashboard/utils/constants';
import { notify } from '../../../../CarbonDashboard/components/Toast/notify';
import { DownloadCloud } from '@sede-x/shell-ds-react-framework/build/esm/components/Icon/components';

type DownloadCsvButtonProps = {
  data: object[];
  fileName: string;
};

const DownloadCsvButton: React.FC<DownloadCsvButtonProps> = ({ data, fileName }) => {
  const handleDownload = () => {
    if (!data?.length) {
      notify('info', 'No data to download');
      return;
    }

    try {
      // Convert JSON object to CSV string using papaparse
      const csvString = unparse(data);
      // Create a Blob from the CSV string
      const blob = new Blob([csvString], { type: 'text/csv' });

      // Create a link element
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;

      // Programmatically click the link to trigger the download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      notify('error', DOWNLOAD_ERROR);
    }
  };
  return (
    <Button
      icon={<DownloadCloud />}
      iconOnly
      size={Sizes.Medium}
      onClick={handleDownload}
      variant='transparent'
    >
      Download
    </Button>
  );
};

export default DownloadCsvButton;
