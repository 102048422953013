import React from 'react';
import { TCETileContainer, MTitle, CEdata, NciTileFooter } from '../CarbonDashBoard.styles';
import { NET_CARBON_INTENSITY, two } from '../../../constants';
import Carbonemissionstat from '../../Carbonemissionstat';
import { INciTileData } from '../../../Types/types';
import { currentYear } from '../../../utils/utils';
import { lightenColor } from '../../../utils';

interface NciTileCardProps {
  data: INciTileData;
}
const NciTileCard: React.FC<NciTileCardProps> = ({ data }) => {
  const statusLabel2 = data?.unit;
  const nciValue = 72.4; // As per business value 73.4 is hard coded for now. When they ask will remove value and get dynamic value.
  const statusLabel = `LE3 ${nciValue?.toFixed(two)}`;
  let textcolor = '';
  const lightenAmount = 90;
  if (nciValue < data?.lowerNciTarget) {
    textcolor = '#008000';
  } else if (nciValue > data?.lowerNciTarget && nciValue < data?.higherNciTarget) {
    textcolor = '#7CFC00';
  } else if (nciValue > data?.higherNciTarget) {
    textcolor = '#FF0000';
  }
  const statusBgColor = lightenColor(
    textcolor === '#7CFC00' ? '#008000' : textcolor,
    lightenAmount,
  );

  return (
    <TCETileContainer>
      <MTitle>{`${NET_CARBON_INTENSITY} (${currentYear})`}</MTitle>
      <CEdata>
        <Carbonemissionstat
          statusLabel2={statusLabel2}
          statusLabel={statusLabel}
          statusBgColor={statusBgColor}
          textcolor={textcolor}
        />
      </CEdata>
      <NciTileFooter>
        Group NCI Target Range: {data?.lowerNciTarget} - {data?.higherNciTarget} {statusLabel2}
      </NciTileFooter>
    </TCETileContainer>
  );
};
export default NciTileCard;
