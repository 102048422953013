import styled from 'styled-components';

export const SideCard = styled.div`
  display: flex;
  flex-direction: column; // Stacks children vertically
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 10px;
  height: auto; // Adjusts height based on content
  background-color: white;
  padding: 15px;
  margin-top: 46px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  border-radius: 4px;
  margin-right: 22px;
`;

export const Dates = styled.span`
  font-family: 'Shell Font';
  font-size: 9px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #757575;
`;

export const DatesHeading = styled.span`
  font-family: 'Shell Font';
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #343434;
  margin-bottom: 9px;
`;
