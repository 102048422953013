import { Flexbox, Grid, Sizes, Text } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';

export const SelectRoleContainer = styled(Flexbox)`
  background: url('/wind-turbine-landscape.png');
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
`;

export const SelectRoleDiv = styled(Grid)`
  border: 2px solid black;
  border-radius: 10px;
  width: 20%;
  padding: 10px;
  background-color: white;
  height: auto;
  margin: auto; /* Added to center the div horizontally */
`;

export const CustomText = styled(Text)`
  font-size: ${Sizes.ExtraLarge};
  font-weight: bold;
`;
