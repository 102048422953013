import { BaseTable, Pagination } from '@sede-x/shell-ds-react-framework';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../Context/AppContext';
import { getTableData } from '../PpaInsightUtils';

export const PPAInsightTable = () => {
  const [columns, setColumns] = useState<{ header: string; accessorKey: string }[]>([]);
  const [count, setCount] = useState(0);
  const { state: ppaInsightState, dispatch: ppaInsightDispatch } =
    useContext(AppContext).ppaInsight;
  useEffect(() => {
    getTableData(ppaInsightDispatch, ppaInsightState.filteredValues, setColumns, setCount);
  }, [ppaInsightState.filteredValues]);
  const handleOnPaginationChange = (pageNumber: number, pageSize: number) => {
    getTableData(ppaInsightDispatch, {
      ...ppaInsightState.filteredValues,
      pageNumber,
      pageSize,
    });
  };
  return (
    <>
      <BaseTable
        columns={columns}
        data={ppaInsightState.tableData.rows}
        size='large'
        width={'100%'}
        data-testid='base-table'
      />
      {ppaInsightState.tableData.count !== 0 && (
        <div>
          <Pagination total={count} pageSize={5} onChange={handleOnPaginationChange} />
        </div>
      )}
    </>
  );
};
