import { ReactComponent as AddCircle } from '../../../commonUtils/assets/icons/add-circle.svg';
import { ReactComponent as AddCircleSolid } from '../../../commonUtils/assets/icons/add-circle-solid.svg';
import { ReactComponent as AlertCircle } from '../../../commonUtils/assets/icons/alert-circle.svg';
import { ReactComponent as ArrowLeft } from '../../../commonUtils/assets/icons/arrow-left.svg';
import { ReactComponent as CheckCircleSolid } from '../../../commonUtils/assets/icons/check-circle-solid.svg';
import { ReactComponent as CrossCircle } from '../../../commonUtils/assets/icons/cross-circle.svg';
import { ReactComponent as Eye } from '../../../commonUtils/assets/icons/eye.svg';
import { ReactComponent as Find } from '../../../commonUtils/assets/icons/find.svg';
import { ReactComponent as Home } from '../../../commonUtils/assets/icons/home.svg';
import { ReactComponent as LineChart } from '../../../commonUtils/assets/icons/line-chart.svg';
import { ReactComponent as MinusCircleSolid } from '../../../commonUtils/assets/icons/minus-circle-solid.svg';
import { ReactComponent as Offline } from '../../../commonUtils/assets/icons/offline.svg';
import { ReactComponent as Search } from '../../../commonUtils/assets/icons/search.svg';
import { ReactComponent as SearchNoResults } from '../../../commonUtils/assets/icons/search-no-results.svg';
import { ReactComponent as Tag } from '../../../commonUtils/assets/icons/tag.svg';
import { ReactComponent as Tools } from '../../../commonUtils/assets/icons/tools.svg';

export const icons = {
  add: AddCircle,
  addSolid: AddCircleSolid,
  alertCircle: AlertCircle,
  arrowLeft: ArrowLeft,
  checkSolid: CheckCircleSolid,
  cross: CrossCircle,
  eye: Eye,
  find: Find,
  home: Home,
  lineChart: LineChart,
  minusSolid: MinusCircleSolid,
  offline: Offline,
  search: Search,
  searchNoResults: SearchNoResults,
  tag: Tag,
  tools: Tools,
};
