import React from 'react';
import { Alignments, Modal } from '@sede-x/shell-ds-react-framework';
import ReconIngetionFailure from './ReconIngetionFailure';

interface Log {
  ERROR_HEADING: string;
  ERROR_DESCRIPTION: string;
  REGION: string;
  COMMODITY: string;
}

interface DataIngestionAlertModalProps {
  open: boolean;
  closeModal: () => void;
  log: Log;
}

const ReconIngetionFailureModal: React.FC<DataIngestionAlertModalProps> = ({
  open,
  closeModal,
  log,
}) => {
  return (
    <Modal
      showFooter={false}
      closable={true}
      loading={false}
      width={731}
      open={open}
      onClose={closeModal}
      actionsAlignment={Alignments.Center}
      data-testid='loader_modal'
    >
      <ReconIngetionFailure log={log} />
    </Modal>
  );
};

export default ReconIngetionFailureModal;
