import { BaseTable } from '@sede-x/shell-ds-react-framework';
import React, { useState } from 'react';
import {
  ColumnDef,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
} from '@tanstack/react-table';
import { IBiaReportTableData } from '../../../../../Types/types2';
import { SOURCE_UOM } from '../../../../../api/constants';

export interface PaginationState {
  pageIndex: number;
  pageSize: number;
}

// Define the CellRenderer component
const CellRenderer: React.FC<{ value: string; columnId: string }> = ({ value, columnId }) => {
  if (columnId === '(1)-(2)') {
    const numericValue = Number(value);
    const backgroundColor = numericValue === 0 ? '#74C365' : '#e08792';
    return <div style={{ backgroundColor, padding: '8px' }}>{value}</div>;
  }
  return <div style={{ padding: '8px' }}>{value}</div>;
};

export const BiaReportTable = ({ biaData }: { biaData: IBiaReportTableData }) => {
  const [paginationState, setPaginationState] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 4,
  });
  const [sorting, setSorting] = useState<SortingState>([]);
  let columns: { header: string; accessorKey: string }[] | ColumnDef<unknown, string>[] = [];

  // Inside your main component
  if (biaData.length > 0) {
    const firstRow = biaData[0];
    const keys = Object.keys(firstRow);
    columns = keys
      .filter((key) => key !== SOURCE_UOM)
      .map((key) => ({
        header: key.toUpperCase().replace(/_/g, ' '),
        accessorKey: key,
        cell: (info) => {
          const value = info.getValue();
          return <CellRenderer value={value} columnId={info.column.id} />;
        },
      }));

    // Add SOURCE_UOM column at the end
    if (keys.includes(SOURCE_UOM)) {
      columns.push({
        header: SOURCE_UOM,
        accessorKey: SOURCE_UOM,
        cell: (info) => {
          const value = info.getValue();
          return <CellRenderer value={value} columnId={info.column.id} />;
        },
      });
    }
  }

  const handlePaginationChange = async (
    newPaginationState: React.SetStateAction<PaginationState>,
  ) => {
    setPaginationState(newPaginationState);
  };

  const { pageSize, pageIndex } = paginationState;
  const tableOptions = {
    state: {
      pagination: { pageSize, pageIndex },
      sorting,
    },
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onPaginationChange: handlePaginationChange,
    onSortingChange: setSorting,
  };

  return (
    <BaseTable
      columns={columns}
      data={biaData}
      size='large'
      width={'100%'}
      data-testid='base-table'
      tableOptions={tableOptions}
      style={{ border: '1px solid #C0C0C0' }}
    />
  );
};
