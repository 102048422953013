import { createSlice } from '@reduxjs/toolkit';
import { datavisualizationState } from './datavisualization.types';

export const initialState: datavisualizationState = {
  spinner: false,
};

const datavisualizationSlice = createSlice({
  name: 'datavisualization',
  initialState,
  reducers: {
    showSpinner(state) {
      state.spinner = true;
    },
    hideSpinner(state) {
      state.spinner = false;
    },
  },
});

export const { showSpinner, hideSpinner } = datavisualizationSlice.actions;

export default datavisualizationSlice.reducer;
