import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IScenarioModellingState } from './scenarioModelling.types';
import { BUSINESS_PLAN_TITLE } from '../../constants';

export const initialState: IScenarioModellingState = {
  scenarioModellingTabTitle: BUSINESS_PLAN_TITLE,
};

const scenarioModellingSlice = createSlice({
  name: 'scenarioModelling',
  initialState,
  reducers: {
    setScenarioModellingTabTitle(state, action: PayloadAction<string>) {
      state.scenarioModellingTabTitle = action.payload;
    },
  },
});

export const { setScenarioModellingTabTitle } = scenarioModellingSlice.actions;
export default scenarioModellingSlice.reducer;
