import { Loading } from '@sede-x/shell-ds-react-framework';
import React from 'react';

export const BiaReportLoader = ({ loadingText }: { loadingText: string }) => {
  return (
    <div
      style={{
        borderRadius: '4px',
        background: 'var(--Background-Surface, #FFF)',

        /* Elevation/Level 2 */
        boxShadow: '0px 6px 24px 0px rgba(0, 0, 0, 0.06), 0px 2px 6px 0px rgba(0, 0, 0, 0.10)',

        /* Flexbox for centering */
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

        /* Padding */
        padding: '20px 0',
      }}
    >
      <Loading />
      <p>Loading {loadingText} data</p>
    </div>
  );
};
