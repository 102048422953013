import styled from 'styled-components';

export const DasboardContainer = styled.div`
  width: 100%;
  background: #f5f5f5;
  padding: 30px 24px;
`;

export const SubContainer = styled.div`
  box-sizing: border-box;
  width: 33.3%;
  //height: 480px;
  align-items: center;
  margin: 10px 10px 0 10px;
  border-radius: 8px;
  flex: 1;
`;

export const Title = styled.div`
  font-family: 'Shell Font';
  font-style: normal;
  word-wrap: break-word;
  white-space: pre-wrap;
  font-weight: 600;
  font-size: 22px;
  line-height: 35px;
  color: #000000;
`;

export const Link = styled.div`
  width: 244px;
  //font-family: 'SF Pro Text';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-decoration-line: underline;
  color: #32619f;
  //padding-bottom: 2rem;
`;

export const MTitle = styled.div`
  font-family: 'Shell Font';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #343434;
  padding-top: 10px;
  padding-bottom: 8px;
  padding-left: 15px;
`;

export const MSubTitle = styled.div`
  word-wrap: break-word;
  white-space: pre-wrap;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0em;
  fill: #757575;
  font-style: normal;
  font-family: 'Shell Font';
  color: #757575;
  padding-bottom: 4px;
  padding-left: 15px;
`;

export const TextAreaWrapper = styled.div`
  padding: 10px 10px 10px 15px;
`;

export const TextAreaButtonWrapper = styled.div`
  padding: 2px 10px 10px 15px;
`;

export const CDashboard = styled.div`
  box-sizing: border-box;
  height: 134px;
  width: 100%;
  word-wrap: break-word;
  white-space: pre-wrap;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 47px 0;
  background: #f5f5f5;
  border-radius: 8px;
`;

export const CarbonStatus = styled.div`
  box-sizing: border-box;
  min-height: 335px;
  align-items: center;
  background: #ffffff;
  border-radius: 8px;
  position: relative;
`;

export const AIContainer = styled.div`
  box-sizing: border-box;
  //width: 100%;
  height: 470px;
  border-radius: 8px;
  background: #ffffff;
`;

export const TCETileContainer = styled.div`
  box-sizing: border-box;
  height: 164px;
  border-radius: 8px;
  margin-bottom: 17px;
  background: #ffffff;
  position: relative;
`;

export const TCEContainer = styled.div`
  box-sizing: border-box;
  min-height: 335px;
  border-radius: 8px;
  background: #ffffff;
  position: relative;
`;

export const HCVContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 470px;
  border-radius: 8px;
  background: #ffffff;
`;

export const OCSDesc = styled.div`
  box-sizing: border-box;
  //border: 1px solid red;
  border-radius: 8px;
  margin: 10px 0px;
  padding: 0px 16px 0px 16px;
`;

export const CEdata = styled.div`
  display: flex;
  margin-top: 15px;
  padding: 5px 0 5px 10px;
  justify-content: center;
  align-items: center;
`;

export const OCSDescHy = styled.div`
  //border: 1px solid red;
  padding: 5px 0 5px 10px;
  color: #fdb840;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
`;

export const OCSDescHr = styled.div`
  //border: 1px solid red;
  padding: 5px 0 5px 10px;
  color: #cc292c;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
`;

export const OCSDescHb = styled.div`
  //border: 1px solid red;
  padding: 5px 0 5px 11px;
  color: #404040;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
`;

export const OCSDescB = styled.div`
  //border: 1px solid black;
  padding: 2px 10px;
  color: #7f7f7f;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
`;

export const OCSDescHcvp = styled.div`
  //border: 1px solid red;
  padding: 3px 6px 3px 6px;
  margin-left: 5px;
  background: #fae7e7;
  border-radius: 16px;
  color: #cc292c;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  //border: 1px solid #404040;
  display: inline-block;
`;

export const OCSDescHcvps = styled.div`
  //border: 1px solid red;
  padding: 1px 0 5px 0px;
  margin-left: 5px;
  color: #7f7f7f;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  display: inline-block;
`;

export const CommoditySelect = styled.div`
  padding-top: 10px;
  padding-bottom: 8px;
  padding-right: 15px;
`;

export const NciTileFooter = styled.div`
  text-align: center;
  font-size: 12px;
`;
