import React from 'react';
import { Flexbox } from '@sede-x/shell-ds-react-framework';
import { Link } from 'react-router-dom';
import {
  CardAnalytics,
  IconContainer,
  SvgIcon,
  TextContainer,
  Heading,
  SubHeading,
} from './TradeNCounterPartyCards.style';

interface VolumeChartProps {
  regions: string[];
}

const TradeNCounterPartyCards: React.FC<VolumeChartProps> = ({ regions }) => {
  return (
    <Flexbox gap='10px' style={{ marginTop: '-40px' }}>
      {regions.length === 1 && regions[0] === 'ASIA' && (
        <CardAnalytics>
          <IconContainer>
            <SvgIcon xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none'>
              <path
                d='M17 3C16.4477 3 16 3.44772 16 4V20C16 20.5523 16.4477 21 17 21H20C20.5523 21 21 20.5523 21 20V4C21 3.44772 20.5523 3 20 3H17Z'
                fill='white'
              />
              <path
                d='M9.5 9C9.5 8.44772 9.94772 8 10.5 8H13.5C14.0523 8 14.5 8.44772 14.5 9V20C14.5 20.5523 14.0523 21 13.5 21H10.5C9.94772 21 9.5 20.5523 9.5 20V9Z'
                fill='white'
              />
              <path
                d='M3 13C3 12.4477 3.44772 12 4 12H7C7.55228 12 8 12.4477 8 13V20C8 20.5523 7.55228 21 7 21H4C3.44772 21 3 20.5523 3 20V13Z'
                fill='white'
              />
            </SvgIcon>
          </IconContainer>
          <TextContainer>
            <Heading>
              {' '}
              <Link to='/ci-trades' style={{ textDecoration: 'none', color: 'inherit' }}>
                C & I Trades{' '}
              </Link>
            </Heading>
            <SubHeading>
              {' '}
              <Link to='/ci-trades' style={{ textDecoration: 'none', color: 'inherit' }}>
                Gross Traded Volume.{' '}
              </Link>
            </SubHeading>
          </TextContainer>
        </CardAnalytics>
      )}
      <CardAnalytics className='cardAnalytics'>
        <IconContainer>
          <SvgIcon xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none'>
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M3 5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V7.99995H3V5ZM3 9.49995H21V14.5H3V9.49995ZM3 16V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V16H3Z'
              fill='white'
            />
          </SvgIcon>
        </IconContainer>

        <TextContainer>
          <Heading>
            {' '}
            <Link to='/top-counterparties' style={{ textDecoration: 'none', color: 'inherit' }}>
              Top 20 Counterparties{' '}
            </Link>
          </Heading>
          <SubHeading>
            {' '}
            <Link to='/top-counterparties' style={{ textDecoration: 'none', color: 'inherit' }}>
              List of top Counterparties based on Quantity{' '}
            </Link>
          </SubHeading>
        </TextContainer>
      </CardAnalytics>

      {/* show all cpty menu if regions array only have AMERICAS */}
      {regions.length === 1 && regions[0] === 'AMERICAS' && (
        <CardAnalytics className='cardAnalytics'>
          <IconContainer>
            <SvgIcon xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none'>
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M3 5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V7.99995H3V5ZM3 9.49995H21V14.5H3V9.49995ZM3 16V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V16H3Z'
                fill='white'
              />
            </SvgIcon>
          </IconContainer>

          <TextContainer>
            <Heading>
              {' '}
              <Link to='/all-counterparties' style={{ textDecoration: 'none', color: 'inherit' }}>
                All Counterparties{' '}
              </Link>
            </Heading>
            <SubHeading>
              {' '}
              <Link to='/all-counterparties' style={{ textDecoration: 'none', color: 'inherit' }}>
                List of all Counterparties based on Quantity{' '}
              </Link>
            </SubHeading>
          </TextContainer>
        </CardAnalytics>
      )}
    </Flexbox>
  );
};

export default TradeNCounterPartyCards;
