/* eslint-disable no-undef */
import {
  Button,
  Flexbox,
  Grid,
  Pecten,
  RadioButton,
  Sizes,
} from '@sede-x/shell-ds-react-framework';
import React, { ChangeEvent, useContext, useState } from 'react';
import { CustomText, SelectRoleContainer, SelectRoleDiv } from './SelectRolePage.style';
import { useAppDispatch, useAppSelector } from '../../../CarbonDashboard/redux/hooks';
import { SELECT_ROLE_MESSAGE, CONTINUE } from '../../../Tanso/api/constants';
import { setUpRoleBasedAccess } from '../AppContentFunctions';
import { AppContext } from '../../../Tanso/Context/AppContext';

export const SelectRolePage = () => {
  const { loggedInUserRoles } = useAppSelector((states) => states.dashboard);
  const { state, dispatch } = useContext(AppContext).appData;
  const cpmDispatch = useAppDispatch();
  const [isChecked, setIsChecked] = useState('');
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setIsChecked(e.target.value);
  };
  const onContinueClick = () => {
    const isProdEnv = process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION';
    if (isChecked) {
      setUpRoleBasedAccess(
        [isChecked],
        state.username,
        state.mail,
        dispatch,
        cpmDispatch,
        isProdEnv,
      );
    }
  };

  return (
    <SelectRoleContainer data-testid='select-role-container'>
      <SelectRoleDiv>
        <Grid.Cell>
          <Grid justifyItems='center'>
            <Grid.Cell>
              <Flexbox>
                <Pecten size={Sizes.Large} />
              </Flexbox>
            </Grid.Cell>
            <Grid.Cell>
              <CustomText>Carbon Portfolio Management</CustomText>
            </Grid.Cell>
            <Grid.Cell>{SELECT_ROLE_MESSAGE}</Grid.Cell>
            <Grid.Cell>
              <table style={{ borderCollapse: 'collapse' }}>
                <tbody>
                  {(loggedInUserRoles as string[]).map((role) => (
                    <tr key={role} style={{ border: '1px solid #edece8' }}>
                      <td style={{ padding: '5px', border: '1px solid #edece8' }}>
                        <RadioButton
                          label={role}
                          value={role}
                          checked={isChecked === role}
                          onChange={handleOnChange}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Grid.Cell>
            <Grid.Cell>
              <Flexbox justifyContent='center'>
                <Button
                  sentiment='primary'
                  size='medium'
                  onClick={onContinueClick}
                  disabled={isChecked === ''}
                >
                  {CONTINUE}
                </Button>
              </Flexbox>
            </Grid.Cell>
          </Grid>
        </Grid.Cell>
      </SelectRoleDiv>
    </SelectRoleContainer>
  );
};
