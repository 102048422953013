import { Grid } from '@sede-x/shell-ds-react-framework';
import React from 'react';

export const PageDescription = () => {
  return (
    <Grid.Cell area='description'>
      <p>
        Data in Tanso is updated daily to match that in the ETRM systems and so should a NCF volume
        report need to be created in a single day, then the data in Tanso may not match the value in
        the ETRM that is required for reporting.
      </p>
      <p>
        This feature has therefore been implemented to allow NCF leads to make changes to the data
        in Tanso before confirming the final NCF numbers.
      </p>
      <br />
      <p style={{ color: 'orange' }}>
        NOTE :: Edits made in Tanso DO NOT flow back to the ETRM system - there is therefore a need
        to also update the ETRM system to reflect any changes made.
      </p>
    </Grid.Cell>
  );
};
