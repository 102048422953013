import React, { forwardRef } from 'react';

import { icons } from './icons';

type IconProps = React.SVGProps<SVGSVGElement> & {
  type: keyof typeof icons;
};

const Icon = forwardRef<SVGSVGElement, IconProps>(function Icon({ type, ...props }, ref) {
  const Component = icons[type];
  return <Component {...props} ref={ref} />;
});

export default React.memo(Icon);
