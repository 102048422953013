import { Dispatch, SetStateAction } from 'react';
import { INVALID, THOUSAND_TWENTY_FOUR, TWENTY } from '../../../../../../api/constants';

export const handleOnUpload = (
  file: File[],
  fileName: string,
  setFuncs: {
    setETRMFile: Dispatch<SetStateAction<File | null>>;
    setETRMFileUploadSuccess: Dispatch<SetStateAction<boolean>>;
    setETRMFileUploadState: Dispatch<SetStateAction<string>>;
    setETRMFileUploadFailureMessage: Dispatch<SetStateAction<string>>;
  },
) => {
  const {
    setETRMFile,
    setETRMFileUploadSuccess,
    setETRMFileUploadState,
    setETRMFileUploadFailureMessage,
  } = setFuncs;
  const selectedFile = file?.[0];

  const isETRMFile = fileName === 'ETRMFile';

  const fileValidationResult = checkFileType(selectedFile);
  const isFileInvalid = fileValidationResult.status === INVALID;

  if (!isFileInvalid && isETRMFile) {
    setETRMFile(selectedFile);
    setETRMFileUploadSuccess(true);
  } else {
    setETRMFileUploadState(fileValidationResult.status);
    setETRMFileUploadFailureMessage(fileValidationResult.errorMessage);
  }
};

export const checkFileType = (file: File) => {
  let finalErrMessage = '';
  let status = 'success';
  // Check file size
  const maxSizeInBytes = TWENTY * THOUSAND_TWENTY_FOUR * THOUSAND_TWENTY_FOUR; // 5 MB
  if (file.size > maxSizeInBytes) {
    status = INVALID;
    finalErrMessage = `${finalErrMessage}File size exceeds 20 MB limit`;
  }

  // Check file extension
  const allowedExtensions = ['csv', 'xlsx', 'xls'];
  const fileExtension = file.name.split('.').pop()?.toLowerCase() ?? '';
  if (!allowedExtensions.includes(fileExtension)) {
    status = INVALID;
    finalErrMessage = `${finalErrMessage}Only Excel/CSV files are allowed`;
  }
  // All checks passed, file is valid
  return { status, errorMessage: finalErrMessage.trim() };
};
