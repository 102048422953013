import { Country, IBiaReports } from '../../Types/types';

export const BiaReportsInitialState: IBiaReports = {
  biaValidationCheckData: [
    {
      region: '',
      commodity: '',
      transactionType: '',
      gptv_1: 0,
      volumeByCaegory_2: 0,
      sourceUom: '',
      gptv_minus_vcm: 0,
    },
  ],
  biaUncatCptyEuropeAmericasData: [
    {
      region: '',
      commodity: '',
      counterParty: '',
      ncfCategory: '',
    },
  ],
  biaUncatCptyAsiaAustraliaData: [
    {
      region: '',
      commodity: '',
      counterPartyShortName: '',
      counterPartyLongName: '',
      ncfCategory: '',
    },
  ],
  biaInactiveCptyData: [
    {
      region: '',
      commodity: '',
      counterParty: '',
      ncfCategory: '',
      units: '',
    },
  ],
  biaGroupAffData: [
    {
      counterPartyAccount: '',
    },
  ],
  biaFilterData: {
    biaRegion: 'europe',
    biaYear: [new Date().getFullYear().toString()],
    biaQuarter: [],
    biaMonth: [],
    biaCountry: [],
    biaCommodity: [],
    biaUnitName: 'TWH',
  },
  isValidationDataLoading: false,
  isGroupAffDataLoading: false,
  isInactiveCptyDataLoading: false,
  isUncatCptyDataLoading: false,
  isLoading: false,
  isBiaFilterOptionsLoading: false,
  biaFilterOptions: {
    year: [],
    quarter: [],
    month: [],
    region: [],
    regionCountryMapping: {} as Record<string, Country[]>,
    commodity: [],
    unitName: [],
  },
  isNcfServerError: false,
  ncfServerErrorMessage: '',
};

export type IBiaReportsDataState = typeof BiaReportsInitialState;
