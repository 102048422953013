import React, { useContext, useEffect, useState } from 'react';
import { Flexbox, Button } from '@sede-x/shell-ds-react-framework';
import { PPASelectFormField } from '../../../../PPAInsights/PPAInsightFilterSection/PPASelectFormField';
import { AppContext } from '../../../../../../Context/AppContext';

export const SnapshotFilterSection = () => {
  const [reportSelected, setReportSelected] = useState('');
  const [generatedData, setGeneratedData] = useState('');
  const { state: pptState, dispatch: pptDispatch } = useContext(
    AppContext
  ).pptReports;

  useEffect(() => {
    setReportSelected('');
  }, [pptDispatch, pptState.PptfilteredValues.region]);


  const handleGenerateData = () => {
    // Mocking data generation based on the selected report
    if (reportSelected) {
      const payload = {
        ...pptState.PptfilteredValues,
        snapshotReports: [...reportSelected],
      };
      pptDispatch({ type: 'setPptFilteredValues', payload });
    } else {
      setGeneratedData('Please select a report to generate data.');
    }
  };

  // Use pptReportNames from context as options
  const options = pptState.pptReportNames.map(reportName => reportName);

  return (
    <div data-testid="filter" style={{ width: '60%' }}>
      <Flexbox flexDirection="row" alignItems="flex-start" gap="20px">
        <PPASelectFormField
          id="report"
          label="Select the report to view data"
          testId="Report"
          value={reportSelected}
          onChange={setReportSelected}
          options={options}
          mode="multiple"
        />
        <Button
          className="getDataBtn"
          data-testid="Generate Data"
          onClick={handleGenerateData}
          style={{ marginLeft: '10px', marginTop: '24px', width: '162px' }}
        >
          Generate Data
        </Button>
      </Flexbox>
      {generatedData && (
        <div style={{ marginTop: '20px' }}>{generatedData}</div>
      )}
    </div>
  );
};
