import React, { useContext } from 'react';
import { ChartContainer } from './PPAInsightPieCharts.style';
import { GlassChart } from '@sede-x/glass-design-library';
import { AppContext } from '../../../../../../Context/AppContext';

// Accept tradeName as a prop
export const PptInScopeVsOutScope: React.FC<{ tradeName: string }> = ({ tradeName }) => {
  const { state: pptState } = useContext(AppContext).pptReports;

  // Filter volumes based on the tradeName prop
  const [filteredVolumes] = pptState.pptInScopeVsOutScopeVolume.filter(
    (volume) => volume.tradeName === tradeName,
  );

  const inScopeQuantity = filteredVolumes?.inScopeQty;
  const totalQuantity = filteredVolumes?.totalQty;
  const outOfScopeQuantity = filteredVolumes?.outOfScopeQty;

  // Calculate percentages
  const inScopePercentage = (inScopeQuantity / totalQuantity) * 100;
  const outOfScopePercentage = (outOfScopeQuantity / totalQuantity) * 100;

  // Prepare chart data
  const chartData = [
    { name: 'In Scope', qty: inScopeQuantity, y: inScopePercentage, color: '#fbce07' },
    { name: 'Out of Scope', qty: outOfScopeQuantity, y: outOfScopePercentage, color: '#dd1d21' },
  ];

  // Chart options
  const options = {
    title: {
      text: `${tradeName} - In Scope vs Out of Scope`,
      align: 'left',
    },
    subtitle: {
      text: `An overview of In Scope vs Out of Scope for ${tradeName}`,
      align: 'left',
    },
    chart: {
      type: 'pie',
    },
    tooltip: {
      pointFormat: '<b>{point.qty:.2f}({point.percentage:.2f}%)</b>',
    },

    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '{point.y:.2f}%', // upto 2 decimal points
        },
        showInLegend: true,
      },
    },
    series: [
      {
        name: 'Scope',
        colorByPoint: true,
        data: chartData,
      },
    ],
  };

  // Conditionally render the chart or a no data message
  return (
    <ChartContainer>
      {totalQuantity > 0 ? (
        <GlassChart options={options} />
      ) : (
        <div>No data available for {tradeName}</div>
      )}
    </ChartContainer>
  );
};
