import { Alert, Sentiments } from '@sede-x/shell-ds-react-framework';
import React from 'react';

export const DownloadInProgressToast = () => {
  return (
    <Alert
      sentiment={Sentiments.Information}
      style={{ marginBottom: '20px', width: 'auto' }}
      elevation
    >
      Downloading In Progress
    </Alert>
  );
};
