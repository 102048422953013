import styled from 'styled-components';

export const TogglePPA = styled.div`
  display: flex;
  flex-direction: column; // Stacks children vertically
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 10px;
  height: auto; // Adjusts height based on content
  padding: 15px 0px 15px 15px;
  margin-top: 46px;
  overflow: hidden;
`;
