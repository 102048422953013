import { Country, IPptData } from '../../Types/types';

export const PptReportsInitialState: IPptData = {
  PptfilterOptions: {
    year: [],
    quarter: [],
    month: [],
    region: [],
    regionCountryMapping: {} as Record<string, Country[]>,
    commodity: [],
    transactionType: [],
    unitName: [],
    reportStatus: [],
  },
  PptfilteredValues: {
    year: ['2024'],
    quarter: [],
    month: [],
    region: [],
    countryCode: [],
    commodity: [],
    uom: 'TWh',
    transactionType: ['NCF'],
    reportStatus: [],
    snapshotReports: [],
  },
  pptNcfCategories: [
    {
      year: 2023,
      quantity: 0.8458809070000005,
      tradeName: 'Power',
      quarter: 'Q1',
      ncfCategory: 'Utilities - end user sales/ generation',
    },
  ],
  pptNcfVolumes: [],
  pptReportNames: [],
  pptInScopeVsOutScopeVolume: [
    {
      outOfScopeQty: 0,
      inScopeQty: 0,
      totalQty: 0,
      tradeName: 'Natural Gas',
    },
    {
      outOfScopeQty: 0,
      inScopeQty: 0,
      totalQty: 0,
      tradeName: 'Power',
    },
  ],
};

export type IPptInitialState = typeof PptReportsInitialState;
