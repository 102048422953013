import { Dispatch } from 'react';
import {
  getPpaInsightFormFilterOptions,
  getPpaInsightVolumeByRegionData,
  getPpaInsightLongVsShortTermData,
  getPpaInsightVolByTechTypeData,
  getPpaInsightExportData,
  getPpaInsightTableData,
} from '../../../api/services';
import { IPpaAction, IOptions, IppaInsightFilteredValues } from '../../../Types/types';
import { FIVE, ONE } from '../../../api/constants';

export const getAndSetFilterOptions = async (ppaInsightDispatch: Dispatch<IPpaAction>) => {
  showPpaSpinner(ppaInsightDispatch);
  try {
    const result = await getPpaInsightFormFilterOptions();
    const filterOptionsData = result.data;
    ppaInsightDispatch({
      type: 'setFilterOptions',
      payload: filterOptionsData,
    });
  } catch (e) {
    ppaInsightDispatch({
      type: 'setIsPpaServerError',
      payload: true,
    });
  }
  hidePpaSpinner(ppaInsightDispatch);
};

export const getVolumeByRegionData = async (
  ppaInsightDispatch: Dispatch<IPpaAction>,
  filteredValues: IppaInsightFilteredValues,
) => {
  const options = filterEmptyValues(filteredValues);
  showPpaSpinner(ppaInsightDispatch);
  try {
    const result = await getPpaInsightVolumeByRegionData(options);
    ppaInsightDispatch({ type: 'setVolumeByRegionData', payload: result.data });
  } catch (e) {
    ppaInsightDispatch({
      type: 'setIsPpaServerError',
      payload: true,
    });
  }
  hidePpaSpinner(ppaInsightDispatch);
};

export const getLongVsShortTermData = async (
  ppaInsightDispatch: Dispatch<IPpaAction>,
  filteredValues: IppaInsightFilteredValues,
) => {
  const options = filterEmptyValues(filteredValues);
  showPpaSpinner(ppaInsightDispatch);
  try {
    const result = await getPpaInsightLongVsShortTermData(options);
    ppaInsightDispatch({
      type: 'setLongVsShortTermData',
      payload: result.data,
    });
  } catch (e) {
    ppaInsightDispatch({
      type: 'setIsPpaServerError',
      payload: true,
    });
  }
  hidePpaSpinner(ppaInsightDispatch);
};

export const getVolumeByTechTypeData = async (
  ppaInsightDispatch: Dispatch<IPpaAction>,
  filteredValues: IppaInsightFilteredValues,
) => {
  const options = filterEmptyValues(filteredValues);
  showPpaSpinner(ppaInsightDispatch);
  try {
    const result = await getPpaInsightVolByTechTypeData(options);
    ppaInsightDispatch({
      type: 'setVolumeByTechTypeData',
      payload: result.data,
    });
  } catch (e) {
    ppaInsightDispatch({
      type: 'setIsPpaServerError',
      payload: true,
    });
  }
  hidePpaSpinner(ppaInsightDispatch);
};

export const handleExportData = async (
  ppaInsightDispatch: Dispatch<IPpaAction>,
  filteredValues: IppaInsightFilteredValues,
) => {
  const options = filterEmptyValues(filteredValues);
  showPpaSpinner(ppaInsightDispatch);
  try {
    // Make a GET request to download the CSV file from the API
    const response = await getPpaInsightExportData(options);
    // Create a Blob from the response data
    const blob = new Blob([response.data], { type: 'text/csv' });
    const filename = 'PPA_Insight_Data';
    // Create a temporary URL for the Blob
    const url = window.URL.createObjectURL(blob);
    // Programmatically create a link
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    // Simulate a click event to initiate the download
    document.body.appendChild(link);
    link.click();
    // Cleanup: Remove the temporary URL and the link element
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (e) {
    ppaInsightDispatch({
      type: 'setIsPpaServerError',
      payload: true,
    });
  }
  hidePpaSpinner(ppaInsightDispatch);
};

export const getTableData = async (
  ppaInsightDispatch: Dispatch<IPpaAction>,
  filteredValues: IppaInsightFilteredValues,
  setColumns?: Dispatch<{ header: string; accessorKey: string }[]>,
  setCount?: Dispatch<number>,
) => {
  const options = filterEmptyValues({
    pageNumber: filteredValues.pageNumber ?? ONE,
    pageSize: filteredValues.pageNumber ?? FIVE,
    ...filteredValues,
  });
  showPpaSpinner(ppaInsightDispatch);
  try {
    const result = await getPpaInsightTableData(options);
    ppaInsightDispatch({
      type: 'setTableData',
      payload: result.data,
    });
    if (setCount) {
      setCount(result.data.count);
    }
    if (result.data.count > 0) {
      // Get keys from the first row
      const firstRow = result.data.rows[0];
      const keys = Object.keys(firstRow);

      // Generate columns based on keys
      const columns = keys.map((key) => ({
        header: key.toUpperCase().replace(/_/g, ' '),
        accessorKey: key,
      }));
      if (setColumns) {
        setColumns(columns);
      }
    }
  } catch (e) {
    ppaInsightDispatch({
      type: 'setIsPpaServerError',
      payload: true,
    });
  }
  hidePpaSpinner(ppaInsightDispatch);
};

export function filterEmptyValues(obj: IppaInsightFilteredValues) {
  const filteredObj: IOptions = {};
  for (const key in obj) {
    if (obj[key as keyof typeof obj]) {
      filteredObj[key] = obj[key as keyof typeof obj];
    }
  }
  return filteredObj;
}

export const showPpaSpinner = (ppaInsightDispatch: Dispatch<IPpaAction>) => {
  ppaInsightDispatch({
    type: 'setIsLoading',
    payload: true,
  });
};

export const hidePpaSpinner = (ppaInsightDispatch: Dispatch<IPpaAction>) => {
  ppaInsightDispatch({
    type: 'setIsLoading',
    payload: false,
  });
};

export const handleApplyFilters = (
  stateVars: {
    yearSelected: number;
    renewableCategorySelected: string;
    tenureSelected: string;
    contractTypeSelected: string;
    regionsSelected: string[];
    regions: string[];
    quartersSelected: string[];
    quarters: string[];
  },
  ppaInsightDispatch: Dispatch<IPpaAction>,
) => {
  const {
    yearSelected,
    renewableCategorySelected,
    tenureSelected,
    contractTypeSelected,
    regionsSelected,
    regions,
    quartersSelected,
    quarters,
  } = stateVars;
  const payload: IppaInsightFilteredValues = {
    year: yearSelected,
    renewableCategory: renewableCategorySelected,
    tenure: tenureSelected,
    contractType: contractTypeSelected,
  };
  if (regionsSelected.length > 0 && !arraysHaveSameValues(regionsSelected, regions)) {
    payload.region = regionsSelected;
  }
  if (quartersSelected.length > 0 && !arraysHaveSameValues(quartersSelected, quarters)) {
    payload.quarter = quartersSelected;
  }
  ppaInsightDispatch({ type: 'setFilteredValues', payload });
};

export const arraysHaveSameValues = (arr1: string[], arr2: string[]) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const sortedArr1 = [...arr1].sort((a, b) => a.localeCompare(b));
  const sortedArr2 = [...arr2].sort((a, b) => a.localeCompare(b));

  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }

  return true;
};
