import { Dispatch } from '@reduxjs/toolkit';
export const showSpinner = (dispatch: Dispatch) => {
  dispatch({ type: 'setSpinner', payload: true });
};

export const hideSpinner = (dispatch: Dispatch) => {
  dispatch({ type: 'setSpinner', payload: false });
};

export const currentYear = new Date().getFullYear();
