import React, { useContext, useMemo } from 'react';
import { ChartContainer } from './PPAInsightPieCharts.style';
import { GlassChart } from '@sede-x/glass-design-library';
import { AppContext } from '../../../../../../Context/AppContext';

// Common component that accepts tradeName as a prop
type PptInScopeNcfCatgChartProps = {
  tradeName: string;
};

export const PptInScopeNcfCatgChart: React.FC<PptInScopeNcfCatgChartProps> = ({ tradeName }) => {
  const { state: pptState } = useContext(AppContext).pptReports;

  // useMemo to optimize testData calculation based on tradeName
  const testData = useMemo(() => {
    return pptState?.pptNcfCategories && pptState.pptNcfCategories.length > 0
      ? pptState.pptNcfCategories
          .filter((category) => category.tradeName === tradeName)
          .map((category) => ({
            name: category.ncfCategory,
            y: category.quantity,
          }))
      : [];
  }, [pptState?.pptNcfCategories, tradeName]); // Dependency on pptState.pptNcfCategories and tradeName

  const options = useMemo(
    () => ({
      title: {
        text: `${tradeName} - In Scope NCF Category`,
        align: 'left',
      },
      subtitle: {
        text: `An overview of In Scope NCF Category for ${tradeName}`,
        align: 'left',
      },
      chart: {
        type: 'pie',
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.y:.2f}({point.percentage:.2f}%)</b>',
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format:
              '<span style="font-size: 1.2em"><b>{point.name}</b>' +
              '</span><br>' +
              '<span style="opacity: 0.6">{point.percentage:.2f} ' +
              '%</span>',
            connectorColor: 'rgba(128,128,128,0.5)',
          },
        },
      },
      series: [
        {
          name: 'NCF Category',
          colorByPoint: true,
          data: testData, // Assuming chartData is not used or is an initial setup
        },
      ],
    }),
    [testData, tradeName], // Dependencies on testData and tradeName
  );

  return (
    <ChartContainer>
      {testData.length > 0 ? (
        <GlassChart options={options} />
      ) : (
        <div>No data available for {tradeName}</div>
      )}
    </ChartContainer>
  );
};
