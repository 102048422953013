import styled from 'styled-components';

export const Body = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 16px;
  box-sizing: border-box;
  text-align: center;
  font-size: 20px;
  color: #343434;
  font-family: 'Shell Font';
`;

export const ConfirmationState = styled.div`
  height: 469px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 24px;
  box-sizing: border-box;
  gap: 40px;
`;

export const StatusAlertParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

export const StatusAlertIcon = styled.img`
  width: 32px;
  position: relative;
  height: 32px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
`;

export const DataIngestionFailedParent = styled.div`
  width: 565px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const DataIngestionFailed = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 24px;
  font-weight: 800;
`;

export const ADataLoad = styled.div`
  align-self: stretch;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Shell Font';
  color: #757575;
`;

export const DataHasFailedToLoadForRWrapper = styled.div`
  width: 312px;
  border-radius: 8px;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 24px;
  box-sizing: border-box;
  text-align: left;
  font-size: 16px;
  color: #757575;
  font-family: 'Shell Font';
`;

export const DataHasFailedContainer = styled.div`
  position: relative;
  line-height: 24px;
`;

export const RegionAmericas = styled.p`
  margin: 0;
  color: #343434;
`;

export const Americas = styled.b`
    font-family: 'Shell Font';
`;

export const ForFurtherInformationOrToWrapper = styled.div`
  width: 589px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #757575;
  font-family: 'Shell Font';
`;

export const ForFurtherInformationContainer = styled.i`
  align-self: stretch;
  position: relative;
  line-height: 24px;
`;

export const DstsApplicationSupportShell = styled.span`
  text-decoration: underline;
  color: #336094;
`;