/* eslint-disable no-unused-vars */
import {
  IppaInsightData,
  IppaInsightFilterOptions,
  IppaInsightFilteredValues,
  IppaInsightTableData,
  IppaInsightVolumeByRegionData,
  IppaInsightVolumeByTechTypeData,
  IppaInsightLongVsShortTermData,
  IPpaAction,
} from '../../Types/types';
import { IPPApayload } from '../../Types/types2';

const ppaInsightPpaActionHandlers: Record<
  string,
  (state: IppaInsightData, payload: IPPApayload) => IppaInsightData
> = {
  setIsLoading: (state, payload) => ({
    ...state,
    isLoading: payload as boolean,
  }),
  setIsPpaServerError: (state, payload) => ({
    ...state,
    isPpaServerError: payload as boolean,
  }),
  setFilterOptions: (state, payload) => ({
    ...state,
    filterOptions: payload as IppaInsightFilterOptions,
  }),
  setFilteredValues: (state, payload) => ({
    ...state,
    filteredValues: payload as IppaInsightFilteredValues,
  }),
  setTableData: (state, payload) => ({
    ...state,
    tableData: payload as IppaInsightTableData,
  }),
  setVolumeByRegionData: (state, payload) => ({
    ...state,
    volumeByRegionData: payload as IppaInsightVolumeByRegionData[],
  }),
  setVolumeByTechTypeData: (state, payload) => ({
    ...state,
    volumeByTechTypeData: payload as IppaInsightVolumeByTechTypeData[],
  }),
  setLongVsShortTermData: (state, payload) => ({
    ...state,
    longVsShortTermData: payload as IppaInsightLongVsShortTermData,
  }),
};

export const PpaInsightReducers = (state: IppaInsightData, PpaAction: IPpaAction) => {
  if (Object.hasOwn(ppaInsightPpaActionHandlers, PpaAction.type)) {
    const handler = ppaInsightPpaActionHandlers[PpaAction.type];
    return handler(state, PpaAction.payload);
  }
  return state;
};
