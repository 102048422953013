import styled from 'styled-components';

export const FrameParent = styled.div`
//   width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: 14px;
  color: #757575;
  font-family: 'Shell Font';
  margin-top: '46px';
  margin-top: '46px' !important;
`;

export const TimeStampCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 11px;
    margin-top: '46px';
`;

export const TimeStampCardInner = styled.div`
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const Header = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 16px 16px 0px;
  gap: 12px;
  z-index: 2;
`;

export const TitleGroup = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
`;

export const Text4 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
`;

export const Meta = styled.div`
  width: 26px;
  position: relative;
  line-height: 16px;
  display: none;
`;

export const Timetime = styled.div`
  width: 20px;
  position: relative;
  height: 20px;
  display: none;
`;

export const Title = styled.b`
  flex: 1;
  position: relative;
  font-size: 14px;
  line-height: 16px;
  color: #343434;
`;

export const Description = styled.div`
  width: 383px;
  position: relative;
  line-height: 16px;
  display: none;
`;

export const Timetime1 = styled.div`
  align-self: stretch;
  width: 96.5px;
  position: relative;
  display: none;
`;

export const Body = styled.div`
  width: 380px;
  flex: 1;
  background-color: #f5f5f5;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 1;
  text-align: center;
  color: #d9d9d9;
`;

export const Placeholder1 = styled.div`
  align-self: stretch;
  flex: 1;
  position: relative;
  border-radius: 2px;
  background-color: #fff;
  overflow: hidden;
`;

export const PlaceholderIcon = styled.img`
  position: absolute;
  height: calc(100% - 16px);
  width: calc(100% - 16px);
  top: 8px;
  right: 8px;
  bottom: 8px;
  left: 8px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
`;

export const Text5 = styled.div`
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 40px);
  border-radius: 2px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
`;

export const Text1 = styled.div``;

export const Footer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px;
  gap: 12px;
  z-index: 0;
  font-size: 16px;
`;

export const FrameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
`;

export const Tag20 = styled.div`
  align-self: stretch;
  border-radius: 8px;
  background-color: #fff2f0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 4px;
  color: #dd1d21;
`;

export const StatusAlertIcon = styled.img`
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
`;

export const Label = styled.div`
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4px;
`;

export const Text7 = styled.div`
  position: relative;
  line-height: 20px;
`;

export const Tag201 = styled.div`
  align-self: stretch;
  border-radius: 8px;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 4px;
    padding-left: 15px;
`;

export const ETRMSvg = styled.div`
  align-self: stretch;
  border-radius: 8px;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 7px;
  margin-left: 4px;
  padding-top: 8px;
  cursor: pointer;
`;

export const Description1 = styled.div`
  width: 179px;
  position: relative;
  font-size: 14px;
  line-height: 16px;
  display: none;
`;

export const Description2 = styled.div`
  width: 205px;
  position: relative;
  font-size: 11px;
  line-height: 16px;
  display: none;
`;

export const Placeholder2 = styled.div`
  align-self: stretch;
  width: 185.5px;
  position: relative;
  border-radius: 2px;
  background-color: #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  overflow: hidden;
  display: none;
`;

export const EtrmText = styled.div`
display: flex;
justify-content: center;
`;