import React, { useEffect, useContext, useState, useRef } from 'react';
import { ChartContainer, TableHeading } from './TopCtpyTable.style';
import { topCounterParty, counterPartyNcfCategory } from '../../../../../../api/services';
import { BaseTable, Flexbox, Toggle } from '@sede-x/shell-ds-react-framework';
import { AppContext } from '../../../../../../Context/AppContext';
import Loader from '../../../../../Loader/Loader';
import { SortingState, getSortedRowModel } from '@tanstack/react-table';
import DownloadCsvButton from '../../../../../ReUsableComponents/DownloadCsvButton/DownloadCsvButton';

const TopCtpyTable: React.FC = () => {
  const [isActiveNcfCategoryView, setIsActiveNcfCategoryView] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sorting, setSorting] = useState<SortingState>([]);
  const { state: analyticsState, dispatch: analyticsDispatch } =
    useContext(AppContext).analyticsHome;
  const hasFetchedNcfCategoryViewData = useRef(false);

  const columnNames = [
    'CounterpartyName',
    'NcfCategory',
    'Country',
    'Year',
    'Quantity',
    'Percentage',
  ];

  const columns = columnNames.map((key) => ({
    header: key === 'Quantity' ? `Quantity (${analyticsState?.cptyFilteredValues?.uom})` : key,
    accessorKey: key,
  }));

  const tableColumns = isActiveNcfCategoryView
    ? columns.filter(
        (column) => column.header !== 'CounterpartyName' && column.header !== 'Country',
      )
    : columns.filter((column) => column.header !== 'NcfCategory');

  const tableData = isActiveNcfCategoryView
    ? analyticsState.counterpartyNcfCategoryData
    : analyticsState.counterpartyData;

  useEffect(() => {
    const fetchData = async () => {
      let response;
      try {
        response = await topCounterParty(analyticsState.cptyFilteredValues);
        analyticsDispatch({
          type: 'setCounterpartyData',
          payload: response.data,
        });
        setIsActiveNcfCategoryView(false);
        hasFetchedNcfCategoryViewData.current = false;
      } catch (error) {
        // Log error once toast feature is implemented
      }
    };

    fetchData();
  }, [analyticsDispatch, analyticsState.cptyFilteredValues]);

  // Fetch data for NCF Category View only once, no re-render required
  useEffect(() => {
    if (isActiveNcfCategoryView && !hasFetchedNcfCategoryViewData.current) {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          const response = await counterPartyNcfCategory({
            ...analyticsState.cptyFilteredValues,
            pageNumber: 1,
            pageSize: 20,
          });
          analyticsDispatch({
            type: 'setCounterpartyNcfCategoryData',
            payload: response.data,
          });
          hasFetchedNcfCategoryViewData.current = true;
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          // Log error once toast feature is implemented
        }
      };

      fetchData();
    }
  }, [analyticsDispatch, analyticsState.cptyFilteredValues, isActiveNcfCategoryView]);

  const tableOptions = {
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  };

  return (
    <ChartContainer>
      <Flexbox style={{ width: '100%' }} justifyContent='space-between' alignItems='center'>
        <Loader loader={isLoading} message={''} />
        <TableHeading>Top 20 Counterparties Table</TableHeading>
        <Flexbox justifyContent='flex-end' alignItems='center' style={{ flex: 1 }}>
          <Toggle
            label='NCF Category View'
            mirrored
            data-testid='toggleButton-overallPortfolio'
            onChange={() => setIsActiveNcfCategoryView(!isActiveNcfCategoryView)}
            checked={isActiveNcfCategoryView}
          />
          <DownloadCsvButton data={tableData} fileName={'Top20Cpty'} />
        </Flexbox>
      </Flexbox>
      <BaseTable
        columns={tableColumns}
        data={tableData}
        size='large'
        data-testid='base-table'
        width={'100%'}
        stickyHeader={true}
        maxHeight={800}
        tableOptions={tableOptions}
      />
    </ChartContainer>
  );
};

export default TopCtpyTable;
