import Highcharts, { SeriesOptionsType } from 'highcharts';
import { LINE_CHART_TITLE, LINE_CHART_SUB_TITLE, LINE_CHART_YAXIS_TEXT } from '../../../constants';
import { toolTipFormatter } from '../../../utils';
import { Iseries, PointsData } from '../../../Types/types';
const fontFamilyValue = 'Shell Font';
const left = 'left';
export const highchartOptions = (series: Iseries[]) => {
  const options: Highcharts.Options = {
    chart: {
      type: 'line',
      height: 500,
      style: {
        fontFamily: fontFamilyValue, // Set 'Shell Font' as the primary font family for the whole chart
      },
    },
    title: {
      text: LINE_CHART_TITLE,
      align: left,
      style: {
        fontSize: '20px',
        fontFamily: fontFamilyValue, // Set 'Shell Font' for the title
      },
    },
    subtitle: {
      text: LINE_CHART_SUB_TITLE,
      align: left,
      style: {
        fontSize: '14px',
        fontFamily: fontFamilyValue, // Set 'Shell Font' for the subtitle
      },
    },
    yAxis: {
      title: {
        useHTML: true,
        text: LINE_CHART_YAXIS_TEXT,
        style: {
          fontFamily: fontFamilyValue, // Set 'Shell Font' for yAxis title
          color: '#404040',
        },
      },
      gridLineWidth: 1,
      min: 0,
    },
    legend: {
      itemStyle: {
        fontSize: '12px', // Adjust the font size here as per your requirement
        fontWeight: 'light',
        color: '#757575',
        fontFamily: 'Shell Font, Arial, sans-serif',
      },
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'top',
    },
    tooltip: {
      shared: true,
      headerFormat: '<span style="font-size:12px"><b>{point.key}</b></span><br>',
      valueDecimals: 2,
      formatter() {
        return toolTipFormatter(this as unknown as PointsData);
      },
    },
    xAxis: {
      title: {
        useHTML: true,
        text: 'Year',
        style: {
          fontFamily: fontFamilyValue, // Set 'Shell Font' for yAxis title
          color: '#404040',
        },
      },
      categories: [],
      gridLineWidth: 1,
    },
    series: series as SeriesOptionsType[],
  };
  return options;
};
