import { useNavigate } from 'react-router-dom';
import { ChevronLeft } from '@sede-x/shell-ds-react-framework/build/esm/components/Icon/components';
import { Button } from '@sede-x/shell-ds-react-framework';

export const BackToDashboard = () => {
  const navigate = useNavigate();
  const navigateToDashboard = () => {
    navigate('/');
  };
  return (
    <Button
      className='backToDashboard'
      onClick={navigateToDashboard}
      aria-label='Back to Dashboard'
      variant='transparent'
    >
      <div>
        <ChevronLeft width={20} height={20} />
      </div>
      <div className='backText'> Back</div>
    </Button>
  );
};
