import styled from 'styled-components';
export const OverAllContainer = styled.div`
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 4px;
  padding-top: 10px;
  margin: 10px 10px 0 10px;
  flex: 1;
  width: 33.3%;
`;

export const DataVisualizationContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 15px 15px;
  gap: 7px;
  height: auto;
`;

export const ViewDetailsButton = styled.div`
  float: right;
  margin-right: 20px;
  padding-top: 10px;
  margin-bottom: 10px;
`;
