import React, { useEffect, useContext, useState } from 'react';
import { Flexbox, Button } from '@sede-x/shell-ds-react-framework';
import { counterPartyFilter } from '../../../../api/services';
import { AppContext } from '../../../../Context/AppContext';
import { SelectFormField } from '../../../ReUsableComponents/SelectFormField/SelectFormField';
import { ICptyFilteredValues } from '../../../../Types/types';
import { getCombinedMonths } from '../../../Utils/utils'; // Adjust the import path as necessary

export const TopCtpyFilterSection = () => {
  const { state: analyticsState, dispatch: analyticsDispatch } =
    useContext(AppContext).analyticsHome;
  const { state } = useContext(AppContext).appData;
  const filterData = analyticsState.cptyFilterOptions;

  const [yearSelected, setYearSelected] = useState('2024');
  const [quarterSelected, setQuarterSelected] = useState<string[]>([]);
  const [monthSelected, setMonthSelected] = useState<string[]>([]);
  const [regionSelected, setRegionSelected] = useState<string[]>(
    state.userRegion ? [state.userRegion] : [],
  );
  const [transactionTypeSelected, setTransactionTypeSelected] = useState('NCF');
  const [unitNameSelected, setUnitNameSelected] = useState('TWH');
  const [commoditySelected, setCommoditySelected] = useState('PW');
  const [ncfScopeSelected, setNcfScopeSelected] = useState<string[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await counterPartyFilter();
      analyticsDispatch({
        type: 'setCptyFilterOptions',
        payload: response.data,
      });
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!filterData) {
    return null; // or a loading spinner
  }

  const handleApplyFilters = () => {
    const payload: ICptyFilteredValues = {
      year: [yearSelected],
      quarter: quarterSelected,
      month: monthSelected,
      region: regionSelected,
      transactionType: transactionTypeSelected,
      uom: unitNameSelected,
      commodity: commoditySelected ? [commoditySelected] : ['PW'],
      ncfScope: ncfScopeSelected,
    };

    analyticsDispatch({ type: 'setCptyFilteredValues', payload });
  };

  const handleClearFilters = () => {
    setYearSelected('2024');
    setQuarterSelected([]);
    setMonthSelected([]);
    if (!state.isNcfReportingRegionFieldDisabled) {
      setRegionSelected([]);
    }
    setTransactionTypeSelected('NCF');
    setUnitNameSelected('TWH');
    setCommoditySelected('PW');
    setNcfScopeSelected([]);

    const payload = {
      year: ['2024'],
      quarter: [],
      month: [],
      region: state.isNcfReportingRegionFieldDisabled ? [state.userRegion] : [],
      transactionType: 'NCF',
      commodity: ['PW'],
      ncfScope: [],
      uom: 'TWH',
    };

    analyticsDispatch({ type: 'setCptyFilteredValues', payload });
  };

  return (
    <div data-testid='filter' style={{ padding: '20px', width: '25%' }}>
      <Flexbox flexDirection='column'>
        <div
          style={{
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '17px',
            lineHeight: '26px',
            color: '#404040',
            padding: '10px 0',
            marginTop: '26px',
            width: '251px',
          }}
        >
          Filters
        </div>
        <SelectFormField
          id='year'
          label='Year'
          testId='Year'
          value={yearSelected}
          onChange={setYearSelected}
          options={filterData.year}
        />
        <SelectFormField
          id='quarter'
          label='Quarter'
          testId='Quarter'
          value={quarterSelected}
          onChange={setQuarterSelected}
          options={filterData.quarter}
          mode='multiple'
        />
        {quarterSelected.length > 0 && (
          <SelectFormField
            id='month'
            label='Month'
            testId='Month'
            value={monthSelected}
            onChange={setMonthSelected}
            options={getCombinedMonths(quarterSelected)}
            mode='multiple'
          />
        )}
        <SelectFormField
          id='region'
          label='Region'
          testId='Region'
          value={regionSelected}
          onChange={setRegionSelected}
          options={filterData.region}
          mode='multiple'
          disabled={state.isNcfReportingRegionFieldDisabled}
        />
        <SelectFormField
          id='unitName'
          label='Unit Name'
          testId='Unit Name'
          value={unitNameSelected}
          onChange={setUnitNameSelected}
          options={filterData.unitName}
        />
        <SelectFormField
          id='commodity'
          label='Commodity'
          testId='Commodity'
          value={commoditySelected}
          onChange={setCommoditySelected}
          options={filterData.commodity.map((el: { TRADE_NAME: string; TRADE_CODE: string }) => {
            return {
              label: el.TRADE_NAME,
              value: el.TRADE_CODE,
            };
          })}
        />
        <SelectFormField
          id='transactionType'
          label='TransactionType'
          testId='TransactionType'
          value={transactionTypeSelected}
          onChange={setTransactionTypeSelected}
          options={filterData.transactionType}
        />
        <SelectFormField
          id='ncfScope'
          label='NCF Scope'
          testId='NCF Scope'
          value={ncfScopeSelected}
          onChange={setNcfScopeSelected}
          options={filterData.ncfScope}
          mode='multiple'
        />
        <Button className='getDataBtn' data-testid='Apply Filters' onClick={handleApplyFilters}>
          Apply Filters
        </Button>
        <Button
          className='getDataBtn'
          variant='outlined'
          style={{ marginTop: '10px' }}
          data-testid='Clear Filters'
          onClick={handleClearFilters}
        >
          Clear Filters
        </Button>
      </Flexbox>
      <div style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.06)' }}></div>
    </div>
  );
};
