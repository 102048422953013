import React, { useEffect, useState, useContext } from 'react';

import { ChartContainer, TableHeading, ChartFooterText } from './SnapshotWaterfallChart.style';
import {
  generateSeries,
  getCommodityName,
  fetchDataBasedOnView,
  generateColumns,
  WHOLESALE_CHURN_PARAMETER_NAME,
  OUT_OF_SCOPE_VOLUME_PARAMETER_NAME,
} from './SnapshotWaterfallChartUtils';
import Chart from '../../Chart/Chart';
import { BaseTable, Flexbox, Grid, Button } from '@sede-x/shell-ds-react-framework';
import { AppContext } from '../../../../../Context/AppContext';
import ChartToggle from '../../../../ReUsableComponents/PPAToggle/PPAToggle';
import Loader from '../../../../Loader/Loader';
import { Link } from 'react-router-dom';
import { SingleRegionVolumeTableData } from '../../../../../Types/types';
import DownloadCsvButton from '../../../../ReUsableComponents/DownloadCsvButton/DownloadCsvButton';
import { notify } from '../../../../../../CarbonDashboard/components/Toast/notify';
import { FETCH_ERROR } from '../../../../../../CarbonDashboard/utils/constants';
import { SortingState, getSortedRowModel } from '@tanstack/react-table';

interface VolumeChartProps {
  region: string;
}

const SnapshotWaterfallChart: React.FC<VolumeChartProps> = ({ region }) => {
  const [view, setView] = useState('Chart');
  const [selectedParameter, setSelectedParameter] = useState('');
  const { state: analyticsState, dispatch: snapshotDispatch } =
    useContext(AppContext).analyticsSnapshotReport;
  const [isLoading, setIsLoading] = useState(true);
  const [sorting, setSorting] = useState<SortingState>([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetchDataBasedOnView(view, analyticsState, region);
        if (response && view === 'Chart') {
          snapshotDispatch({
            type: 'setAnalyticsSnapshotVolumeByRegionChartData',
            payload: {
              [region]: response.data,
            },
          });
        } else {
          snapshotDispatch({
            type: 'setAnalyticsSnapshotVolumeByRegionTableData',
            payload: {
              [region]: response?.data,
            },
          });
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        snapshotDispatch({
          type: 'setAnalyticsSnapshotVolumeByRegionChartData',
          payload: {
            [region]: [],
          },
        });
        snapshotDispatch({
          type: 'setAnalyticsSnapshotVolumeByRegionTableData',
          payload: {
            [region]: [],
          },
        });
        notify('error', FETCH_ERROR);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view, snapshotDispatch, analyticsState.analyticsSnapshotReportFilteredValues]);

  const seriesData = generateSeries(
    analyticsState.volumeByRegionChartData[region],
    selectedParameter,
  );

  const tabledata: SingleRegionVolumeTableData[] =
    analyticsState.volumeByRegionTableData[region]?.data;
  const tableTotals = analyticsState.volumeByRegionTableData[region]?.totals;

  const options = {
    chart: {
      type: 'waterfall',
    },
    xAxis: {
      type: 'category',
    },
    yAxis: {
      title: {
        text: `Quantity(${analyticsState?.analyticsSnapshotReportFilteredValues?.uom})`,
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      shared: false,
    },
    series: seriesData,
    plotOptions: {
      series: {
        cursor: 'pointer',
        events: {
          click: (event: { point: { name: string } }) => {
            const parameter = event.point.name;
            if (selectedParameter === parameter) {
              onSelectWaterfallBar('');
            } else {
              onSelectWaterfallBar(parameter);
            }
          },
        },
        lineWidth: 0,
      },
    },
  };

  const tableOptions = {
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  };

  const onSelectWaterfallBar = (parameter: string) => {
    setSelectedParameter(parameter);
    snapshotDispatch({
      type: 'setAnalyticsSnapshotHomeDetailedViewParameter',
      payload: {
        parameterName: parameter,
        region,
      },
    });
  };

  const renderContent = () => {
    if (isLoading) {
      return <Loader loader={true} message={''} />;
    } else if (view === 'Table') {
      return (
        <BaseTable
          columns={generateColumns(tableTotals)}
          data={tabledata}
          size='large'
          data-testid='base-table'
          stickyHeader
          maxHeight={450}
          width={'100%'}
          tableOptions={tableOptions}
          stickyFooter
        />
      );
    } else {
      const dbParameterName =
        selectedParameter === WHOLESALE_CHURN_PARAMETER_NAME
          ? OUT_OF_SCOPE_VOLUME_PARAMETER_NAME
          : selectedParameter;
      return (
        <Grid gap='10px'>
          <Grid.Cell>
            <Chart options={options} footer={'Source'} />
          </Grid.Cell>
          <Grid.Cell>
            <Flexbox
              justifyContent='space-between'
              alignItems='center'
              style={{ minHeight: '30px' }}
            >
              <ChartFooterText>Select a parameter to drill through</ChartFooterText>
              <Button disabled={!dbParameterName} variant='outlined' size='small'>
                <Link to={`/snapshot-region-volume-details`} style={{ textDecoration: 'none' }}>
                  View Details
                </Link>
              </Button>
            </Flexbox>
          </Grid.Cell>
        </Grid>
      );
    }
  };

  return (
    <ChartContainer>
      <Flexbox style={{ width: '100%' }} justifyContent='space-between'>
        <TableHeading>
          {getCommodityName(analyticsState?.analyticsSnapshotReportFilteredValues?.commodity[0])}{' '}
          Volume For {region}
        </TableHeading>

        <Flexbox justifyContent='flex-end' alignItems='center' style={{ flex: 1 }}>
          <ChartToggle setView={setView} />
          {view === 'Table' && <DownloadCsvButton data={tabledata} fileName={'SnapshotVolume'} />}
        </Flexbox>
      </Flexbox>
      {renderContent()}
    </ChartContainer>
  );
};

export default SnapshotWaterfallChart;
