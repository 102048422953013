import { PaddingTop } from '../../../../commonUtils/Common.styles';
import { ten } from '../../../constants';
import { BackToDashboard } from '../../BackToDashboard/BackToDashboard';
import { DasboardContainer } from '../../CarbonDashBoard/CarbonDashBoard.styles';
import Footer from '../../Footer/Footer';
import Loader from '../../Loader/Loader';
import ContractedVsPlanVsLe from '../ContractedVsPlanVsLe';
import { Divider } from '@sede-x/shell-ds-react-framework';

const ContractedVsPlanVsLeDetails = () => {
  return (
    <>
      <DasboardContainer>
        <BackToDashboard />
        <Divider size='small' direction='horizontal' color='#F5F5F5' />
        <Loader />
        <PaddingTop paddingTop={ten}>
          <ContractedVsPlanVsLe />
        </PaddingTop>
      </DasboardContainer>
      <Footer />
    </>
  );
};
export default ContractedVsPlanVsLeDetails;
