// TotalValuesStyles.js
import styled from 'styled-components';

export const TotalValuesContainer = styled.div`
  width: 100%;
  position: relative;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 16px;
  box-sizing: border-box;
  gap: 8px;
  text-align: left;
  font-size: 12px;
  color: #343434;
  font-family: 'Shell Font';
`;

export const TotalValues01 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 0px;
  gap: 16px;
`;

export const TableHeading = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
`;

export const Text = styled.div`
  position: relative;
  line-height: 18px;
`;

export const Text1 = styled.b`
  position: relative;
  font-size: 16px;
  line-height: 24px;
`;

export const NavigationCross = styled.div`
  width: 24px;
  position: relative;
  height: 24px;
  display: none;
`;

export const TotalValues02 = styled.div`
  width: 1336px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  font-size: 11px;
`;

export const SingleSelection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
`;

export const DeprecatedLabel = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Text12 = styled.div`
  flex: 1;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Text13 = styled.b`
  flex: 1;
  position: relative;
  line-height: 16px;
`;

export const DeprecatedTextInput = styled.div`
  align-self: stretch;
  border-radius: 2px;
  background-color: #f5f5f5;
  border: 1px solid rgba(28, 28, 28, 0.04);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 14px;
  color: #757575;
`;

export const Inner = styled.div`
  flex: 1;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 10px;
`;

export const Text15 = styled.div`
  flex: 1;
  position: relative;
  line-height: 22px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 22px;
`;