import { Modal } from '@sede-x/shell-ds-react-framework';
import React, { useContext } from 'react';
import { AppContext } from '../../../../Context/AppContext';

export const NcfServerErrorMessage = () => {
  const { state, dispatch } = useContext(AppContext).biaReports;

  const handleOnClose = () =>
    dispatch({ type: 'setIsNcfServerError', payload: false });

  const getTitle = () => {
    return state.ncfServerErrorMessage.includes('timeout')
      ? 'Timeout Error'
      : 'Server Error';
  };

  const getDescription = () => {
    if (state.ncfServerErrorMessage.includes('timeout')) {
      return 'The request took too long to process. Please try again later.';
    }
    return 'There was an error processing your request. Please try again later.';
  };

  return (
    <Modal
      onClose={handleOnClose}
      title={getTitle()}
      actions={[
        {
          label: 'Close',
          action: handleOnClose,
        },
      ]}
      open={state.isNcfServerError}
      centered
      bodyPadding={true}
    >
      {getDescription()}
    </Modal>
  );
};
