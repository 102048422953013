import React from 'react';
import { Alignments, Modal } from '@sede-x/shell-ds-react-framework';
import { DataReconETRMFileUpload } from './DataReconETRMFileUpload';

interface DataReconETRMFileUploadModalProps {
  open: boolean;
  closeModal: () => void;
}

const DataReconETRMFileUploadModal: React.FC<DataReconETRMFileUploadModalProps> = ({
  open,
  closeModal,
}) => {
  return (
    <Modal
      showFooter={false}
      closable={true}
      loading={false}
      width={731}
      open={open}
      onClose={closeModal}
      actionsAlignment={Alignments.Center}
      data-testid='loader_modal'
      title={'ETRM Upload'}
      description={'Upload ETRM for data ingestion (Max size 20MB)'}
    >
      <DataReconETRMFileUpload />
    </Modal>
  );
};

export default DataReconETRMFileUploadModal;
