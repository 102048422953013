import './Feedback.css';
import { Button } from '@sede-x/shell-ds-react-framework';
import { FEEDBACK_LINK } from '../../api/constants';
import { FEEDBACK } from '../../constants';

const Feedback = () => {
  const handleFeedback = () => {
    window.open(FEEDBACK_LINK, '_blank');
  };
  return (
    <Button variant='outlined' className='btn-size feedback-btn-style' onClick={handleFeedback}>
      {FEEDBACK}
    </Button>
  );
};

export default Feedback;
