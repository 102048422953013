import { useContext, useState } from 'react';
import { Flexbox, Button } from '@sede-x/shell-ds-react-framework';
import { AppContext } from '../../../../Context/AppContext';
import { YEAR_2023 } from '../../../../api/constants';
import { SelectFormField } from '../../../ReUsableComponents/SelectFormField/SelectFormField';
import { handleApplyFilters } from '../PpaInsightUtils';

export const PpaInsightsFilterSection = () => {
  const { state: ppaInsightState, dispatch: ppaInsightDispatch } =
    useContext(AppContext).ppaInsight;
  const { years, regions, renewableCategories, tenures, contractTypes, quarters } =
    ppaInsightState.filterOptions;
  const [yearSelected, setYearSelected] = useState(YEAR_2023);
  const [regionsSelected, setRegionsSelected] = useState<string[]>([]);
  const [quartersSelected, setQuartersSelected] = useState<string[]>([]);
  const [renewableCategorySelected, setRenewableCategorySelected] = useState('');
  const [tenureSelected, setTenureSelected] = useState('');
  const [contractTypeSelected, setContractTypeSelected] = useState('');

  const handleClearFilters = () => {
    setYearSelected(YEAR_2023);
    setRegionsSelected([]);
    setQuartersSelected([]);
    setRenewableCategorySelected('');
    setTenureSelected('');
    setContractTypeSelected('');
    ppaInsightDispatch({
      type: 'setFilteredValues',
      payload: {
        year: YEAR_2023,
        region: [],
        quarter: [],
        renewableCategory: '',
        tenure: '',
        contractType: '',
      },
    });
  };

  return (
    <div data-testid='filter' style={{ padding: '20px' }}>
      <Flexbox flexDirection='column'>
        <div
          style={{
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '17px',
            lineHeight: '26px',
            color: '#404040',
            padding: '10px 0',
            marginTop: '26px',
          }}
        >
          Filters
        </div>
        <SelectFormField
          id='year'
          label='Year'
          testId='Year'
          value={yearSelected}
          onChange={setYearSelected}
          options={years}
        />
        <SelectFormField
          id='quarter'
          label='Quarter'
          testId='Quarter'
          value={quartersSelected}
          onChange={setQuartersSelected}
          options={quarters}
          mode='multiple'
        />
        <SelectFormField
          id='region'
          label='Region'
          testId='Region'
          value={regionsSelected}
          onChange={setRegionsSelected}
          options={regions}
          mode='multiple'
        />
        <SelectFormField
          id='renewableCategory'
          label='Renewable Category'
          testId='Renewable Category'
          value={renewableCategorySelected}
          onChange={setRenewableCategorySelected}
          options={renewableCategories}
        />
        <SelectFormField
          id='tenure'
          label='Tenure'
          testId='Tenure'
          value={tenureSelected}
          onChange={setTenureSelected}
          options={tenures}
        />
        <SelectFormField
          id='contractType'
          label='Contract Type'
          testId='Contract Type'
          value={contractTypeSelected}
          onChange={setContractTypeSelected}
          options={contractTypes}
        />
        <Button
          className='getDataBtn'
          data-testid='Apply Filters'
          onClick={() =>
            handleApplyFilters(
              {
                yearSelected,
                renewableCategorySelected,
                tenureSelected,
                contractTypeSelected,
                regionsSelected,
                regions,
                quartersSelected,
                quarters,
              },
              ppaInsightDispatch,
            )
          }
        >
          Apply Filters
        </Button>
        <Button
          className='getDataBtn'
          variant='outlined'
          style={{ marginTop: '10px' }}
          data-testid='Clear Filters'
          onClick={handleClearFilters}
        >
          Clear Filters
        </Button>
      </Flexbox>
      <div style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.06)' }}></div>
    </div>
  );
};
