import React, { useEffect, useContext, useState } from 'react';
import { ChartContainer, TableHeading } from './RegionVolumeDetailsTable.style';
import { regionVolumeDetailsTableData } from '../../../../../../api/services';
import { BaseTable, Flexbox } from '@sede-x/shell-ds-react-framework';
import { AppContext } from '../../../../../../Context/AppContext';
import { RegionVolumeDetailsTableData } from '../../../../../../Types/types';
import Loader from '../../../../../Loader/Loader';
import DownloadCsvButton from '../../../../../ReUsableComponents/DownloadCsvButton/DownloadCsvButton';
import {
  OUT_OF_SCOPE_VOLUME_PARAMETER_NAME,
  WHOLESALE_CHURN_PARAMETER_NAME,
} from '../../AnalyticsHomeCharts/RegionVolumeChart/RegionVolumeChartUtils';
import { SortingState, getPaginationRowModel, getSortedRowModel } from '@tanstack/react-table';

const RegionVolumeDetailsTable: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [sorting, setSorting] = useState<SortingState>([]);
  const { state: analyticsState, dispatch: analyticsDispatch } =
    useContext(AppContext).analyticsHome;

  const mapData: RegionVolumeDetailsTableData[] = analyticsState.regionVolumeDetailsTableData;
  const sum = mapData?.reduce((acc, item) => acc + item.QTY, 0)?.toFixed(2);
  const tableFormattedData = [...mapData].map((el) => {
    return {
      ...el,
      QTY: Math.round(Number(el.QTY) * 100) / 100,
    };
  });

  const columnNames = [
    'YEAR',
    'QUARTER',
    'MONTH',
    'COMMODITY',
    'TRANSACTION_TYPE',
    'ORIGINAL_TRANSACTION_TYPE',
    'NCF_CATEGORY',
    'CPTY',
    'CPTY_DESC',
    'QTY',
    'NCF_SCOPE',
  ];
  const columns = columnNames.map((key) => ({
    header: key.replace(/_/g, ' '),
    accessorKey: key,
  }));

  useEffect(() => {
    analyticsDispatch({
      type: 'setRegionVolumeDetailsTableData',
      payload: [],
    });
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const parameterName =
          analyticsState.detailedViewParameter.parameterName === WHOLESALE_CHURN_PARAMETER_NAME
            ? OUT_OF_SCOPE_VOLUME_PARAMETER_NAME
            : analyticsState.detailedViewParameter.parameterName;
        const region = [analyticsState.detailedViewParameter.region];
        if (region && parameterName) {
          const response = await regionVolumeDetailsTableData({
            ...analyticsState.filteredValues,
            parameterName,
            region,
          });
          // Use the analyticsDispatch function to call setNcfQuarterwise
          analyticsDispatch({
            type: 'setRegionVolumeDetailsTableData',
            payload: response.data,
          });
        }
      } catch (error) {
        // Log error once toast feature is implemented
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [
    analyticsDispatch,
    analyticsState.detailedViewParameter.parameterName,
    analyticsState.detailedViewParameter.region,
    analyticsState.filteredValues,
  ]); // Add analyticsDispatch to the dependency array

  const tableOptions = {
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  };

  return (
    <ChartContainer>
      <Loader loader={isLoading} message={''} />
      <Flexbox style={{ width: '100%' }} justifyContent='space-between'>
        <TableHeading>
          Detail View of {analyticsState?.detailedViewParameter?.parameterName}
        </TableHeading>
        <DownloadCsvButton data={tableFormattedData} fileName={'RegionVolume'} />
      </Flexbox>
      <BaseTable
        columns={columns}
        data={tableFormattedData}
        size='large'
        data-testid='base-table'
        style={{ width: '100%' }}
        stickyHeader={true}
        tableOptions={tableOptions}
      />
      {Math.abs(Number(sum)) > 0 && (
        <Flexbox justifyContent='flex-start'>
          <TableHeading>Total: {sum}</TableHeading>
        </Flexbox>
      )}
    </ChartContainer>
  );
};

export default RegionVolumeDetailsTable;
