import { Button, Flexbox, Grid, Modal, TextInput } from '@sede-x/shell-ds-react-framework';
import React, { Dispatch, SetStateAction, useState } from 'react';

export const ConfirmDeleteRule = (props: {
  handleFinalDelete: () => Promise<void>;
  setShowConfirmDeleteModal: Dispatch<SetStateAction<boolean>>;
  showConfirmDeleteModal: boolean;
}) => {
  const [confirmDeleteText, setConfirmDeleteText] = useState('');
  const handleDeleteSelectedRule = () => {
    //console statement to delete rule
    props.handleFinalDelete();
    setConfirmDeleteText('');
  };
  const handleCancelDelete = () => {
    props.setShowConfirmDeleteModal(false);
    setConfirmDeleteText('');
  };
  return (
    <Modal
      title='CONFIRMATION TO PROCEED'
      open={props.showConfirmDeleteModal}
      onClose={handleCancelDelete}
      actions={[
        {
          label: '',
          showActionButton: false,
        },
        {
          label: '',
          showActionButton: false,
        },
      ]}
      showFooter={false}
    >
      <Grid areas={['description', 'input', 'buttons']}>
        <Grid.Cell area='description'>Please write DELETE in the textbox to confirm.</Grid.Cell>
        <Grid.Cell area='input'>
          <TextInput
            value={confirmDeleteText}
            onChange={(e: { target: { value: string } }) =>
              setConfirmDeleteText(e.target.value.toUpperCase())
            }
          />
        </Grid.Cell>
        <Grid.Cell area='buttons'>
          <Flexbox gap='20px' justifyContent='center'>
            <Button
              disabled={confirmDeleteText !== 'DELETE'}
              onClick={handleDeleteSelectedRule}
              variant='filled'
            >
              Confirm Delete
            </Button>
            <Button variant='outlined' onClick={handleCancelDelete}>
              {' '}
              Cancel Delete
            </Button>
          </Flexbox>
        </Grid.Cell>
      </Grid>
    </Modal>
  );
};
