import { Alignments, Modal } from '@sede-x/shell-ds-react-framework';
import React, { useContext } from 'react';
import { AppContext } from '../../Context/AppContext';

const Loader = (props: { loader?: boolean; message?: string }) => {
  const { state } = useContext(AppContext).appData;
  const isLoading = state.spinner || (props.loader ?? false);

  return (
    <Modal
      showFooter={false}
      closable={false}
      loading={true}
      bodyPadding={true}
      open={isLoading}
      loadingBody={
        props.message !== undefined && props.message !== '' ? (
          <div>{props.message}</div>
        ) : (
          <div>Loading...</div>
        )
      }
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClose={() => {}}
      actionsAlignment={Alignments.Center}
      data-testid='loader_modal'
    ></Modal>
  );
};

export default Loader;
