import React, { FC } from 'react';
import { Button } from '@sede-x/shell-ds-react-framework';
import { VIEW_DETAILS } from '../../constants';

type Float = 'left' | 'right' | 'none';
interface IViewDetails {
  handleClick: () => void;
  float?: Float;
}
const ViewDetailsButton: FC<IViewDetails> = ({ handleClick, float = 'right' }) => {
  const styles = {
    buttonContainer: {
      float,
      marginRight: '10px',
      paddingTop: '10px',
    },
  };
  return (
    <div style={styles.buttonContainer} data-testid='viewDetailsButton'>
      <Button onClick={handleClick} size='small' variant='outlined'>
        {VIEW_DETAILS}
      </Button>
    </div>
  );
};

export default ViewDetailsButton;
