import { ten, two } from '../../../constants';
import { currentYear } from '../../../utils/utils';
import { ProcessedDataItem, TradeData } from './types';
const historicalStartYear = currentYear - two;
const planningEndYear = currentYear + ten;
const actuals = 'actuals';
function totalQuantityAndIntensityByYear(
  data: TradeData[],
  sourcesHist: Set<string>,
  sourcesPlan: Set<string>,
  kpiSet: Set<string>,
  type: keyof TradeData,
): Map<string, number> {
  const historicalYearsMap = new Map();
  const planningYearsMap = new Map();
  const combinedMap = new Map();

  for (const item of data) {
    const isHistoricalSource = sourcesHist.has(item.SOURCE) && kpiSet.has(item.KPI);
    const isPlanningSource = sourcesPlan.has(item.SOURCE) && kpiSet.has(item.KPI);

    if (
      isHistoricalSource &&
      item.TRADE_YEAR >= historicalStartYear &&
      item.TRADE_YEAR <= currentYear
    ) {
      const yearKey = `${item.TRADE_YEAR}-actuals`;
      historicalYearsMap.set(yearKey, (historicalYearsMap.get(yearKey) || 0) + item[type]);
    }

    if (isPlanningSource && item.TRADE_YEAR >= currentYear && item.TRADE_YEAR < planningEndYear) {
      const yearKey = `${item.TRADE_YEAR}-plan`;
      planningYearsMap.set(yearKey, (planningYearsMap.get(yearKey) || 0) + item[type]);
    }
  }

  for (const [key, value] of historicalYearsMap) {
    combinedMap.set(key, value);
  }

  for (const [key, value] of planningYearsMap) {
    const existingValue = combinedMap.get(key) || 0;
    combinedMap.set(key, existingValue + value);
  }
  return combinedMap;
}

export function processAndSortData(
  data: TradeData[],
  sourcesHist: Set<string>,
  sourcesPlan: Set<string>,
  kpiSet: Set<string>,
  dataType: keyof TradeData,
): ProcessedDataItem[] {
  const totalQuantityByYear = totalQuantityAndIntensityByYear(
    data,
    sourcesHist,
    sourcesPlan,
    kpiSet,
    dataType, // QUANTITY or CARBON_EMISSION
  );

  const processedData: ProcessedDataItem[] = [];

  totalQuantityByYear.forEach((value, key) => {
    const [year, type] = key.split('-');
    processedData.push({ year, type, value });
  });

  processedData.sort((a, b) => {
    if (a.type === actuals && b.type !== actuals) {
      return -1;
    }
    if (a.type !== actuals && b.type === actuals) {
      return 1;
    }
    return a.year.localeCompare(b.year);
  });

  return processedData;
}
