import { toast, TypeOptions } from 'react-toastify';
import { Icon } from '../Icon';
import { icons } from '../Icon/icons';

const mapToastTypeToIcon: Record<TypeOptions, keyof typeof icons> = {
  default: 'alertCircle',
  error: 'cross',
  info: 'alertCircle',
  success: 'checkSolid',
  warning: 'alertCircle',
};

export const notify = (type: TypeOptions, message: string) => {
  toast(message, {
    type,
    icon: () => <Icon type={mapToastTypeToIcon[type]} />,
  });
};
