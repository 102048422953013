import { Grid, Flexbox, Divider } from '@sede-x/shell-ds-react-framework';
import CarbonIntensityChart from '../charts/CarbonIntensityChart';
import CountryBreakdownChart from '../charts/CountryBreakdownChart';
import RenewableSalesChart from '../charts/RenewableSalesChart';
import VolumeCarbonEmissionChart from '../charts/VolumeCarbonEmissionChart';
import { StyledDiv, SubTitle, Title } from './commodityOverview.style';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { COMMODITY_TITLE, COMMODITY_SUB_TITLE } from '../../constants';
import { filterCommodity } from './FilterFunctions';
import { setField } from '../../redux/commodityDetails/commodityDetailsSlice';
import { useEffect, useState } from 'react';

export const AllCharts = () => {
  const {
    commodityVal,
    carIntTargetPerRegion,
    commodityFilteredValue,
    masterCommodityFilteredValue,
    sauId,
    evpId,
    seIpuRegionId,
    ipuId,
    vpId,
    bizId,
    isHandleApply,
  } = useAppSelector((state) => state.commodityOverview);
  const dispatch = useAppDispatch();
  const [isEmptyChart, setIsEmptyChart] = useState(false);

  useEffect(() => {
    // when Apply Filter called, then no need to execute this
    if (!isHandleApply) {
      const filters = {
        TRADE_COMMODITY_NAME: commodityVal,
        SAU: sauId,
        EVP: evpId,
        SE_IPU_Region: seIpuRegionId,
        REPORTING_ENTITY_NAME: bizId,
      };
      const newFilteredValue = filterCommodity(filters, masterCommodityFilteredValue);
      dispatch(setField({ commodityFilteredValue: newFilteredValue }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commodityVal, sauId, evpId, seIpuRegionId, ipuId, vpId, bizId]);
  return (
    <Grid gap='10px'>
      <Grid.Cell>
        <div>
          <Title>{COMMODITY_TITLE}</Title>
          <SubTitle>{COMMODITY_SUB_TITLE}</SubTitle>
        </div>
      </Grid.Cell>
      <Grid.Cell>
        <StyledDiv>
          <VolumeCarbonEmissionChart
            commodityFilteredValue={commodityFilteredValue}
            chartType='sales'
            commodityVal={commodityVal}
          />
        </StyledDiv>

        <StyledDiv>
          <VolumeCarbonEmissionChart
            commodityFilteredValue={commodityFilteredValue}
            chartType='emissions'
            commodityVal={commodityVal}
          />
        </StyledDiv>

        <StyledDiv>
          <CarbonIntensityChart
            commodityVal={commodityVal}
            carIntTargetPerRegion={carIntTargetPerRegion}
            commodityFilteredValue={commodityFilteredValue}
          />
        </StyledDiv>
      </Grid.Cell>
      <Grid.Cell>
        {!isEmptyChart && (
          <>
            <Divider size='small' gutter='tight' />
            <div>
              {commodityFilteredValue.some(
                (item: { TRADE_COMMODITY_NAME: string }) => item.TRADE_COMMODITY_NAME === 'Power',
              )
                ? `The two graphs displayed below, show the data for `
                : 'The graph displayed below, show the data for '}
              <strong>current year</strong>
            </div>
          </>
        )}
        <Flexbox gap='10px'>
          {commodityFilteredValue.some(
            (item: { TRADE_COMMODITY_NAME: string }) => item.TRADE_COMMODITY_NAME !== 'LNG',
          ) && (
            <div style={{ marginBottom: '10px', width: '49%' }}>
              <CountryBreakdownChart
                commodityFilteredValue={commodityFilteredValue}
                commodityVal={commodityVal}
                setIsEmptyChart={setIsEmptyChart}
              />
            </div>
          )}
          <div style={{ marginBottom: '10px', width: '49%' }}>
            <RenewableSalesChart
              commodityFilteredValue={commodityFilteredValue}
              commodityVal={commodityVal}
              setIsEmptyChart={setIsEmptyChart}
            />
          </div>
        </Flexbox>
      </Grid.Cell>
    </Grid>
  );
};
