import React from 'react';
import { Link } from 'react-router-dom';

interface BreadcrumbItem {
  name: string;
  path?: string;
}

interface BreadcrumbProps {
  items: BreadcrumbItem[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
  return (
    <nav aria-label='breadcrumb'>
      <ol className='breadcrumb' style={{ display: 'flex', listStyle: 'none', padding: 0 }}>
        {items.map((item, index) => (
          <React.Fragment key={item.name}>
            <li className='breadcrumb-item'>
              {item.path ? <Link to={item.path}>{item.name}</Link> : item.name}
            </li>
            {index < items.length - 1 && <span style={{ margin: '0 8px' }}>{'>'}</span>}
          </React.Fragment>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;