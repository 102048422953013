import { pipeLineGas } from '../../constants';

interface Conversion {
  ID: string;
  UNIT_CATEGORY_ID: string;
  BASE_UOM: string;
  TARGET_UOM: string;
  CONVERSION: string;
  Createdby: string;
  createddate: string;
  Modifiedby: string;
  Modifieddate: string;
}

const createdDate = '2023-05-23T12:43:58.3570000';
const modifiedDate = '2023-10-04T08:26:20.3970000';
const conversionOne = '0.001000000000000';
const conversionValuTwo = '1000';
const conversionValueThree = '1000000';
const conversionValueFour = '1.000000000000000';
const conversionValueFive = '0.000001000000000';
const conversions: Conversion[] = [
  {
    ID: '1',
    UNIT_CATEGORY_ID: '1',
    BASE_UOM: 'gco2e',
    TARGET_UOM: 'mtpa',
    CONVERSION: '0.000000000001000',
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '2',
    UNIT_CATEGORY_ID: '1',
    BASE_UOM: 'ktco2e',
    TARGET_UOM: 'mtpa',
    CONVERSION: conversionOne,
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '3',
    UNIT_CATEGORY_ID: '1',
    BASE_UOM: 'mtpa',
    TARGET_UOM: 'gco2e',
    CONVERSION: '1000000000000',
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '4',
    UNIT_CATEGORY_ID: '1',
    BASE_UOM: 'mtpa',
    TARGET_UOM: 'ktco2e',
    CONVERSION: conversionValuTwo,
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '5',
    UNIT_CATEGORY_ID: '1',
    BASE_UOM: 'mtpa',
    TARGET_UOM: 't',
    CONVERSION: conversionValueThree,
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '6',
    UNIT_CATEGORY_ID: '1',
    BASE_UOM: 'mtpa',
    TARGET_UOM: 'tco2e',
    CONVERSION: conversionValueThree,
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '7',
    UNIT_CATEGORY_ID: '1',
    BASE_UOM: 't',
    TARGET_UOM: 'mtpa',
    CONVERSION: conversionValueFive,
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '8',
    UNIT_CATEGORY_ID: '1',
    BASE_UOM: 't',
    TARGET_UOM: 'tonne h2',
    CONVERSION: conversionValueFour,
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '9',
    UNIT_CATEGORY_ID: '1',
    BASE_UOM: 'tco2e',
    TARGET_UOM: 'mtpa',
    CONVERSION: conversionValueFive,
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '10',
    UNIT_CATEGORY_ID: '1',
    BASE_UOM: 'tonne h2',
    TARGET_UOM: 't',
    CONVERSION: conversionValueFour,
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '11',
    UNIT_CATEGORY_ID: '3',
    BASE_UOM: 'gwh',
    TARGET_UOM: 'twh',
    CONVERSION: conversionOne,
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '12',
    UNIT_CATEGORY_ID: '3',
    BASE_UOM: 'kwh',
    TARGET_UOM: 'twh',
    CONVERSION: '0.000000001000000',
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '13',
    UNIT_CATEGORY_ID: '3',
    BASE_UOM: 'mj',
    TARGET_UOM: 'twh',
    CONVERSION: '0.000000000277778',
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '14',
    UNIT_CATEGORY_ID: '3',
    BASE_UOM: 'mmbtu',
    TARGET_UOM: 'twh',
    CONVERSION: '0.000000293071000',
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '15',
    UNIT_CATEGORY_ID: '3',
    BASE_UOM: 'mwh',
    TARGET_UOM: 'twh',
    CONVERSION: conversionValueFive,
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '16',
    UNIT_CATEGORY_ID: '3',
    BASE_UOM: 'twh',
    TARGET_UOM: 'gwh',
    CONVERSION: conversionValuTwo,
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '17',
    UNIT_CATEGORY_ID: '3',
    BASE_UOM: 'twh',
    TARGET_UOM: 'kwh',
    CONVERSION: '1000000000.000000000000000',
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '18',
    UNIT_CATEGORY_ID: '3',
    BASE_UOM: 'twh',
    TARGET_UOM: 'mj',
    CONVERSION: '3600000000.000000000000000',
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '19',
    UNIT_CATEGORY_ID: '3',
    BASE_UOM: 'twh',
    TARGET_UOM: 'mmbtu',
    CONVERSION: '3412141.632999999914318',
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '20',
    UNIT_CATEGORY_ID: '3',
    BASE_UOM: 'twh',
    TARGET_UOM: 'mwh',
    CONVERSION: conversionValueThree,
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '21',
    UNIT_CATEGORY_ID: '3',
    BASE_UOM: 'gwh',
    TARGET_UOM: 'mwh',
    CONVERSION: conversionValuTwo,
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '22',
    UNIT_CATEGORY_ID: '3',
    BASE_UOM: 'mwh',
    TARGET_UOM: 'gwh',
    CONVERSION: conversionOne,
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '23',
    UNIT_CATEGORY_ID: '1',
    BASE_UOM: 'mmt',
    TARGET_UOM: 'tonne h2',
    CONVERSION: conversionValueFour,
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '24',
    UNIT_CATEGORY_ID: '1',
    BASE_UOM: 'tonne h2',
    TARGET_UOM: 'mmt',
    CONVERSION: conversionValueFour,
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '25',
    UNIT_CATEGORY_ID: '3',
    BASE_UOM: 'mwh',
    TARGET_UOM: 'mj',
    CONVERSION: '8073.656366',
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '26',
    UNIT_CATEGORY_ID: '3',
    BASE_UOM: 'mj',
    TARGET_UOM: 'mwh',
    CONVERSION: '0.000277777800000',
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '27',
    UNIT_CATEGORY_ID: '3',
    BASE_UOM: 'MMBTU',
    TARGET_UOM: 'mj',
    CONVERSION: '1055.000000000000000',
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '28',
    UNIT_CATEGORY_ID: '3',
    BASE_UOM: 'mj',
    TARGET_UOM: 'MMBTU',
    CONVERSION: '0.000947867000000',
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '29',
    UNIT_CATEGORY_ID: '3',
    BASE_UOM: 'MWH',
    TARGET_UOM: 'KWH',
    CONVERSION: conversionValuTwo,
    Createdby: 'ADMIN',
    createddate: createdDate,
    Modifiedby: 'ADMIN',
    Modifieddate: modifiedDate,
  },
  {
    ID: '30',
    UNIT_CATEGORY_ID: '2',
    BASE_UOM: 'MCM',
    TARGET_UOM: 'BSM3',
    CONVERSION: conversionOne,
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '31',
    UNIT_CATEGORY_ID: '2',
    BASE_UOM: 'MCM',
    TARGET_UOM: 'TWH',
    CONVERSION: '0.010000000000000',
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '32',
    UNIT_CATEGORY_ID: '3',
    BASE_UOM: 'TBTU',
    TARGET_UOM: 'MJ',
    CONVERSION: '0.001055000000000',
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '33',
    UNIT_CATEGORY_ID: '3',
    BASE_UOM: 'TBTU',
    TARGET_UOM: 'TWh',
    CONVERSION: '0.293000000000000',
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '34',
    UNIT_CATEGORY_ID: '3',
    BASE_UOM: 'gwh',
    TARGET_UOM: 'mj',
    CONVERSION: '8073656.37',
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '35',
    UNIT_CATEGORY_ID: '3',
    BASE_UOM: 'Gwh',
    TARGET_UOM: 'Kwh',
    CONVERSION: '1000000',
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '36',
    UNIT_CATEGORY_ID: '3',
    BASE_UOM: 'Gwh',
    TARGET_UOM: 'MMbtu',
    CONVERSION: '3412.14',
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '37',
    UNIT_CATEGORY_ID: '3',
    BASE_UOM: 'Gwh',
    TARGET_UOM: 'BSM3',
    CONVERSION: '0.000096644528',
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '38',
    UNIT_CATEGORY_ID: '3',
    BASE_UOM: 'Gwh',
    TARGET_UOM: 'Tonne H2',
    CONVERSION: '30303.03',
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '39',
    UNIT_CATEGORY_ID: '3',
    BASE_UOM: 'gwh',
    TARGET_UOM: 'mj2024',
    CONVERSION: '8073656.366',
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '40',
    UNIT_CATEGORY_ID: '3',
    BASE_UOM: 'gwh',
    TARGET_UOM: 'mj2025',
    CONVERSION: '7980885.962',
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '41',
    UNIT_CATEGORY_ID: '3',
    BASE_UOM: 'gwh',
    TARGET_UOM: 'mj2026',
    CONVERSION: '7911241.002',
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '42',
    UNIT_CATEGORY_ID: '3',
    BASE_UOM: 'gwh',
    TARGET_UOM: 'mj2027',
    CONVERSION: '7842801.037',
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '43',
    UNIT_CATEGORY_ID: '3',
    BASE_UOM: 'gwh',
    TARGET_UOM: 'mj2028',
    CONVERSION: '7775535.061',
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '44',
    UNIT_CATEGORY_ID: '3',
    BASE_UOM: 'gwh',
    TARGET_UOM: 'mj2029',
    CONVERSION: '7709413.123',
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '45',
    UNIT_CATEGORY_ID: '3',
    BASE_UOM: 'gwh',
    TARGET_UOM: 'mj2030',
    CONVERSION: '7644406.284',
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '46',
    UNIT_CATEGORY_ID: '3',
    BASE_UOM: 'gwh',
    TARGET_UOM: 'mj2031',
    CONVERSION: '7615831.86',
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '47',
    UNIT_CATEGORY_ID: '3',
    BASE_UOM: 'gwh',
    TARGET_UOM: 'mj2032',
    CONVERSION: '7587470.26',
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '48',
    UNIT_CATEGORY_ID: '3',
    BASE_UOM: 'gwh',
    TARGET_UOM: 'mj2033',
    CONVERSION: '7559319.116',
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
  {
    ID: '49',
    UNIT_CATEGORY_ID: '1',
    BASE_UOM: 'kg CO2e',
    TARGET_UOM: 'mtpa',
    CONVERSION: '0.000000001000',
    Createdby: 'Admin',
    createddate: createdDate,
    Modifiedby: 'Admin',
    Modifieddate: modifiedDate,
  },
];

const unitcategory = [
  {
    UNIT_ID: '1',
    UNIT_CODE: 'GWh',
    UNIT_NAME: 'Gigawatt hour',
    CreatedBy: 'Admin',
    UNIT_CATEGORY_ID: '3',
  },
  {
    UNIT_ID: '2',
    UNIT_CODE: 'TWh',
    UNIT_NAME: 'Terawatt hour',
    UNIT_CATEGORY_ID: '3',
  },
  {
    UNIT_ID: '3',
    UNIT_CODE: 'MMBTU',
    UNIT_NAME: 'One Million British thermal units',
    UNIT_CATEGORY_ID: '3',
  },
  {
    UNIT_ID: '4',
    UNIT_CODE: 'MWH',
    UNIT_NAME: 'megawatt hour',
    UNIT_CATEGORY_ID: '3',
  },
  {
    UNIT_ID: '5',
    UNIT_CODE: 'BSM3',
    UNIT_NAME: 'BSM3',
    UNIT_CATEGORY_ID: '2',
  },
  {
    UNIT_ID: '6',
    UNIT_CODE: 'MMT',
    UNIT_NAME: 'Million Metric Tonnes',
    UNIT_CATEGORY_ID: '1',
  },
  {
    UNIT_ID: '7',
    UNIT_CODE: 'Tonne H2',
    UNIT_NAME: 'Tonne H2',
    UNIT_CATEGORY_ID: '1',
  },
  {
    UNIT_ID: '8',
    UNIT_CODE: 'G',
    UNIT_NAME: 'gram',
    UNIT_CATEGORY_ID: '1',
  },
  {
    UNIT_ID: '9',
    UNIT_CODE: 'gCO2e',
    UNIT_NAME: 'gCO2e',
    UNIT_CATEGORY_ID: '1',
  },
  {
    UNIT_ID: '10',
    UNIT_CODE: 'T',
    UNIT_NAME: 'T',
    UNIT_CATEGORY_ID: '1',
  },
  {
    UNIT_ID: '11',
    UNIT_CODE: 'TCO2e',
    UNIT_NAME: 'TCO2e',
    UNIT_CATEGORY_ID: '1',
  },
  {
    UNIT_ID: '12',
    UNIT_CODE: 'KTCO2e',
    UNIT_NAME: 'KTCO2e',
    UNIT_CATEGORY_ID: '1',
  },
  {
    UNIT_ID: '13',
    UNIT_CODE: 'KT',
    UNIT_NAME: 'KT',
    UNIT_CATEGORY_ID: '1',
  },
  {
    UNIT_ID: '14',
    UNIT_CODE: 'MTPA',
    UNIT_NAME: 'mtpa',
    UNIT_CATEGORY_ID: '1',
  },
  {
    UNIT_ID: '15',
    UNIT_CODE: 'BCM',
    UNIT_NAME: 'bcm',
    UNIT_CATEGORY_ID: '2',
  },
  {
    UNIT_ID: '16',
    UNIT_CODE: 'KWH',
    UNIT_NAME: 'kwh',
    UNIT_CATEGORY_ID: '3',
  },
  {
    UNIT_ID: '17',
    UNIT_CODE: 'MJ',
    UNIT_NAME: 'mj',

    UNIT_CATEGORY_ID: '3',
  },
  {
    UNIT_ID: '18',
    UNIT_CODE: 'TBTU',
    UNIT_NAME: 'TBTU',

    UNIT_CATEGORY_ID: '3',
  },
  {
    UNIT_ID: '19',
    UNIT_CODE: 'BSCF',
    UNIT_NAME: 'BSCF',
    UNIT_CATEGORY_ID: '3',
  },
  {
    UNIT_ID: '20',
    UNIT_CODE: 'EJ',
    UNIT_NAME: 'EJ',
    UNIT_CATEGORY_ID: '3',
  },
];

const interdimensionConversions = [
  {
    UNIT_CONVERSION_INTERDIMENSION_ID: '1',
    COMMODITY: 'NG',
    BASE_UOM: 'bsm3',
    TARGET_UOM: 'twh',
    CONVERSION: '10.347197350000000',
    BASE_UNIT_CATEGORY_ID: '2',
    TARGET_UNIT_CATEGORY_ID: '3',
  },
  {
    UNIT_CONVERSION_INTERDIMENSION_ID: '2',
    COMMODITY: 'NG',
    BASE_UOM: 'bsm3',
    TARGET_UOM: 'tbtu',
    CONVERSION: '35.314666720000000',
    BASE_UNIT_CATEGORY_ID: '2',
    TARGET_UNIT_CATEGORY_ID: '3',
  },
  {
    UNIT_CONVERSION_INTERDIMENSION_ID: '3',
    COMMODITY: 'NG',
    BASE_UOM: 'bsm3',
    TARGET_UOM: 'bscf',
    CONVERSION: '35.314666720000000',
    BASE_UNIT_CATEGORY_ID: '2',
    TARGET_UNIT_CATEGORY_ID: '3',
  },
  {
    UNIT_CONVERSION_INTERDIMENSION_ID: '4',
    COMMODITY: 'NG',
    BASE_UOM: 'bsm3',
    TARGET_UOM: 'ej',
    CONVERSION: '0.037258951000000',
    BASE_UNIT_CATEGORY_ID: '2',
    TARGET_UNIT_CATEGORY_ID: '3',
  },
  {
    UNIT_CONVERSION_INTERDIMENSION_ID: '5',
    COMMODITY: 'NG',
    BASE_UOM: 'twh',
    TARGET_UOM: 'bsm3',
    CONVERSION: '0.096644528000000',
    BASE_UNIT_CATEGORY_ID: '3',
    TARGET_UNIT_CATEGORY_ID: '2',
  },
  {
    UNIT_CONVERSION_INTERDIMENSION_ID: '6',
    COMMODITY: 'NG',
    BASE_UOM: 'tbtu',
    TARGET_UOM: 'bsm3',
    CONVERSION: '0.028316847000000',
    BASE_UNIT_CATEGORY_ID: '3',
    TARGET_UNIT_CATEGORY_ID: '2',
  },
  {
    UNIT_CONVERSION_INTERDIMENSION_ID: '7',
    COMMODITY: 'NG',
    BASE_UOM: 'bscf',
    TARGET_UOM: 'bsm3',
    CONVERSION: '0.028316847000000',
    BASE_UNIT_CATEGORY_ID: '3',
    TARGET_UNIT_CATEGORY_ID: '2',
  },
  {
    UNIT_CONVERSION_INTERDIMENSION_ID: '8',
    COMMODITY: 'NG',
    BASE_UOM: 'ej',
    TARGET_UOM: 'bsm3',
    CONVERSION: '26.839188250000000',
    BASE_UNIT_CATEGORY_ID: '3',
    TARGET_UNIT_CATEGORY_ID: '2',
  },
  {
    UNIT_CONVERSION_INTERDIMENSION_ID: '9',
    COMMODITY: 'Hydrogen',
    BASE_UOM: 'mmt',
    TARGET_UOM: 'twh',
    CONVERSION: '33.329651030000000',
    BASE_UNIT_CATEGORY_ID: '1',
    TARGET_UNIT_CATEGORY_ID: '3',
  },
  {
    UNIT_CONVERSION_INTERDIMENSION_ID: '10',
    COMMODITY: 'Hydrogen',
    BASE_UOM: 'mmt',
    TARGET_UOM: 'mj',
    CONVERSION: '119986743725.490000000000000',
    BASE_UNIT_CATEGORY_ID: '1',
    TARGET_UNIT_CATEGORY_ID: '3',
  },
  {
    UNIT_CONVERSION_INTERDIMENSION_ID: '11',
    COMMODITY: 'LNG',
    BASE_UOM: 'kt',
    TARGET_UOM: 'mj',
    CONVERSION: '50089179.55',
    BASE_UNIT_CATEGORY_ID: '1',
    TARGET_UNIT_CATEGORY_ID: '3',
  },
  {
    UNIT_CONVERSION_INTERDIMENSION_ID: '12',
    COMMODITY: 'LNG',
    BASE_UOM: 'kt',
    TARGET_UOM: 'twh',
    CONVERSION: '0.013913672117040',
    BASE_UNIT_CATEGORY_ID: '1',
    TARGET_UNIT_CATEGORY_ID: '3',
  },
];

export function convertValue(
  originalValue: number,
  originalUOM: string,
  targetUOM: string,
  commodityName?: string | undefined,
): number | undefined {
  originalUOM = originalUOM.toLowerCase();
  targetUOM = targetUOM.toLowerCase();

  if (originalUOM === targetUOM) {
    return originalValue; // If original and target UOMs are the same, no conversion needed.
  }

  const conversion = conversions.find(
    (c) => c.BASE_UOM.toLowerCase() === originalUOM && c.TARGET_UOM.toLowerCase() === targetUOM,
  );
  const originalUOMcategoryId = unitcategory.find((c) => c.UNIT_CODE.toLowerCase() === originalUOM);
  const targetUOMcategoryId = unitcategory.find((c) => c.UNIT_CODE.toLowerCase() === targetUOM);
  const originalCategoryId = originalUOMcategoryId?.UNIT_CATEGORY_ID;
  const targetCategoryId = targetUOMcategoryId?.UNIT_CATEGORY_ID;
  const interdimensionConversion = interdimensionConversions.find((c) => {
    const hasMatchingCategories =
      c.BASE_UNIT_CATEGORY_ID === originalCategoryId &&
      c.TARGET_UNIT_CATEGORY_ID === targetCategoryId;

    const hasMatchingUOMs = c.BASE_UOM === originalUOM && c.TARGET_UOM === targetUOM;
    const hasMatchingCommodity = c.COMMODITY === commodityName;

    return hasMatchingCategories && hasMatchingUOMs && hasMatchingCommodity;
  });
  if (conversion) {
    // check the conversion value as per commodity. For gas, the conversion factor is statically set to 3240000 while the target uom is mj
    const conversionFactor =
      commodityName === pipeLineGas && targetUOM.includes('mj')
        ? 3240000
        : parseFloat(conversion.CONVERSION);
    return originalValue * conversionFactor;
  } else if (
    interdimensionConversion &&
    originalUOMcategoryId &&
    targetUOMcategoryId &&
    originalUOMcategoryId !== targetUOMcategoryId
  ) {
    const interdimensionconversionFactor = parseFloat(interdimensionConversion.CONVERSION);
    return originalValue * interdimensionconversionFactor;
  } else {
    return undefined;
  }
}
