import { FOOTER_TEXT, FOOTER_SUB_TEXT } from '../../constants';
import './Footer.css';

const Footer = () => {
  return (
    <div className='dashboard-footer'>
      <span>{FOOTER_TEXT}</span>
      <em>{FOOTER_SUB_TEXT}</em>
    </div>
  );
};

export default Footer;
