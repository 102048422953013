import React, { useContext, useEffect } from 'react';
import {
  Divider,
  Flexbox,
  Grid,
  Pagination,
  DeprecatedTable,
} from '@sede-x/shell-ds-react-framework';
import FilterSection from '../../FilterSection/FilterSection';
import { NoTableData } from '../../TableData/NoTableData/NoTableData';
import styled from 'styled-components';
import { AppContext } from '../../../Context/AppContext';
import { editAndAuditReportTableCols } from '../../TableData/EditAndAuditReportTableCols/editAndAuditReportTableCols';
import { fetchAuditReport } from '../../../api/services';
import Loader from '../../Loader/Loader';
import { showSpinner, hideSpinner } from '../../Utils/utils';
import { IAuditTableData } from '../../../Types/types';
import { ColumnsType } from '@sede-x/shell-ds-react-framework/build/esm/components/DeprecatedTable/Table.types';

const Container = styled.div`
  width: auto;
  height: auto;
`;

export const ViewAuditReportPage = () => {
  const { state, dispatch } = useContext(AppContext).appData;

  useEffect(() => {
    dispatch({ type: 'setCurrentPage', payload: 'Audit Report Page' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let cols: string[] = [];
  if (state.auditPaginationData.length > 0) {
    cols = Object.keys(state.auditPaginationData[0]);
  }

  //pagination
  const handleOnChange = async (current: number): Promise<void> => {
    dispatch({ type: 'setCurrentPaginationNum', payload: current });
    showSpinner(dispatch);
    const options = state.filteredOptions;
    options.pageNumber = current;
    try {
      const viewReport = await fetchAuditReport(options);
      dispatch({
        type: 'setAuditPaginationData',
        payload: viewReport.data.data,
      });
      hideSpinner(dispatch);
    } catch (e) {
      hideSpinner(dispatch);
    }
  };

  return (
    <div data-testid='view-audit-report-page'>
      <Grid gap='24px' areas={['description', 'filter', 'table']}>
        <Grid.Cell area='description'>
          <p>
            As Data gets edited on an Ad-Hoc basis using the Data Edit functionality, it is
            important to be able to understand and track how the data has changed.
          </p>
          <p>
            This feature has therefore been implemented to allow users to understand and to trace
            changes made to the data.
          </p>
          <br />
        </Grid.Cell>
        <Grid.Cell area='filter'>
          <FilterSection view={'audit report'} />
          <Divider
            size='medium'
            direction='horizontal'
            color='medium'
            style={{ marginTop: '10px' }}
          />
        </Grid.Cell>
        <Grid.Cell area='table'>
          {!state.showAuditReport ? (
            <NoTableData />
          ) : (
            <div data-testid='tableComponent'>
              <Flexbox gap='24px 24px' justifyContent='center'>
                <Grid rowGap='24px'>
                  <Container>
                    {state.spinner ? (
                      <Loader />
                    ) : (
                      <DeprecatedTable
                        data-testid='auditTable'
                        size='small'
                        data={state.auditPaginationData}
                        rowKey={(record: IAuditTableData) => record.Transaction_Id}
                        columns={
                          editAndAuditReportTableCols(
                            cols,
                            state,
                            dispatch,
                          ) as ColumnsType<IAuditTableData>
                        }
                        tableLayout='fixed'
                        wrapperStyle={{ border: '2px solid rgba(0,0,0,0.15)' }}
                      />
                    )}
                  </Container>
                  <Pagination
                    data-testid='pagination'
                    total={state.recordCount}
                    pageSize={state.defaultPageSize}
                    onChange={handleOnChange}
                  />
                </Grid>
              </Flexbox>
            </div>
          )}
        </Grid.Cell>
      </Grid>
    </div>
  );
};
