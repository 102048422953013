import React from 'react';
import { Title, SubTitle } from './PPAInsightHeader.style';

const PPAInsightHeader = () => (
  <div style={{ marginTop: '46px' }}>
    <Title>PPA Insights</Title>
    <SubTitle>For tracking PPA deals on Quarterly basis in order to help LTIP Reporting</SubTitle>
  </div>
);

export default PPAInsightHeader;
