import {
  I_ALIGNE_TABLE,
  I_GROUP_TABLE,
  ENDUR_I_GAS_TABLE,
  ENDUR_SEAM_REGION_SCOPE_TABLE,
  NUCLEUS_SEAM_REGION_SCOPE_TABLE,
  NUCLEUS_I_POWER_TABLE,
  ENDURSLMT_STATIC_MAP_NCF_TABLE,
  ENDURSLMT_I_POWER_TABLE,
  ENDURAU_I_GAS,
  ENDURAU_STATIC_MAP_NCF,
} from '../constants';

export default {
  regionForDataEditOptions: [
    { value: 'SELECT', label: 'SELECT' },
    { value: 'Europe', label: 'Europe' },
    { value: 'Americas', label: 'Americas' },
    { value: 'Asia', label: 'Asia' },
    { value: 'Australia', label: 'Australia' },
  ],
  regionForSnapshotAndConfigureRuleOptions: [
    { value: 'Europe', label: 'Europe' },
    { value: 'Americas', label: 'Americas' },
    { value: 'Australia', label: 'Australia' },
    { value: 'Asia-Japan', label: 'Asia-Japan' },
  ],
  tableNameOptionsEurope: [
    { value: 'SELECT', label: 'SELECT' },
    { value: I_ALIGNE_TABLE, label: I_ALIGNE_TABLE },
    { value: I_GROUP_TABLE, label: I_GROUP_TABLE },
  ],
  tableNameOptionsAustralia: [
    { value: 'SELECT', label: 'SELECT' },
    { value: ENDURAU_I_GAS, label: ENDURAU_I_GAS },
    { value: ENDURAU_STATIC_MAP_NCF, label: ENDURAU_STATIC_MAP_NCF },
  ],
  tableNameOptionsAmerica: [
    { value: 'SELECT', label: 'SELECT' },
    { value: ENDUR_I_GAS_TABLE, label: ENDUR_I_GAS_TABLE },
    { value: NUCLEUS_I_POWER_TABLE, label: NUCLEUS_I_POWER_TABLE },
    {
      value: ENDUR_SEAM_REGION_SCOPE_TABLE,
      label: ENDUR_SEAM_REGION_SCOPE_TABLE,
    },
    {
      value: NUCLEUS_SEAM_REGION_SCOPE_TABLE,
      label: NUCLEUS_SEAM_REGION_SCOPE_TABLE,
    },
  ],
  countryOptionsForAsiaRegion: [
    { value: 'SELECT', label: 'SELECT' },
    { value: 'Japan', label: 'Japan' },
  ],
  tableNameOptionsForJapan: [
    { value: 'SELECT', label: 'SELECT' },
    {
      value: ENDURSLMT_STATIC_MAP_NCF_TABLE,
      label: ENDURSLMT_STATIC_MAP_NCF_TABLE,
    },
    { value: ENDURSLMT_I_POWER_TABLE, label: ENDURSLMT_I_POWER_TABLE },
  ],
  commodityOptionsforGasTable: [{ value: 'NG', label: 'Natural Gas' }],
  commodityOptionsforPowerTable: [{ value: 'PW', label: 'Power' }],
  commodityOptions: [
    { value: 'NG', label: 'Natural Gas' },
    { value: 'PW', label: 'Power' },
  ],
  transactionTypeOptions: [
    { value: 'Buy', label: 'Buy' },
    { value: 'Sell', label: 'Sell' },
  ],
  cptyOptions: [
    { value: 'CITIGROUP', label: 'CITIGROUP' },
    { value: 'BIOCODE', label: 'BIOCODE' },
    { value: 'COCOSA', label: 'COCOSA' },
    { value: 'HILCORP_LGL', label: 'HILCORP_LGL' },
    { value: 'SHESP', label: 'SHESP' },
    { value: 'HUDSON', label: 'HUDSON' },
    { value: 'SHELL-WL', label: 'SHELL-WL' },
    { value: 'SEEL', label: 'SEEL' },
  ],
  defaultOption: [{ value: 'SELECT', label: 'SELECT' }],
};
