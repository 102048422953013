import { FC } from 'react';
import { Heading } from '@sede-x/shell-ds-react-framework';
import { Container } from './ErrorMessage.styles';

type ErrorMessageType = {
  message?: string;
};

const ErrorMessage: FC<ErrorMessageType> = ({ message }: ErrorMessageType) => (
  <Container data-testid='error-message'>
    <Heading type='h5'>{message}</Heading>
  </Container>
);

ErrorMessage.defaultProps = {
  message: 'Something went wrong, please try again later.',
};

export default ErrorMessage;
