import moment from 'moment';
import { TooltipFormatterContextObject, AxisLabelsFormatterContextObject } from 'highcharts';
import {
  ncfQuarterWiseData,
  ncfTableDataMonthly,
  ncfMonthWiseData,
} from '../../../../../../api/services';

export interface MapDataItem {
  DATE: string;
  Original_Transaction_Type: string;
  UNIT: string;
  QTY: number;
  TRADE_CODE: string;
  REGION: string;
  QUARTER: string;
  YEAR: number;
}

interface IRegionData {
  date: string;
  ncf: number;
  QUARTER?: string;
  YEAR?: number;
}

export interface ISeriesData {
  type: string;
  name: string;
  data: number[];
}

export interface IRegionQuarterData extends IRegionData {
  QUARTER: string;
  YEAR: number;
}

interface FilteredValues {
  year: string[];
  quarter: string[];
  month: string[];
  region: string[];
  countryCode: string[];
  commodity: string[];
  uom: string;
  ncfType: string;
}

interface AnalyticsState {
  filteredValues: FilteredValues;
}
const dateFormat = 'YYYY/MM/DD';

export const formatDateRange = (mapData: MapDataItem[]): string => {
  // Extract all dates from mapData and convert them to moment objects
  const dates = mapData.map((data) => moment(data.DATE));

  // Find the minimum and maximum dates
  const minDate = moment.min(dates);
  const maxDate = moment.max(dates);

  // Format the dates and construct the final string
  return `${minDate.format('MMM YYYY')} to ${maxDate.format('MMM YYYY')}`;
};

export const groupAndSortDataByRegion = (mapData: MapDataItem[]): Record<string, IRegionData[]> => {
  const dataByRegion = mapData.reduce((acc: Record<string, IRegionData[]>, item: MapDataItem) => {
    const { REGION, DATE, QTY, QUARTER, YEAR } = item;
    if (!acc[REGION]) {
      acc[REGION] = [];
    }
    const data: IRegionData = { date: DATE, ncf: QTY };
    if (!DATE) {
      data.QUARTER = QUARTER;
      data.YEAR = YEAR;
    }
    acc[REGION].push(data);
    return acc;
  }, {});

  // Sort data by date or by QUARTER and YEAR
  Object.keys(dataByRegion).forEach((region) => {
    dataByRegion[region].sort((a, b) => {
      if (a.date && b.date) {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      } else if (a.QUARTER && a.YEAR && b.QUARTER && b.YEAR) {
        return a.YEAR - b.YEAR || a.QUARTER.localeCompare(b.QUARTER);
      } else {
        return 0;
      }
    });
  });

  return dataByRegion;
};

export function getAllUniqueDates(dataByRegion: Record<string, IRegionData[]>): string[] {
  return Array.from(
    new Set(
      Object.values(dataByRegion)
        .flat()
        .map((item) => item.date),
    ),
  );
}

export function getAllUniqueDatesOrQuarters(dataByRegion: Record<string, IRegionData[]>): string[] {
  return Array.from(
    new Set(
      Object.values(dataByRegion)
        .flat()
        .map((item) => (item.date ? item.date : `${item.QUARTER} ${item.YEAR}`)),
    ),
  );
}

export const generateSeries = (dataByRegion: Record<string, IRegionData[]>): ISeriesData[] => {
  return Object.keys(dataByRegion).map((region) => ({
    type: 'line',
    name: region,
    data: dataByRegion[region].map((item: IRegionData) => item.ncf),
  }));
};

export const tooltipFormatter = (
  context: TooltipFormatterContextObject,
  mapData: MapDataItem[],
) => {
  const region: string = context?.series?.name ?? '';
  const x: string | number = context.x ?? '';
  const ncf: number = context.y ?? 0;
  let transactionType: string | undefined;
  if (moment(x as string, dateFormat, true).isValid()) {
    transactionType = mapData.find(
      (data) => data.DATE === x && data.REGION === region,
    )?.Original_Transaction_Type;
  } else {
    const [QUARTER, YEAR] = (x as string).split(' ');
    transactionType = mapData?.find(
      (data) => data.QUARTER === QUARTER && data.YEAR === Number(YEAR) && data.REGION === region,
    )?.Original_Transaction_Type;
  }
  return `<b>Region:</b> ${region}<br/>
          <b>Transaction Type:</b> ${transactionType}<br/>
          <b>QTY:</b> ${ncf}`;
};

export const axisLabelFormatter = (context: AxisLabelsFormatterContextObject) => {
  const value: string | number = context.value;
  if (typeof value === 'string' && moment(value, dateFormat, true).isValid()) {
    return moment(value, dateFormat).format('MMM YYYY');
  } else {
    return value;
  }
};

export const getCommodityName = (abbreviation: string) => {
  switch (abbreviation) {
    case 'PW':
      return 'Power';
    case 'NG':
      return 'Natural Gas';
    default:
      return 'Power';
  }
};

export const getTitleText = (mapData: MapDataItem[]) => {
  // Replace any[] with the actual type of mapData
  let titleText = 'Month'; // default title

  // Check the first item in your data
  if (mapData[0] && !mapData[0].DATE && mapData[0].QUARTER) {
    titleText = 'Quarter';
  }

  return titleText;
};

export const fetchDataBasedOnView = async (view: string, analyticsState: AnalyticsState) => {
  if (view === 'Table') {
    return ncfTableDataMonthly(analyticsState.filteredValues);
  } else if (analyticsState.filteredValues.year.length === 1) {
    return ncfMonthWiseData(analyticsState.filteredValues);
  } else {
    return ncfQuarterWiseData(analyticsState.filteredValues);
  }
};

export const generateColumns = (filteredRegions: string[], regions: string[]) => {
  const regionColumns = filteredRegions?.length ? filteredRegions : regions;
  const columnNames = ['YEAR', 'QUARTER', 'MONTH', ...regionColumns];
  return columnNames.map((key) => ({
    header: key,
    accessorKey: key,
  }));
};
