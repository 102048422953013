/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import { fetchMarginToCarbonRatio } from '../../../api/services';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { hideSpinner, showSpinner } from '../../../redux/datavisualization/datavisualizationSlice';
import { IMarginToCarbonData, ITransformIPUData, Iseries } from '../../../Types/types';
import Chart from '../../Chart/Chart';
import { highchartOptions } from './utils';
import { notify } from '../../Toast/notify';
import { FETCH_ERROR } from '../../../utils/constants';
import { LINE_CHART_TITLE } from '../../../constants';

const LineChart = () => {
  const [source, setSource] = useState<string[]>([]);
  const [series, setSeries] = useState<Iseries[]>([{ type: '', name: '', data: [[null]] }]);
  const dispatch = useAppDispatch();
  const { loggedInUserRegion, userEvp } = useAppSelector((state) => state.dashboard);

  const fetchData = async () => {
    dispatch(showSpinner());
    try {
      const margintocarbonratio = await fetchMarginToCarbonRatio(loggedInUserRegion, userEvp);
      const margintoCarbonData = margintocarbonratio?.data;
      margintoCarbonData && drawLineChart(margintoCarbonData);
    } catch {
      notify('error', FETCH_ERROR);
      dispatch(hideSpinner());
    }
    dispatch(hideSpinner());
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortRegionData = (transformData: Iseries[]) => {
    return transformData.map((item: Iseries) => ({
      ...item,
      data: item.data?.sort((a: (number | null)[], b: (number | null)[]) => {
        if (a[0] === null || b[0] === null) {
          return 0; // Handle null values
        }
        return a[0] - b[0];
      }),
    }));
  };

  const transformDataByIPU = (data: IMarginToCarbonData[]) => {
    const map: ITransformIPUData = data.reduce((acc, item) => {
      if (!acc[item.IPU]) {
        acc[item.IPU] = {
          name: item.IPU,
          type: 'line',
          data: [[item.TRADE_YEAR, item.MARGIN_CARBON_RATIO]],
        };
      } else {
        acc[item.IPU].data.push([item.TRADE_YEAR, item.MARGIN_CARBON_RATIO]);
      }
      return acc;
    }, {} as ITransformIPUData);

    return sortRegionData(Object.values(map));
  };

  const drawLineChart = (margintoCarbonData: IMarginToCarbonData[]) => {
    const sourcesSet = new Set(margintoCarbonData?.map((item: IMarginToCarbonData) => item.SOURCE));
    const uniqueSources: string[] = Array.from(sourcesSet);
    setSource(uniqueSources);
    const transformData = transformDataByIPU(margintoCarbonData).map((item) => {
      return {
        ...item,
        name: item.name.replace('R&ES ', ''),
      };
    });
    setSeries(transformData);
  };

  const options = highchartOptions(series);
  const [chartKey, setChartKey] = useState(0);
  const refreshChart = () => {
    setChartKey((prevKey) => prevKey + 1);
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    refreshChart();
  }, [source]);
  return (
    <div data-testid='line-chart'>
      <Chart
        chartKey={LINE_CHART_TITLE}
        highcharts={Highcharts}
        options={options}
        source={source.join(',')}
        key={chartKey}
      />
    </div>
  );
};

export default LineChart;
