import styled from 'styled-components';

export const CommodityTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-family: 'Shell font';
  font-size: 20px;
  color: #343434;
  line-height: 24px;
  margin-top: 10px;
  margin-bottom: 7px;
`;

export const StyledDiv = styled.div`
  width: 100%;
  margin-top: 10px;
`;

export const BorderDiv = styled.div`
  border-left: 1px solid rgba(0, 0, 0, 0.06);
`;

export const Title = styled.div`
  font-size: 24px;
  color: #343434;
  font-weight: 600;
  font-family: 'Shell Font';
  line-height: 24px;
  font-style: normal;
  fill: #343434;
  margin-top: 13px;
`;

export const SubTitle = styled.div`
  color: rgb(117, 117, 117);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-style: normal;
  font-family: 'Shell Font';
  fill: rgb(117, 117, 117);
  margin-top: 10px;
`;
