import React from 'react';
import { BiaReportLoader } from './BiaReportLoader/BiaReportLoader';
import { BiaReportTable } from './BiaReportTable/BiaReportTable';
import { IBiaReportTableData } from '../../../../Types/types2';
import { Flexbox } from '@sede-x/shell-ds-react-framework';
import DownloadCsvButton from '../../../ReUsableComponents/DownloadCsvButton/DownloadCsvButton';

interface IBiaReportTable {
  description: string;
  isLoading: boolean;
  loadingText: string;
  biaData: IBiaReportTableData;
}

export const BiaTable = ({ description, isLoading, loadingText, biaData }: IBiaReportTable) => (
  <div style={{ paddingBottom: '10px' }} data-testid='bia-report-table-container'>
    <Flexbox style={{ width: '100%' }} justifyContent='space-between' alignItems='center'>
      <h2 style={{ fontWeight: 'bold' }}>{description}</h2>
      <DownloadCsvButton data={biaData} fileName={'BiaReportDataValidationCheck'} />
    </Flexbox>

    {isLoading ? (
      <BiaReportLoader loadingText={loadingText} />
    ) : (
      <BiaReportTable biaData={biaData} />
    )}
  </div>
);
