/* eslint-disable no-undef */
import { type User, UserManager } from 'oidc-client';

const postLogoutRedirectUri = process.env.REACT_APP_WEB_URL;
const clientId = process.env.REACT_APP_SSO_CLIENT_ID;
const authorityUrl = process.env.REACT_APP_SSO_AUTHORITY_URL;

export class AuthService {
  public userManager: UserManager;

  constructor() {
    const settings = {
      authority: authorityUrl,
      client_id: clientId,
      // redirect_uri: postLogoutRedirectUri,
      redirect_uri: `${postLogoutRedirectUri}signin-callback.html`,
      post_logout_redirect_uri: postLogoutRedirectUri,
      automaticSilentRenew: true,
      response_type: 'code',
    };
    this.userManager = new UserManager(settings);
  }

  public getUser(): Promise<User | null> {
    return this.userManager.getUser();
  }

  public login(isTokenExpired = false): Promise<User | void> {
    if (isTokenExpired) {
      return this.userManager.signinSilent({ extraQueryParams: { kc_idp_hint: 'oidc' } });
    }
    return this.userManager.signinRedirect({ extraQueryParams: { kc_idp_hint: 'oidc' } });
  }

  public logout(): Promise<void> {
    return this.userManager.signoutRedirect();
  }
}
