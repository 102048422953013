/* eslint-disable no-unused-vars */
import {
  IReconAction,
  IReconData,
  IReconFilteredValues,
  IReconFilterOptions,
  IReconReportDefaultTotalVolume,
  IReconReportLastRefreshedDate,
  IReconReportNcfCategoryMismatchTableData,
  IReconReportTotalVolume,
  IReconReportVolumeTableData,
} from '../../Types/types';
import { IReconDataPayload } from '../../Types/types2';

const ReconActionHandlers: Record<
  string,
  (state: IReconData, payload: IReconDataPayload) => IReconData
> = {
  setReconFilterOptions: (state, payload) => ({
    ...state,
    ReconfilterOptions: payload as IReconFilterOptions,
  }),
  setReconFilteredValues: (state, payload) => ({
    ...state,
    ReconfilteredValues: payload as IReconFilteredValues,
  }),
  setReconReportTotalVolume: (state, payload) => ({
    ...state,
    reconReportTotalVolume: payload as IReconReportTotalVolume,
  }),
  setReconReportDefaultTotalVolume: (state, payload) => ({
    ...state,
    reconReportDefaultTotalVolume: payload as IReconReportDefaultTotalVolume,
  }),
  setReconReportVolumeTableData: (state, payload) => ({
    ...state,
    reconReportVolumeTableData: payload as IReconReportVolumeTableData,
  }),
  setReconReportNcfCategoryMismatchTableData: (state, payload) => ({
    ...state,
    reconReportNcfCategoryMismatchTableData: payload as IReconReportNcfCategoryMismatchTableData[],
  }),
  setReconReportLastRefreshedDate: (state, payload) => ({
    ...state,
    reconReportLastRefreshedDate: payload as IReconReportLastRefreshedDate,
  }),
};

export const ReconReportsReducers = (state: IReconData, action: IReconAction) => {
  if (Object.hasOwn(ReconActionHandlers, action.type)) {
    const handler = ReconActionHandlers[action.type];
    return handler(state, action.payload);
  }
  return state;
};
