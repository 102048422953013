import {
  CarbonBudgetData,
  IRegionChartSeries,
  IYearDataRegion,
  RegionDataObject,
  UniqueYearType,
} from '../../../Types/types';
import {
  PARIG_FINANCE_REPORTING,
  PLANNING_ONE,
  POWER_INTENSITY_MODEL,
  POWER_INTENSITY_MODEL_RES,
  REPORTONE,
  TANSO,
  TANSO_FUTURE,
} from '../../../api/constants';
import { colorMapping } from '../../../constants';
import { updateSplitDataWithRegion } from '../../../utils';

const historicalSources = [TANSO, REPORTONE, POWER_INTENSITY_MODEL, POWER_INTENSITY_MODEL_RES];
const planningSources = [PLANNING_ONE, PARIG_FINANCE_REPORTING, TANSO_FUTURE];

const determineYearType = (source: string, TRADE_YEAR: number): string => {
  const sources = source.split(', ');

  for (const src of sources) {
    if (planningSources.includes(src)) {
      return `${TRADE_YEAR}-plan`;
    } else if (historicalSources.includes(src)) {
      return `${TRADE_YEAR}-historical`;
    }
  }
  return `${TRADE_YEAR}-historical`;
};

const mapToYearTypeData = (line: CarbonBudgetData) => {
  const {
    REGION,
    SOURCE,
    TRADE_YEAR,
    TRADE_COMMODITY_NAME,
    CARBON_BUDGET,
    CARBON_EMISSION,
    QUANTITY,
  } = line;
  const yearType = determineYearType(SOURCE, TRADE_YEAR);
  return {
    yearType: yearType || '',
    TRADE_YEAR,
    TRADE_COMMODITY_NAME,
    CARBON_BUDGET,
    CARBON_EMISSION,
    QUANTITY,
    SOURCE,
    year: TRADE_YEAR,
    type: yearType?.includes('historical') ? 'historical' : 'plan',
    REGION,
  } as IYearDataRegion;
};
const changeAfricaToEurope = (carbonBudgetData: CarbonBudgetData[]) => {
  return carbonBudgetData.map((item: CarbonBudgetData) => {
    if (item.REGION === 'AFRICA') {
      return { ...item, REGION: 'EUROPE' };
    } else {
      return item;
    }
  });
};

const capitalizeFirstLetter = (word: string) => {
  return word ? word[0].toUpperCase() + word.slice(1).toLowerCase() : word;
};

const getMappedField = (showEmissionChart: boolean) => {
  return showEmissionChart ? 'QUANTITY' : 'CARBON_EMISSION';
};
export const splitDataByRegion = (
  carbonBudgetData: CarbonBudgetData[],
  newUniqueYears: UniqueYearType[],
  showEmissionChart: boolean,
  selectedRegion: string,
) => {
  const mappedField = getMappedField(showEmissionChart);
  const updatedData: CarbonBudgetData[] = changeAfricaToEurope(carbonBudgetData);
  const ignoreGlobalRegion = updatedData.filter(
    (item: CarbonBudgetData) => item.REGION !== 'GLOBAL',
  );
  const splitDataWithYearType: IYearDataRegion[] = ignoreGlobalRegion.map(mapToYearTypeData);
  const splitDataWithRegion: RegionDataObject = {
    AMERICAS: {
      'Pipeline Gas (Americas)': [],
      'Power (Americas)': [],
    },
    ASIA: {
      'Pipeline Gas (Asia)': [],
      'Power (Asia)': [],
    },
    AUSTRALIA: {
      'Pipeline Gas (Australia)': [],
      'Power (Australia)': [],
    },
    EUROPE: {
      'Pipeline Gas (Europe)': [],
      'Power (Europe)': [],
    },
  };
  const selectedRegionUpperCase = selectedRegion
    ? selectedRegion?.trim()?.toUpperCase()?.replace('EUROPE & EMP', 'EUROPE')
    : null;
  const filterRegion =
    !selectedRegionUpperCase || selectedRegionUpperCase === 'ALL'
      ? splitDataWithRegion
      : {
          [selectedRegionUpperCase]: splitDataWithRegion[selectedRegionUpperCase],
        };
  splitDataWithYearType.forEach((obj: IYearDataRegion) => {
    const region: string = obj.REGION as string;
    const commodity: string = obj.TRADE_COMMODITY_NAME;

    const yearType: string = obj.yearType;
    const commmodityRegion = `${commodity} (${capitalizeFirstLetter(region)})`;
    if (!filterRegion[region]) {
      filterRegion[region] = {};
    }
    if (!filterRegion[region][commmodityRegion]) {
      filterRegion[region][commmodityRegion] = [];
    }
    const existingObj = filterRegion[region][commmodityRegion].find(
      (item) => item.yearType === yearType,
    );

    if (existingObj) {
      existingObj[mappedField] += obj[mappedField] ?? 0;
    } else {
      filterRegion[region][commmodityRegion].push(obj);
    }
  });
  const updatedSplitDataWithRegion = updateSplitDataWithRegion(filterRegion);
  return createSeries(updatedSplitDataWithRegion, newUniqueYears, showEmissionChart);
};

const createSeries = (
  carbonBudgetData: RegionDataObject,
  newUniqueYears: UniqueYearType[],
  showEmissionChart: boolean,
) => {
  const mappedField = getMappedField(showEmissionChart);
  const splitData: IRegionChartSeries[] = [];
  let carbonBudget: (number | null)[] = [];
  Object.keys(carbonBudgetData).forEach((region: string) => {
    const regionData = carbonBudgetData[region];
    Object.keys(regionData).forEach((key: string) => {
      const updatedRegionData: IYearDataRegion[] = getUpdatedRegionData(
        regionData[key],
        newUniqueYears,
      ).sort(sortbyYearType);
      carbonBudget = carbonBudget ? getCarbonBudgetData(regionData[key]) : carbonBudget;
      if (key !== 'total') {
        const splitObject: IRegionChartSeries = {
          name: key,
          dashStyle: 'Solid',
          color: getColor(key),
          data: updatedRegionData.map((item: IYearDataRegion) => item[mappedField]),
          type: 'area',
        };
        splitData.push(splitObject);
      }
    });
  });
  return sortByCommodityAndRegion(splitData);
};

const sortByCommodityAndRegion = (splitData: IRegionChartSeries[]) => {
  const commodityOrder = ['Pipeline Gas', 'Power'];
  const regionOrder = ['Americas', 'Europe', 'Asia', 'Australia'];
  return splitData.sort((a, b) => {
    const aCommodity = a.name.split(' (')[0];
    const bCommodity = b.name.split(' (')[0];
    const aRegion = a.name.split('(')[1].split(')')[0];
    const bRegion = b.name.split('(')[1].split(')')[0];

    if (commodityOrder.indexOf(aCommodity) !== commodityOrder.indexOf(bCommodity)) {
      return commodityOrder.indexOf(aCommodity) - commodityOrder.indexOf(bCommodity);
    }
    return regionOrder.indexOf(aRegion) - regionOrder.indexOf(bRegion);
  });
};

const getUpdatedRegionData = (data: IYearDataRegion[], newUniqueYears: UniqueYearType[]) => {
  const newYearObj = {
    CARBON_BUDGET: null,
    CARBON_EMISSION: 0,
    QUANTITY: 0,
    REGION: '',
    SOURCE: '',
    TRADE_COMMODITY_NAME: '',
    TRADE_YEAR: 0,
    type: '',
    year: 0,
    yearType: '',
  };
  newUniqueYears.forEach((yearObj: UniqueYearType) => {
    const { year, type } = yearObj;
    const newYearType = `${year}-${type}`;
    const exists = data.some((dataObj: IYearDataRegion) => dataObj.yearType === newYearType);
    if (!exists) {
      data.push({ ...newYearObj, TRADE_YEAR: year, year, type, yearType: newYearType });
    }
  });
  return data;
};
const getCarbonBudgetData = (carbonData: IYearDataRegion[]) => {
  return carbonData.map((item: IYearDataRegion) => {
    return item.CARBON_BUDGET;
  });
};
export const sortbyYearType = (a: IYearDataRegion, b: IYearDataRegion) => {
  const [yearA, typeA] = a.yearType.split('-');
  const [yearB, typeB] = b.yearType.split('-');
  if (typeA === 'historical' && typeB === 'historical') {
    return parseInt(yearA, 10) - parseInt(yearB, 10);
  } else if (typeA === 'historical') {
    return -1;
  } else if (typeB === 'historical') {
    return 1;
  } else {
    return parseInt(yearA, 10) - parseInt(yearB, 10);
  }
};

const getColor = (name: string) => {
  return colorMapping[name];
};
