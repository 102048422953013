import { HighchartsReactProps } from 'highcharts-react-official';
import {
  TITLE_FONT_FAMILY,
  CHART_AXIS_STYLE,
  pipeLineGas,
  GAS_COMMODITY_ORDER,
} from '../../../constants';
import { currentYear } from '../../../utils/utils';
import Highcharts from 'highcharts';
import { YearProcessedDataItem } from './types';
import { getColorByIndex } from '../../../utils';

const left = 'left';

interface HighchartOption {
  chartSubtitleKPI: string;
  resultYearArray: YearProcessedDataItem[];
  processedCombinedSortArrayWithKey: Highcharts.SeriesBarOptions[];
}
export const highchartOption = (highchartOptionData: HighchartOption) => {
  const { chartSubtitleKPI, resultYearArray, processedCombinedSortArrayWithKey } =
    highchartOptionData || {};
  let transformedData = processedCombinedSortArrayWithKey;
  if (chartSubtitleKPI.includes('Gas')) {
    const pipelineGasEntries = processedCombinedSortArrayWithKey.filter(
      (item) => item.name === pipeLineGas,
    );

    if (pipelineGasEntries.length > 1) {
      const mergedData = pipelineGasEntries.reduce(
        (acc, item) => {
          item?.data?.forEach((value, index) => {
            if (acc[index] !== undefined) {
              acc[index] += value as number;
            } else {
              acc[index] = value as number;
            }
          });
          return acc;
        },
        Array.from({ length: pipelineGasEntries[0]?.data?.length ?? 0 }, () => 0),
      );

      const mergedPipelineGasEntry = {
        ...pipelineGasEntries[0],
        data: mergedData,
      };
      transformedData = processedCombinedSortArrayWithKey
        .filter((item) => item.name !== pipeLineGas)
        .concat(mergedPipelineGasEntry);
    }
  }
  transformedData = transformedData.sort((a, b) => {
    return (
      GAS_COMMODITY_ORDER.indexOf(a.name as string) - GAS_COMMODITY_ORDER.indexOf(b.name as string)
    );
  });
  const updatedRegionName = resultYearArray.map((x: YearProcessedDataItem) => {
    return {
      ...x,
      country: x.type === 'Other' ? `${x.country}-${x.type}` : x.country,
    };
  });
  const options: Highcharts.Options = {
    chart: {
      type: 'bar',
      height: 500,
      style: {
        fontFamily: 'Shell Font, Arial, sans-serif', // Set 'Shell Font' as the primary font family for the whole chart
      },
    },

    title: {
      text: `Country Breakdown (${currentYear})`,
      align: left,
      x: 16,
      y: 20,
      style: {
        fontSize: '20px',
        fontFamily: TITLE_FONT_FAMILY,
      },
    },
    subtitle: {
      text: `${chartSubtitleKPI} by Region.`,
      align: left,
      x: 16,
      style: {
        fontSize: '14px',
        fontFamily: TITLE_FONT_FAMILY,
      },
    },
    xAxis: {
      gridLineWidth: 1,
      categories: updatedRegionName.map((x: YearProcessedDataItem) => x.country),
      title: {
        text: 'Country',
        useHTML: true,
        style: CHART_AXIS_STYLE,
      },
      labels: {
        formatter() {
          const value = (this as HighchartsReactProps)?.value;
          if (value === 'Multiple') {
            return `<span style="color: red;"> ${value} + </span>`;
          } else {
            return value.toString();
          }
        },
      },
    },

    yAxis: {
      gridLineWidth: 1,
      title: {
        useHTML: true,
        style: CHART_AXIS_STYLE,
        text: 'Volume (TWh)',
      },
    },
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          x: 5,
          y: -10,
          menuItems: ['downloadPNG', 'downloadSVG'],
        },
      },
    },
    legend: {
      reversed: true,
      x: 16,
      align: left,
      itemStyle: {
        fontSize: '12px',
        fontWeight: 'light',
        color: '#757575',
        fontFamily: 'Shell Font, Arial, sans-serif',
      },
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        dataLabels: {
          enabled: false,
        },
      },
    },
    tooltip: {
      shared: true,
      headerFormat: '<span style="font-size:12px"><b>{point.key}</b></span><br>',
      valueDecimals: 2,
    },
    series: transformedData.map((item, index) => {
      return {
        ...item,
        color: getColorByIndex(index, item.name as string),
      };
    }),
  };
  return options;
};
