import styled from 'styled-components';

export const ChartContainer = styled.div`
  margin-bottom: 10px;
  background: #fff;
  //align-items: flex-start;
  padding: 10px;
`;

export const TableHeading = styled.span`
  font-size: 20px;
  color: rgb(52, 52, 52);
  font-weight: 600;
  font-family: 'Shell Font';
  line-height: 24px;
  letter-spacing: 0em;
  font-style: normal;
  text-align: left;
  fill: rgb(52, 52, 52);
  margin-bottom: 11px;
  margin-top: 4px;
`;

export const NcfToggle = styled.div`
  margin-top: 6px;
`;

export const ScrollableDiv = styled.div`
  overflow: auto;
  max-height: 500px;
`;

export const StyleSpanText = styled.span<{ value: number }>`
  color: ${({ value }) => (value !== 0 ? 'red' : 'inherit')};
`;
