import React from 'react';
import { IUserInfo } from '../../../CarbonDashboard/Types/types';
import AppRouter from '../../AppRouter';
import { LoginPage } from '../LoginPage/LoginPage';
import { UnAuthUserPage } from '../UnAuthUserPage/UnAuthUserPage';
import { ONE, UNAUTHORISED } from '../../../Tanso/api/constants';
import { SelectRolePage } from '../SelectRolePage/SelectRolePage';
import { useAppSelector } from '../../../CarbonDashboard/redux/hooks';

interface RoleBasedUserProps {
  user: IUserInfo;
  login: () => Promise<void>;
  logout: () => Promise<void>;
}

const RoleBasedUser: React.FC<RoleBasedUserProps> = ({ user, login, logout }) => {
  const { loggedInUserRoles } = useAppSelector((state) => state.dashboard);
  const isUnauthorizedUser = loggedInUserRoles[0] === UNAUTHORISED;
  const isNewLogIn = loggedInUserRoles[0] === '';
  const doesUserHaveMultipleRoles =
    Array.isArray(loggedInUserRoles) && loggedInUserRoles.length > ONE;

  switch (true) {
    case isUnauthorizedUser:
      return <UnAuthUserPage role={loggedInUserRoles} user={user} />;
    case isNewLogIn:
      return <LoginPage login={login} />;
    case doesUserHaveMultipleRoles:
      return <SelectRolePage />;
    default:
      return <AppRouter user={user} logout={logout} />;
  }
};

export default RoleBasedUser;
