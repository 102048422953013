import { Divider, Grid } from '@sede-x/shell-ds-react-framework';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../Context/AppContext';
import { SECTORAL_PERFORMANCE_DESC } from '../../../api/constants';
import { SectoralPerformanceCards } from './SectoralPerformanceCards';
import { SectoralSpinner } from './SectoralSpinner';
import { SectoralServerErrorPage } from './SectoralServerErrorPage';
import { getStep01Log, getStep02Log, getStep03Log } from '../../../api/services';
import { showSpinner, hideSpinner } from '../../Utils/utils';
import { getLogDateInFormat } from './SectoralInfoUtils';

export const SectoralPerformance = () => {
  const { state, dispatch } = useContext(AppContext).appData;
  const [step01Log, setStep01Log] = useState('');
  const [step02Log, setStep02Log] = useState('');
  const [step03LastRunDate, setStep03LastRunDate] = useState('');
  const [step03LastSuccessRunDate, setStep03LastSuccessRunDate] = useState('');
  const [step03ReportStatus, setStep03ReportStatus] = useState('');
  const [isServerError, setIsServerError] = useState(false);

  useEffect(() => {
    dispatch({ type: 'setCurrentPage', payload: 'Sectoral Performance' });
    fetchUpdateInfo();
  }, []);

  const fetchUpdateInfo = async () => {
    showSpinner(dispatch);
    try {
      const step01LogResp = await getStep01Log();
      const step02LogResp = await getStep02Log();
      const step03LogResp = await getStep03Log();
      const step01LogDate = step01LogResp.data.Last_Modified_Date ?? new Date();
      const step02LogDate = step02LogResp.data.Update_Date ?? new Date();
      const step03LastRunDateData = step03LogResp?.data?.lastRunDateTime ?? new Date();
      const step03LastSuccessRunDateData =
        step03LogResp?.data?.lastSuccessRunDateTime ?? new Date();
      setStep03ReportStatus(step03LogResp.data.lastRunStatus);
      const [step01LogData, step02LogData, step03LastRunData, step03LastSuccessRunData] =
        getLogDateInFormat(
          step01LogDate,
          step02LogDate,
          step03LastRunDateData,
          step03LastSuccessRunDateData,
        );
      setStep01Log(`Updated: Last extracted on ${step01LogData}`);
      setStep02Log(`Updated: Last uploaded on  ${step02LogData}`);
      setStep03LastRunDate(`Updated: Last run on ${step03LastRunData}`);
      setStep03LastSuccessRunDate(step03LastSuccessRunData);
      setIsServerError(false);
    } catch (e) {
      setIsServerError(true);
    }
    hideSpinner(dispatch);
  };

  return (
    <Grid areas={['description', 'actions', 'logs']} gap='20px' data-testid='sectoralMainPage'>
      {state.spinner && <SectoralSpinner />}
      <SectoralServerErrorPage isServerError={isServerError} setIsServerError={setIsServerError} />
      <Grid.Cell area='description'>
        {SECTORAL_PERFORMANCE_DESC}
        <br />
        <br />
        <Divider />
      </Grid.Cell>
      <Grid.Cell area='actions'>
        <SectoralPerformanceCards
          step03ReportStatus={step03ReportStatus}
          cardLogs={{
            step01Log,
            step02Log,
            step03LastRunDate,
            step03LastSuccessRunDate,
          }}
          setIsServerError={setIsServerError}
        />
      </Grid.Cell>
    </Grid>
  );
};
