/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Grid,
  Tabs,
  Divider,
  Select,
  Button,
  Option,
  Flexbox,
} from '@sede-x/shell-ds-react-framework';
import { ArrowCw } from '@sede-x/shell-ds-react-framework/build/esm/components/Icon/components';
import { SubRegion } from '..';
import { ALL_REGIONS } from '../../../api/constants';
import { SubTitle } from '../ScenerioModelling.styles';
import { useAppSelector } from '../../../redux/hooks';
import { CommodityDetailsState } from '../../../redux/commodityDetails/commodityDetails.types';
import { formatRegionName, getRegionVal } from '../../../utils';
import { BUSINESS_PLAN_KEY } from '../../../constants';

interface RegionTabsProps {
  selectedRegion: string;
  handleTabChange: (key: string) => void;
  subRegionOptions: SubRegion[];
  selectedSubRegion: string;
  setSelectedSubRegion: (value: string) => void;
  handleRevertAllToOriginal: () => void;
}
const RegionTabs: React.FC<RegionTabsProps> = ({
  selectedRegion,
  handleTabChange,
  subRegionOptions,
  selectedSubRegion,
  setSelectedSubRegion,
  handleRevertAllToOriginal,
}) => {
  const { userRegionFlags, tabKey, loggedInUserRegion } = useAppSelector(
    (state) => state.dashboard,
  );
  const { scenarioModellingTabTitle } = useAppSelector((state) => state.scenarioModelling);
  const tabitems = [
    { key: ALL_REGIONS, label: ALL_REGIONS },
    { key: 'AMERICAS', label: 'Americas' },
    { key: 'ASIA', label: 'Asia' },
    { key: 'AUSTRALIA', label: 'Australia' },
    { key: 'EUROPE', label: 'Europe' },
    // { key: 'GLOBAL', label: 'Global' }, // uncomment when it needs to be added.
  ];

  const activeRegion = formatRegionName(getRegionVal(userRegionFlags, loggedInUserRegion));
  return (
    <Grid flow='row'>
      <SubTitle>{scenarioModellingTabTitle}</SubTitle>
      <Grid gap='0' style={{ gridAutoRows: 'auto' }}>
        {activeRegion ? (
          <Tabs
            data-testid='tab-change'
            items={[{ key: activeRegion, label: activeRegion }]}
            activeKey={activeRegion}
            defaultActiveKey={activeRegion}
            onChange={handleTabChange}
          />
        ) : (
          <Tabs
            data-testid='tab-change'
            items={tabitems}
            activeKey={selectedRegion}
            defaultActiveKey={selectedRegion}
            onChange={handleTabChange}
          />
        )}
        <Divider size='medium' direction='horizontal' color='#F5F5F5' />
      </Grid>
      <Flexbox style={{ paddingBottom: '10px' }}>
        {tabKey !== BUSINESS_PLAN_KEY ? (
          <Select
            size='medium'
            allowClear={false}
            value={selectedSubRegion}
            style={{ width: '150px', zIndex: 10 }}
            onChange={(value) => setSelectedSubRegion(value)}
          >
            {subRegionOptions?.map((option) => (
              <Option key={option.id} value={option.value}>
                {option.value}
              </Option>
            ))}
          </Select>
        ) : null}

        <Button
          variant='outlined'
          size='medium'
          icon={<ArrowCw />}
          style={{ marginLeft: '10px' }}
          onClick={() => handleRevertAllToOriginal()}
        >
          Reset
        </Button>
      </Flexbox>
    </Grid>
  );
};
export default RegionTabs;
