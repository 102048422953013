import LineChart from '../charts/LineChart';
import styled from 'styled-components';
import Footer from '../Footer/Footer';
import { Divider, Flexbox, Grid } from '@sede-x/shell-ds-react-framework';
import { BackToDashboard } from '../BackToDashboard/BackToDashboard';
import Loader from '../Loader/Loader';
import styles from './MarginToCarbon.module.css';
import CountryViewChart from '../charts/CountryViewChart';

const MCarbon = styled.div`
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 4px;
  padding-top: 10px;
  flex: 1;
  width: 100%;
`;
const MarginToCarbonRatio = () => {
  return (
    <MCarbon>
      <Grid>
        <div className={styles.mainGrid} data-testid='margin-to-carbon'>
          <Flexbox>
            <BackToDashboard />
          </Flexbox>
          <Divider size='small' direction='horizontal' color='#F5F5F5' />
          <Flexbox className={styles.chartFlex}>
            <div className={styles.chartContainer}>
              <Loader />
              <Grid columns={2} gap='30px'>
                <Grid.Cell>
                  <LineChart />
                </Grid.Cell>
                <Grid.Cell>
                  <CountryViewChart />
                </Grid.Cell>
              </Grid>
            </div>
          </Flexbox>
        </div>
      </Grid>
      <Footer />
    </MCarbon>
  );
};

export default MarginToCarbonRatio;
