import { Modal } from '@sede-x/shell-ds-react-framework';
import React from 'react';
import { useAppSelector } from '../../redux/hooks';

interface LoaderProps {
  message?: string;
}
const Loader: React.FC<LoaderProps> = ({ message = 'Loading...' }) => {
  const { spinner } = useAppSelector((state) => state.datavisualization);
  return (
    <div data-testid='loader'>
      <Modal
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...{
          closable: false,
          loading: true,
          bodyPadding: true,
          showFooter: false,
          loadingText: message,
        }}
        open={spinner}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onClose={() => {}}
      ></Modal>
    </div>
  );
};

export default Loader;
