import { CopyToClipboard } from 'react-copy-to-clipboard';
import Copy from '@sede-x/shell-ds-react-framework/build/esm/components/Icon/components/Copy';
import { Flexbox } from '@sede-x/shell-ds-react-framework';
const CopyUserToClipboard = ({ getValue }: { getValue: () => string }, handleCopy: () => void) => {
  return (
    <CopyToClipboard text={getValue()} onCopy={handleCopy}>
      <Flexbox justifyContent='space-between'>
        {getValue()}
        <span className='copy-icon'>
          <Copy />
        </span>
      </Flexbox>
    </CopyToClipboard>
  );
};

export default CopyUserToClipboard;
