import { Modal } from '@sede-x/shell-ds-react-framework';
import React, { useContext } from 'react';
import { AppContext } from '../../../Context/AppContext';

export const SectoralSpinner = () => {
  const { state } = useContext(AppContext).appData;
  return (
    <Modal
      {...{
        closable: false,
        loading: true,
        loadingText: 'Fetching Sectoral Performance Information.',
        bodyPadding: true,
        showFooter: false,
      }}
      open={state.spinner}
      onClose={() => {
        //console statement
      }}
      data-testid='sectoralSpinnerId'
    ></Modal>
  );
};
