import { TooltipFormatterContextObject } from 'highcharts';

export interface MapDataItem {
  YEAR: number;
  QUARTER: string;
  UNIT: string;
  QTY: number;
  PARAMETER_CD: string;
}

export interface IRegionData {
  PARAMETER_CD: string;
  QTY: number;
  QUARTER?: string;
  YEAR?: number;
}

export interface ISeriesData {
  name: string;
  data: number[];
}

export interface IRegionQuarterData extends IRegionData {
  QUARTER: string;
  YEAR: number;
}

interface Category {
  QUARTER?: string;
  YEAR?: number;
}

export const groupAndSortDataByParameter = (
  mapData: MapDataItem[],
): Record<string, IRegionData[]> => {
  return mapData.reduce((acc: Record<string, IRegionData[]>, item: MapDataItem) => {
    const { QUARTER, YEAR, QTY, PARAMETER_CD } = item;
    if (!acc[PARAMETER_CD]) {
      acc[PARAMETER_CD] = [];
    }
    const data: IRegionData = { QTY, PARAMETER_CD };
    data.QUARTER = QUARTER;
    data.YEAR = Number(YEAR);
    acc[PARAMETER_CD].push(data);
    return acc;
  }, {});
};

export function getChartXAxisCategories(dataByRegion: Record<string, IRegionData[]>): string[] {
  return getAllUniqueDatesOrQuarters(dataByRegion as Record<string, IRegionQuarterData[]>).map(
    (item) => `${item.YEAR} ${item.QUARTER}`,
  );
}

function getAllUniqueDatesOrQuarters(dataByRegion: Record<string, IRegionData[]>): Category[] {
  const categories = Array.from(
    new Set(
      Object.values(dataByRegion)
        .flat()
        .map((item) => {
          const { QUARTER, YEAR } = item;
          return {
            QUARTER,
            YEAR,
          };
        }),
    ),
  );
  return categories?.filter((item, index) => {
    return (
      categories.findIndex((i) => i.QUARTER === item.QUARTER && i.YEAR === item.YEAR) === index
    );
  });
}

export const generateSeries = (dataByRegion: Record<string, IRegionData[]>): ISeriesData[] => {
  const categories = getAllUniqueDatesOrQuarters(
    dataByRegion as Record<string, IRegionQuarterData[]>,
  );
  return Object.keys(dataByRegion).map((region) => ({
    name: region,
    data: categories.map((category) => {
      const data = dataByRegion[region].find(
        (item: IRegionData) => item.QUARTER === category.QUARTER && item.YEAR === category.YEAR,
      );
      return data ? data.QTY : 0;
    }),
  }));
};

export const tooltipFormatter = (context: TooltipFormatterContextObject) => {
  const y: string | number = context.y ?? '';
  const volume = Math.round(Number(y) * 100) / 100;
  const parameter: string = context?.series?.name ?? '';
  const x: string | number = context.x ?? '';
  return `<b>Qty:</b> ${volume}<br/>
          <b>Year Quarter:</b> ${x}<br/>
          <b>Parameter Name:</b> ${parameter}`;
};
