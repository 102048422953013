import { Grid } from '@sede-x/shell-ds-react-framework';
import React from 'react';
import '../../style.module.css';
import './Sectoral.css';
import { CptyListExtractCard } from './CptyListExtractCard';
import { GenerateReportCard } from './GenerateReportCard';
import { UploadMappingSheetCard } from './UploadMappingSheetCard';

export const SectoralPerformanceCards = ({
  step03ReportStatus,
  cardLogs,
  setIsServerError,
}: {
  step03ReportStatus: string;
  cardLogs: {
    step01Log: string;
    step02Log: string;
    step03LastRunDate: string;
    step03LastSuccessRunDate: string;
  };
  setIsServerError: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <Grid gap='30px'>
      <Grid.Cell>
        <CptyListExtractCard cardLog={cardLogs.step01Log} setIsServerError={setIsServerError} />
      </Grid.Cell>
      <Grid.Cell>
        <UploadMappingSheetCard cardLog={cardLogs.step02Log} setIsServerError={setIsServerError} />
      </Grid.Cell>
      <Grid.Cell>
        <GenerateReportCard
          step03ReportStatus={step03ReportStatus}
          cardLog={{
            step03LastRunDate: cardLogs.step03LastRunDate,
            step03LastSuccessRunDate: cardLogs.step03LastSuccessRunDate,
          }}
          setIsServerError={setIsServerError}
        />
      </Grid.Cell>
    </Grid>
  );
};
