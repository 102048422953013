import styled from 'styled-components';

export const CardAnalytics = styled.div`
  margin-bottom: 10px;
  width: 49%;
  height: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  background-color: white;
  padding: 52px;
  margin-top: 24px;
  text-align: center;
`;

export const IconContainer = styled.div`
  display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 2px;
  background: var(--Series-8, #008557);
  margin-right: 32px; // Add space to the right of the SVG icon
`;

export const SvgIcon = styled.svg`
  width: 24px;
  height: 24px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Heading = styled.strong`
  margin-bottom: -20px;
  margin-top: 11px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
`;

export const SubHeading = styled.p`
  color: #757575;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  // line-height: 16px;
`;
