/* eslint-disable no-undef */
import axios, { AxiosInstance } from 'axios';
import { AuthService } from '../../commonUtils/authPages/AuthService';

const createAxiosInstance = (project: 'cpm' | 'tanso' | 'calculator') => {
  let baseURL: string;

  switch (project) {
    case 'cpm':
      baseURL = process.env.REACT_APP_API_BASE_URL as string;
      break;
    case 'tanso':
      baseURL = process.env.REACT_APP_API_BASE_URL as string;
      break;
    default:
      throw new Error(`Unknown project: ${project}`);
  }

  return axios.create({
    baseURL,
    timeout: 15000, //15Sec
  });
};

const sessionId = `oidc.user:${process.env.REACT_APP_SSO_AUTHORITY_URL}:${process.env.REACT_APP_SSO_CLIENT_ID}`;
const loggedInUser = sessionStorage.getItem(sessionId);
let loggedInUserDetails = loggedInUser && JSON.parse(loggedInUser);
const authService = new AuthService();

const refreshToken = async (isTokenExpired: boolean) => {
  try {
    return await authService.login(isTokenExpired);
  } catch (error: unknown) {
    // If there is any error while refreshing the token, logging out the user.
    if (error instanceof Error && error.message === 'invalid_grant') {
      // Force a full login if the error is 'invalid_grant'
      return authService.login(false);
    }
    return authService.logout();
  }
};

const setupInterceptors = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const expireTimestamp = loggedInUserDetails?.expires_at;
      const milliseconds = 1000;
      const currentTimestamp = new Date().getTime() / milliseconds;
      // checking token expire and getting new token using silent login.
      const isTokenExpired = expireTimestamp && expireTimestamp <= currentTimestamp;
      if (isTokenExpired) {
        loggedInUserDetails = await refreshToken(isTokenExpired);
      }
      const accessToken = loggedInUserDetails?.access_token;
      if (accessToken && config.headers) {
        (config.headers as { [key: string]: string })['Authorization'] = `Bearer ${accessToken}`;
      }
      return config;
    },
    async () => {
      // If there is any error while refresh token, logging out the user.
      await authService.logout();
    },
  );
};

// Create axios instances for both projects
const axiosInstanceCPM = createAxiosInstance('cpm');
const axiosInstanceTanso = createAxiosInstance('tanso');

// Setup interceptors for both instances
setupInterceptors(axiosInstanceCPM);
setupInterceptors(axiosInstanceTanso);

export { axiosInstanceCPM, axiosInstanceTanso };
