import { CARBON_DASHBOARD, WELCOME_TITLE } from '../../../constants';
import { useAppSelector } from '../../../redux/hooks';
import { formatRegionName } from '../../../utils';
import { CDashboard, Title } from '../CarbonDashBoard.styles';

const WelcomeDashboard = () => {
  const { loggedInUserRegion, userEvp } = useAppSelector((state) => state.dashboard);

  const region =
    loggedInUserRegion === 'Europe' ? 'Europe & Africa' : formatRegionName(loggedInUserRegion);

  let title = WELCOME_TITLE;

  if (region) {
    title += ` ${region}`;
  }

  title += ` ${CARBON_DASHBOARD}`;

  if (userEvp) {
    title += `for ${
      userEvp === 'Shell Energy' ? 'Shell Energy Generation and Marketing' : userEvp
    }`;
  }

  return (
    <CDashboard test-id=''>
      <Title>{title}</Title>
    </CDashboard>
  );
};

export default WelcomeDashboard;
