import { useMemo } from 'react';

import { SeriesLineOptions } from 'highcharts';

const useHasData = (series?: SeriesLineOptions[]): boolean => {
  return useMemo(() => {
    if (!series) {
      return false;
    }

    return series.some(({ data }: SeriesLineOptions) => {
      if (data && data.length > 0) {
        return true;
      }
      return false;
    });
  }, [series]);
};

export default useHasData;
