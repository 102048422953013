import styled from 'styled-components';

export const StyledContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-left: 5px;
  width: 100%;
`;

export const ToggleButtonDiv = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: white;
  padding: 10px;
  display: flex;
  align-items: center;
  z-index: 10;
`;

export const ToggleText = styled.div`
  color: rgb(64, 64, 64);
  margin-left: unset;
  margin-right: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  font-style: normal;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const ToggleButton = styled.div`
  margin-right: 10px; /* Add spacing between button and text */
`;

export const StyledDiv = styled.div`
  // flex-grow: 1;
  width: 100%;
  // height: 100%;
  // display: flex;
  // flex-direction: column;
`;

export const ChartContainer = styled.div`
  flex-grow: 1;
`;

export const FooterNotesRenDiv = styled.div`
  padding-left: 16px;
  padding-top: 5px;
  font-style: italic;
  color: #757575;
  font-size: 12px;
  margin-top: -7px;
  background: #ffffff;
`;

export const FooterNotesPlaceDiv = styled.div`
  min-height: 29px;
  padding-left: 16px;
  padding-top: 5px;
  font-style: italic;
  color: #757575;
  font-size: 12px;
  margin-top: -7px;
  background: #ffffff;
`;
