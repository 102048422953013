import React, { ReactNode } from 'react';

const mainStyle = {
  margin: '25px 20px 0px 20px',
};

interface WithMarginProps {
  children: ReactNode;
}

const WithMargin: React.FC<WithMarginProps> = ({ children }) => (
  <div style={mainStyle}>{children}</div>
);

export default WithMargin;
