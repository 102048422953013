const yearsValue = 'years';
const evpVal = 'evpVal';
const seIpuRegionVal = 'seIpuRegionVal';
const ipuVal = 'ipuVal';
const vpVal = 'vpVal';
const bizVal = 'bizVal';
const firstDate = 'firstDate';
const lastDate = 'lastDate';

export const clearFilterFieldsList = [
  evpVal,
  seIpuRegionVal,
  ipuVal,
  vpVal,
  bizVal,
  firstDate,
  lastDate,
  yearsValue,
];

export const clearSauChangeList = [
  evpVal,
  seIpuRegionVal,
  ipuVal,
  vpVal,
  bizVal,
  firstDate,
  lastDate,
  yearsValue,
];

export const clearEVPChangeList = [
  seIpuRegionVal,
  ipuVal,
  vpVal,
  bizVal,
  firstDate,
  lastDate,
  yearsValue,
];
export const clearSeIpuRegionChangeList = [ipuVal, vpVal, bizVal, firstDate, lastDate, yearsValue];
export const clearIPUChangeList = [vpVal, bizVal, firstDate, lastDate, yearsValue];
export const clearVPChangeList = [bizVal, firstDate, lastDate, yearsValue];
export const clearBusinessChangeList = [firstDate, lastDate, yearsValue];
export const SAU_DEFAULT_OPTION = 'Renewables & Energy Solutions';
