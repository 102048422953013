import React, { useEffect, useState } from 'react';
import {
  TCEContainer,
  MTitle,
  MSubTitle,
  OCSDesc,
  CommoditySelect,
} from '../CarbonDashBoard.styles';
import CustomTable from '../../CustomTable';
import { ITableColumns, ITableRowData } from '../../../Types/types';
import { FormField, Grid, Select } from '@sede-x/shell-ds-react-framework';
import { useAppSelector } from '../../../redux/hooks';
import { getManualLeDataForTable } from '../../../utils';

interface TableCardProps {
  title: string;
  subTitle: string;
  columns: ITableColumns[];
  rowsData: ITableRowData[];
}
const TableCard: React.FC<TableCardProps> = ({ title, subTitle, columns, rowsData }) => {
  const [commodityOptions, setCommodityOptions] = useState<string[]>([]);
  const [selectedCommodity, setSelectedCommodity] = useState<string>('');
  const { loggedInUserRegion, userEvp } = useAppSelector((state) => state.dashboard);
  useEffect(() => {
    const commodity = [...new Set(rowsData.map((row) => row.commodity))];
    setCommodityOptions(commodity as string[]);
    setSelectedCommodity(commodity[0] as string);
  }, [rowsData]);
  const tableData = rowsData.filter((item) => item.commodity === selectedCommodity);
  const updatedTabledata = getManualLeDataForTable(
    tableData,
    selectedCommodity,
    loggedInUserRegion,
    userEvp,
  );
  return (
    <TCEContainer data-testid='table'>
      <Grid columns={'75% auto'} rowGap='55px'>
        <Grid.Cell>
          <MTitle>
            {selectedCommodity} {title}
          </MTitle>
          <MSubTitle>{subTitle}</MSubTitle>
        </Grid.Cell>
        <Grid.Cell>
          <CommoditySelect>
            <FormField label='Select the Commodity'>
              <Select
                allowClear={false}
                size='medium'
                value={selectedCommodity}
                onChange={(commodityName) => setSelectedCommodity(commodityName)}
              >
                {commodityOptions.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Select>
            </FormField>
          </CommoditySelect>
        </Grid.Cell>
      </Grid>
      <OCSDesc>
        <CustomTable columns={columns} rowsData={updatedTabledata} />
      </OCSDesc>
    </TCEContainer>
  );
};
export default TableCard;
