import React, { useEffect, useState, useContext } from 'react';
import { TooltipFormatterContextObject, AxisLabelsFormatterContextObject } from 'highcharts';
import { ChartContainer, TableHeading, NcfToggle } from './PowerNcfVolumeChart.style';
import { SortingState, getSortedRowModel } from '@tanstack/react-table';
import {
  groupAndSortDataByRegion,
  generateSeries,
  getAllUniqueDatesOrQuarters,
  IRegionQuarterData,
  tooltipFormatter,
  getCommodityName,
  getTitleText,
  fetchDataBasedOnView,
  generateColumns,
  axisLabelFormatter,
} from './NcfVolumeUtils';
import Chart from '../../../Chart/Chart';
import { BaseTable, Flexbox } from '@sede-x/shell-ds-react-framework';
import { AppContext } from '../../../../../../Context/AppContext';
import { INcfQuarterwise } from '../../../../../../Types/types';
import ChartToggle from '../../../../../ReUsableComponents/PPAToggle/PPAToggle';
import DownloadCsvButton from '../../../../../ReUsableComponents/DownloadCsvButton/DownloadCsvButton';
import { FETCH_ERROR } from '../../../../../../../CarbonDashboard/utils/constants';
import { notify } from '../../../../../../../CarbonDashboard/components/Toast/notify';

const PowerNcfVolumeChart: React.FC = () => {
  const [view, setView] = useState('Chart');
  const [sorting, setSorting] = useState<SortingState>([]);

  const { state: analyticsState, dispatch: analyticsDispatch } =
    useContext(AppContext).analyticsHome;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchDataBasedOnView(view, analyticsState);
        if (response) {
          analyticsDispatch({
            type: 'setNcfQuarterwise',
            payload: response.data,
          });
        }
      } catch (error) {
        analyticsDispatch({
          type: 'setNcfQuarterwise',
          payload: [],
        });
        notify('error', FETCH_ERROR);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view, analyticsDispatch, analyticsState.filteredValues]);

  // Group and sort data by region
  const mapData: INcfQuarterwise[] = analyticsState.ncfQuarterwise;
  const dataByRegion = groupAndSortDataByRegion(mapData);
  const series = generateSeries(dataByRegion);
  let yAxisTitle = `Quantity(${analyticsState?.filteredValues?.uom})`;
  //if single year selected then show cumulative sum
  if (analyticsState?.filteredValues?.year.length === 1) {
    yAxisTitle = `Cumulative Sum(${analyticsState?.filteredValues?.uom})`;
  }

  const options = {
    xAxis: {
      categories: getAllUniqueDatesOrQuarters(dataByRegion as Record<string, IRegionQuarterData[]>),

      title: {
        text: getTitleText(mapData),
      },
      labels: {
        // eslint-disable-next-line no-unused-vars
        formatter(this: AxisLabelsFormatterContextObject) {
          return axisLabelFormatter(this);
        },
      },
    },
    yAxis: {
      title: {
        text: yAxisTitle,
      },
    },
    tooltip: {
      shared: false,
      // eslint-disable-next-line no-unused-vars
      formatter(this: TooltipFormatterContextObject) {
        return tooltipFormatter(this, mapData);
      },
    },
    plotOptions: {
      series: {
        pointWidth: 20,
        pointPadding: 0.2, // Padding between each column or bar, defaults to 0.1
        groupPadding: 0.2, // Padding between each value groups, in x axis units, defaults to 0.2
        dataLabels: {
          enabled: true,
          format: '{point.y:.2f}', // upto 2 decimal points
        },
      },
    },
    series,
  };

  const tableOptions = {
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  };

  return (
    <ChartContainer>
      <Flexbox style={{ width: '100%' }} justifyContent='space-between'>
        <TableHeading>
          {getCommodityName(analyticsState?.filteredValues?.commodity[0])} NCF Volume
        </TableHeading>
        <Flexbox justifyContent='flex-end' alignItems='center' style={{ flex: 1 }}>
          <NcfToggle>
            <ChartToggle setView={setView} />
          </NcfToggle>
          {view === 'Table' && <DownloadCsvButton data={mapData} fileName={'NcfVolume'} />}
        </Flexbox>
      </Flexbox>
      {view === 'Table' ? (
        <BaseTable
          columns={generateColumns(
            analyticsState.filteredValues.region,
            analyticsState.filterOptions.region,
          )}
          data={mapData}
          size='large'
          data-testid='base-table'
          stickyHeader
          maxHeight={383}
          width={'100%'}
          tableOptions={tableOptions}
        />
      ) : (
        <Chart options={options} footer={'Source'} />
      )}
    </ChartContainer>
  );
};

export default PowerNcfVolumeChart;
