import { useContext, useState } from 'react';
import {
  FrameParent,
  TimeStampCard,
  TimeStampCardInner,
  Header,
  TitleGroup,
  Text4,
  Timetime,
  Title,
  Timetime1,
  Footer,
  FrameContainer,
  Tag20,
  Label,
  Text7,
  Tag201,
  Placeholder2,
  EtrmText,
  ETRMSvg,
} from './DataReconciliationSideCard.style';
import { AppContext } from '../../../../../../Context/AppContext';
import ReconIngetionFailureModal from '../ReconIngetionFailureModal/ReconIngetionFailureModal';
import DataReconETRMFileUploadModal from '../DataReconETRMFileUpload/DataReconETRMFileUploadModal';

const DataReconciliationSideCard = () => {
  const { state: reconState } = useContext(AppContext).reconReports;
  const { state: appState } = useContext(AppContext).appData;
  const { ETRM_DATE, TANSO_DATE, LOG } = reconState.reconReportLastRefreshedDate;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isModalETRMOpen, setIsModalETRMOpen] = useState<boolean>(false);

  const handleOpenModal = (description: string | null, heading: string | null) => {
    if (description && heading) {
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleETRMOpenModal = () => {
    setIsModalETRMOpen(true);
  };

  const closeETRMModal = () => {
    setIsModalETRMOpen(false);
  };

  const ETRMIcon = (
    <ETRMSvg onClick={handleETRMOpenModal}>
      <svg
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
        fill='#404040'
        width='1em'
        height='1em'
        className='sc-fqkvVR haXUbL shell-icon sc-nfKuY'
      >
        <path d='M4.97 9.344a.5.5 0 0 1-.334.456l-.967.341A2.502 2.502 0 0 0 4.5 15H9v2H4.5a4.5 4.5 0 0 1-1.656-8.685.265.265 0 0 0 .167-.232 7.5 7.5 0 0 1 14.07-3.182c.052.093.154.146.26.135A6 6 0 1 1 18 17h-3.001v-2h3a4 4 0 1 0-.56-7.961l-1.05.146a.5.5 0 0 1-.513-.266l-.488-.942A5.5 5.5 0 0 0 5.003 8.319L4.97 9.344Zm7.384-2.198a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708l.708.707a.5.5 0 0 0 .707 0L11 10.62v10.086a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V10.621l1.94 1.94a.5.5 0 0 0 .706 0l.708-.707a.5.5 0 0 0 0-.708l-4-4Z'></path>
      </svg>
    </ETRMSvg>
  );

  const renderLogStatus = () => {
    if (LOG?.RUN_STATUS === 'Failed') {
      return (
        <Tag20>
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M13.2894 2.73608C12.7127 1.75463 11.2873 1.75464 10.7105 2.73608L0.704992 19.7632C0.109547 20.7765 0.867002 22 1.99444 22H22.0055C23.133 22 23.8904 20.7765 23.295 19.7632L13.2894 2.73608ZM2.8856 20L12 4.48942L21.1144 20H2.8856ZM11.5 18C11.2238 18 11 17.7761 11 17.5V16.5C11 16.2239 11.2238 16 11.5 16L12.5 16C12.7761 16 13 16.2239 13 16.5V17.5C13 17.7761 12.7761 18 12.5 18H11.5ZM12.5 9C12.7761 9 13 9.22386 13 9.5L13 13.5C13 13.7761 12.7761 14 12.5 14H11.5C11.2238 14 11 13.7761 11 13.5L11 9.5C11 9.22386 11.2238 9 11.5 9H12.5Z'
              fill='#DD1D21'
            />
          </svg>
          <Label>
            <Text7
              data-testid='tanso-date-error'
              onClick={() => handleOpenModal(LOG?.ERROR_DESCRIPTION, LOG?.ERROR_HEADING)}
            >
              Tanso AM: {TANSO_DATE}
            </Text7>
          </Label>
        </Tag20>
      );
    } else {
      return (
        <Tag201>
          <Text7 data-testid='tanso-date'>Tanso AM: {TANSO_DATE}</Text7>
        </Tag201>
      );
    }
  };

  const isDataAvailable = TANSO_DATE && ETRM_DATE;
  const isUserITSupport = appState.userType.isUserITSupport;

  return (
    <FrameParent style={{ marginTop: '46px', marginRight: '20px' }}>
      <TimeStampCard>
        <TimeStampCardInner>
          <Header>
            <TitleGroup>
              <Text4>
                <Timetime />
                <Title>Trade Capture Date</Title>
              </Text4>
            </TitleGroup>
            <Timetime1 />
          </Header>
          <Footer>
            <FrameContainer>
              {isDataAvailable ? (
                <>
                  {renderLogStatus()}

                  <EtrmText>
                    <Tag201>
                      <Text7 data-testid='etrm-date'>ETRM AM: {ETRM_DATE}</Text7>
                    </Tag201>
                    {isUserITSupport && ETRMIcon}
                  </EtrmText>
                </>
              ) : (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Text7>Data not available</Text7>
                  {isUserITSupport && ETRMIcon}
                </div>
              )}
            </FrameContainer>
            <Placeholder2 />
          </Footer>
        </TimeStampCardInner>
      </TimeStampCard>
      <ReconIngetionFailureModal open={isModalOpen} closeModal={closeModal} log={LOG} />
      <DataReconETRMFileUploadModal open={isModalETRMOpen} closeModal={closeETRMModal} />
    </FrameParent>
  );
};

export default DataReconciliationSideCard;
