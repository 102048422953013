/* eslint-disable no-unused-vars */
import React from 'react';
import { Divider, Tabs } from '@sede-x/shell-ds-react-framework';
import { SubRegion } from '..';
import { ImportedDeals, IProduct } from '../../../Types/types';
import BusinessPlan from '../BusinessPlan/BusinessPlan';
import RegionTabs from '../RegionTabs/RegionTabs';
import DealFunnel from '../DealFunnel/DealFunnel';
import { setAddedImportedDealsId, setTabKey } from '../../../redux/dashboard/dashboardSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  BUSINESS_PLAN,
  BUSINESS_PLAN_KEY,
  BUSINESS_PLAN_TITLE,
  CARBON_LEAVERS,
  CARBON_LEAVERS_KEY,
  DEAL_FUNNEL,
  DEAL_FUNNEL_KEY,
  DEAL_FUNNEL_TITLE,
  one,
  two,
} from '../../../constants';
import { getBusinessPlanOptions, getDealFunnelOptions } from '../utils';
import { setScenarioModellingTabTitle } from '../../../redux/scenarioModelling/scenarioModellingSlice';
import { ALL_REGIONS } from '../../../api/constants';
export interface ScenarioModellingTabsProps {
  regionData: IProduct[];
  selectedRegion: string;
  handleTabChange: (key: string) => void;
  subRegionOptions: SubRegion[];
  selectedSubRegion: string;
  setSubRegionOptions: (value: SubRegion[]) => void;
  setSelectedSubRegion: (value: string) => void;
  setSelectedRegion: (value: string) => void;
  handleRevertAllToOriginal: () => void;
  selectedRegionData: IProduct;
  handleValueChangeWrapper: (
    rowId: string,
    category: string,
    product: string,
    year: number,
    newValue: number,
  ) => void;
  handleRevertToOriginalWrapper: (
    rowId: string,
    category: string,
    product: string,
    year: number,
  ) => void;
  importedDealsData: ImportedDeals[];
}
const ScenarioModellingTabs: React.FC<ScenarioModellingTabsProps> = ({
  regionData,
  selectedRegion,
  handleTabChange,
  subRegionOptions,
  selectedSubRegion,
  setSubRegionOptions,
  setSelectedSubRegion,
  setSelectedRegion,
  handleRevertAllToOriginal,
  selectedRegionData,
  handleValueChangeWrapper,
  handleRevertToOriginalWrapper,
  importedDealsData,
}) => {
  const dispatch = useAppDispatch();
  const { userRegionFlags, loggedInUserRegion, tabKey } = useAppSelector(
    (state) => state.dashboard,
  );
  const activeRegion = loggedInUserRegion || ALL_REGIONS;
  const setRegionAndSubRegion = (subRegions: { id: number; value: string }[]) => {
    const defaultSubRegion = subRegions[0]?.value;
    setSubRegionOptions(subRegions);
    setSelectedSubRegion(defaultSubRegion);
    setSelectedRegion(activeRegion);
  };
  const onTabChange = (key: string) => {
    let subRegions: { id: number; value: string }[] = [];
    let tabTitle = '';
    if (key === DEAL_FUNNEL_KEY) {
      subRegions = getDealFunnelOptions(importedDealsData, activeRegion);
      setRegionAndSubRegion(subRegions);
      tabTitle = DEAL_FUNNEL_TITLE;
    } else if (key === BUSINESS_PLAN_KEY) {
      subRegions = getBusinessPlanOptions(regionData, activeRegion, userRegionFlags);
      setRegionAndSubRegion(subRegions);
      tabTitle = BUSINESS_PLAN_TITLE;
    }
    if (key !== DEAL_FUNNEL_KEY) {
      dispatch(setAddedImportedDealsId([]));
    }
    dispatch(setScenarioModellingTabTitle(tabTitle));
    dispatch(setTabKey(key));
  };
  const regionTabs = (
    <>
      <Divider size='medium' direction='horizontal' color='#F5F5F5' />
      <RegionTabs
        selectedRegion={selectedRegion}
        handleTabChange={handleTabChange}
        subRegionOptions={subRegionOptions}
        selectedSubRegion={selectedSubRegion}
        setSelectedSubRegion={setSelectedSubRegion}
        handleRevertAllToOriginal={handleRevertAllToOriginal}
      />
    </>
  );
  const items = [
    {
      label: BUSINESS_PLAN,
      key: BUSINESS_PLAN_KEY,
      children: (
        <>
          {regionTabs}
          {tabKey === BUSINESS_PLAN_KEY && (
            <BusinessPlan
              selectedRegion={selectedRegion}
              selectedRegionData={selectedRegionData}
              handleValueChangeWrapper={handleValueChangeWrapper}
              handleRevertToOriginalWrapper={handleRevertToOriginalWrapper}
            />
          )}
        </>
      ),
    },
    {
      label: DEAL_FUNNEL,
      key: DEAL_FUNNEL_KEY,
      children: (
        <>
          {regionTabs}{' '}
          <DealFunnel selectedSubRegion={selectedSubRegion} selectedRegion={selectedRegion} />
        </>
      ),
    },
    {
      label: CARBON_LEAVERS,
      key: CARBON_LEAVERS_KEY,
      children: <>{regionTabs}</>,
    },
  ];
  // Removing Carbon Levers tab till it is implemented.
  items.splice(two, one);
  return <Tabs items={items} onChange={onTabChange} defaultActiveKey={BUSINESS_PLAN_KEY} />;
};

export default ScenarioModellingTabs;
