import styled from 'styled-components';
export const ShowImportedDealsText = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0em;
  font-style: normal;
  color: rgb(52, 52, 52);
`;
export const ToggleContainer = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
`;
export const ToggleText = styled.div`
  color: #757575;
  font-size: 14px;
  font-family: 'Shell Font';
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  word-wrap: break-word;
`;
