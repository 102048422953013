import styled from 'styled-components';
interface PaddingTopProps {
  paddingTop: number;
}
export const CommonBodyContainer = styled.div`
  background: rgb(245, 245, 245);
  padding: 5px 20px 10px 20px;
`;
export const LeftDividerVertical = styled.div`
  border-left: 1px solid rgba(0, 0, 0, 0.06);
`;
export const PaddingTop = styled.div<PaddingTopProps>`
  padding-top: ${(props) => `${props.paddingTop}px`};
`;
export const AllRegionChartContainer = styled.div`
  margin-top: 10px;
  width: 100%;
`;
