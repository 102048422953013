import { Grid } from '@sede-x/shell-ds-react-framework';
import { FacePoor } from '@sede-x/shell-ds-react-framework/build/esm/components/Icon/components';
import React from 'react';

export const PPAInsightNoRecords = () => {
  return (
    <Grid justifyItems='center' style={{ paddingTop: '100px' }}>
      <Grid.Cell>
        <FacePoor height={30} width={30} />
      </Grid.Cell>
      <Grid.Cell>
        <h2>No Records Found</h2>
      </Grid.Cell>
    </Grid>
  );
};
