import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../../../Context/AppContext';
import { BiaTable } from './BiaTable';
import { fetchBiaInactiveCptyData } from './BiaUtils';
import { BiaTableContainer } from './BiaReports.style';

export const InactiveCptyView = () => {
  const { state, dispatch } = useContext(AppContext).biaReports;
  useEffect(() => {
    fetchBiaInactiveCptyData(state, dispatch);
  }, [dispatch, state.biaFilterData]);
  const description = 'List of Inactive Counterparties with active volumes';
  return (
    <BiaTableContainer>
      <BiaTable
        description={description}
        isLoading={state.isInactiveCptyDataLoading}
        loadingText='Inactive Counterparties'
        biaData={state.biaInactiveCptyData}
      />
    </BiaTableContainer>
  );
};
