import { IEditFeatureData } from '../../Types/types';

export const EditFeatureInitialState: IEditFeatureData = {
  userMail: '',
  allFieldsValidated: true,
  tableData: [],
  masterTableData: [],
  auditTableData: [],
  spinner: false,
  currentPage: '',
  tableSelected: '',
  filteredOptions: {
    pageNumber: 0,
    pageSize: 0,
    region: '',
  },
  marketOptions: [],
  ncfCategoryOptions: [],
  buySellOptions: [],
  filteredRegion: '',
  role: [''],
  isRoleNcf: false,
  userRegion: '',
  username: '',
  mail: '',
  recordCount: 0,
  auditPaginationData: [],
  viewEditPaginationData: [],
  masterPaginationData: [],
  currentPaginationNum: 1,
  hasTableChangesPublished: false,
  dirtyData: [],
  erroredRows: [],
  publishRows: [],
  masterErroredRows: [],
  editableCol: [],
  defaultPageSize: 5,
  isGlobalUser: false,
  showConfigRulePage: false,
  canUserManipulateData: false,
  canUserAddAndSubmitSnapshot: false,
  canUserChangeSnapshotStatus: false,
  showAllRegions: false,
  showSnapshotActions: false,
  isAustraliaUser: false,
  isPpaUser: false,
  isLoggedLogin: false,
  showSectoralpage: false,
  isAnyInvalidComment: false,
  isAnyInvalidCountryName: false,
  isAnyInvalidQuantity: false,
  showReport: false,
  showAuditReport: false,
  isGetData: false,
  userRegionFlags: {
    isAsiaUser: false,
    isAmericasUser: false,
    isAustraliaUser: false,
    isEuropeUser: false,
  },
  isUserWithActions: false,
  userType: {
    isUserITSupport: false,
    isUserITSupportInNonProd: false,
    isUserForReporting: false,
    isUserNcfLead: false,
  },
  isNcfReportingRegionFieldDisabled: false,
  isUserForReporting: false,
  isUserSeVpGm: false,
};

export type IEditFeatureDataState = typeof EditFeatureInitialState;
