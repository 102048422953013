/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-prototype-builtins */
import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import { Select, TextArea, TextInput } from '@sede-x/shell-ds-react-framework';
import tableEditOptions from '../../../api/TableEditOptions';
import tableStyles from '../../style.module.css';
import '../tableHeadingStyle.css';
import {
  COUNTRY_CODE,
  COUNTRY_NAME,
  IN_SCOPE,
  NUCLEUS_SEAM_REGION_SCOPE_TABLE,
  OUT_OF_SCOPE,
  ENDUR_SEAM_REGION_SCOPE_TABLE,
  ENDURSLMT_STATIC_MAP_NCF_TABLE,
  ENDURSLMT_I_POWER_TABLE,
  NOT_UPDATED,
  THREE,
  TWO,
} from '../../../api/constants';
import { areStringsEqual, decorateColName } from '../../Utils/utils';
import { isQtyValid } from '../../pages/ViewEditReportPage/ViewEditReportFunctions';
import {
  Action,
  IAuditTableData,
  IColumnRender,
  ICountry,
  IEditDirtyData,
  OptionI,
} from '../../../Types/types';
import { IEditFeatureDataState } from '../../../Reducers/InitialStates/EditFeatureInitialState';
import { ColumnsType } from '@sede-x/shell-ds-react-framework/build/esm/components/DeprecatedTable/Table.types';
import {
  ColumnIdentifiers,
  ConfigOptions,
  IEditTableData,
  InewRecordOptions,
  OptionLists,
  X,
} from '../../../Types/types2';

export const editAndAuditReportTableCols = (
  cols: string[],
  state: IEditFeatureDataState,
  dispatch: Dispatch<Action>,
  ...otherArgs: X | []
) => {
  const [
    currColumns,
    isEditable,
    isReadyToPublish,
    isEditError,
    setIsDirtyData,
    errorTableData,
    setIsQtyExceedThreeWholeDigits,
    setUpdatedCol,
    setOriginalVal,
    setUpdatedVal,
    regionOptionsForAmericasSeamRegionTable,
    ncfCatOptionsForAsiaJapanTable,
  ] = otherArgs as X;

  let colObj: IColumnRender[] = [];

  const scopeOptions = tableEditOptions.scopeOptions;
  const countryOptionsAmericas: OptionI[] = [];
  let countryNameAndCodeAmericas: ICountry[] = [{ COUNTRY_NAME: 'SELECT', COUNTRY_CODE: 'SELECT' }];
  if (regionOptionsForAmericasSeamRegionTable) {
    countryNameAndCodeAmericas = [...regionOptionsForAmericasSeamRegionTable];
    for (const nameCode of countryNameAndCodeAmericas) {
      countryOptionsAmericas.push({
        value: nameCode.COUNTRY_NAME,
        label: nameCode.COUNTRY_NAME,
      });
    }
  }

  const colNames = currColumns?.slice(0) || [];

  const isScopeTableSelected =
    state.tableSelected === ENDUR_SEAM_REGION_SCOPE_TABLE ||
    state.tableSelected === NUCLEUS_SEAM_REGION_SCOPE_TABLE;

  const isAsiaJapanTableSelected =
    state.tableSelected === ENDURSLMT_STATIC_MAP_NCF_TABLE ||
    state.tableSelected === ENDURSLMT_I_POWER_TABLE;

  const colData = { cols, colObj, colNames };
  const booleanOptions = {
    isEditable,
    isReadyToPublish,
    isEditError,
    isAsiaJapanTableSelected,
    isScopeTableSelected,
  };
  const setFuncs = {
    setUpdatedCol,
    setOriginalVal,
    setUpdatedVal,
    setIsQtyExceedThreeWholeDigits,
    setIsDirtyData,
  };
  const optionsData = {
    countryNameAndCodeAmericas,
    scopeOptions,
    ncfCatOptionsForAsiaJapanTable,
    countryOptionsAmericas,
  };
  const actions = { state, dispatch };
  colObj = updateCols(colData, booleanOptions, setFuncs, optionsData, actions, errorTableData);
  //  create/re-create columns

  return colObj as unknown as ColumnsType<IAuditTableData | IEditTableData>;
};

export const setDirtyDataObj = (
  stateVars: {
    setIsQtyExceedThreeWholeDigits?: Dispatch<SetStateAction<boolean>>;
    dispatch?: Dispatch<Action>;
    masterData: IEditTableData[];
    state?: IEditFeatureDataState;
  },
  ID1: string,
  COMMENTS1: string,
  value: IEditTableData,
  columnName: string,
) => {
  let result: IEditDirtyData = {};
  for (const masterDataObj of stateVars.masterData) {
    const comment = masterDataObj[COMMENTS1 as keyof typeof masterDataObj] ?? '';
    const valueComment = value[COMMENTS1 as keyof typeof value] ?? '';
    if (masterDataObj[ID1 as keyof typeof masterDataObj] === value[ID1 as keyof typeof value]) {
      result = {
        [ID1]: value[ID1 as keyof typeof value],
        [columnName]: value[columnName as keyof typeof value],
      };
      if (!isCommentsError(comment.toString(), valueComment.toString())) {
        result[COMMENTS1 as keyof typeof result] = valueComment;
      }
    }
  }
  return result;
};

export const setDirtyDataAndPublishData = (options: InewRecordOptions) => {
  const {
    newDirtyData,
    ID1,
    SCOPE1,
    COMMENTS1,
    QTY1,
    value,
    stateVars,
    columnName,
    isScopeTableSelected,
    publishData,
    countryNameAndCodeAmericas,
  } = options;
  const data = {
    columnName,
    COMMENTS1,
    isScopeTableSelected,
    options,
    value,
    countryNameAndCodeAmericas,
    SCOPE1,
    newDirtyData,
    publishData,
  };
  if (newDirtyData.length > 0) {
    let currIndex = 0;

    // iterate the array
    for (const obj of newDirtyData) {
      currIndex = newDirtyData.indexOf(obj);
      const nonDirtyRow = false;
      // check if ID (I_Aligne_Id) is already present
      if (obj[ID1] === value[ID1]) {
        const param = {
          stateVars,
          ID1,
          obj,
          columnName,
          value,
          isScopeTableSelected,
          SCOPE1,
          QTY1,
          publishData,
          currIndex,
          countryNameAndCodeAmericas,
          nonDirtyRow,
          COMMENTS1,
          newDirtyData,
        };
        const [updatedDirtyData, updatedPublish] = updateExistingRecord(param);
        return [updatedDirtyData, updatedPublish];
      }
    }
    //  for new ID (I_Aligne_Id)
    const [updatedNewDirtyData, updatedPublishData] = addNewRecord(data);
    return [updatedNewDirtyData, updatedPublishData];
  } else {
    const [updatedNewDirtyData, updatedPublishData] = addFirstRecord(data);
    return [updatedNewDirtyData, updatedPublishData];
  }
};

export const updateExistingRecord = (data: {
  stateVars: {
    setIsQtyExceedThreeWholeDigits: Dispatch<SetStateAction<boolean>>;
    dispatch: Dispatch<Action>;
    masterData: IEditTableData[];
    state: IEditFeatureDataState;
  };
  ID1: string;
  obj: IEditDirtyData;
  columnName: string;
  value: IEditTableData;
  isScopeTableSelected: boolean;
  SCOPE1: string;
  QTY1: string;
  publishData: IEditTableData[];
  currIndex: number;
  countryNameAndCodeAmericas: ICountry[];
  nonDirtyRow: boolean;
  COMMENTS1: string;
  newDirtyData: IEditDirtyData[];
}) => {
  const {
    stateVars,
    ID1,
    obj,
    columnName,
    value,
    isScopeTableSelected,
    SCOPE1,
    QTY1,
    publishData,
    currIndex,
    countryNameAndCodeAmericas,
    newDirtyData,
  } = data;
  // compare the updated value with master value
  for (const masterDataObj of stateVars.masterData) {
    if (masterDataObj[ID1 as keyof typeof masterDataObj] === obj[ID1 as keyof typeof obj]) {
      const masterColVal = masterDataObj[columnName as keyof typeof masterDataObj];
      const valueColVal = setCurrCellValue(
        isScopeTableSelected,
        columnName,
        SCOPE1,
        QTY1,
        value[columnName],
      );
      // if updated value is not equal to master value, then update the dirty data
      if (masterColVal !== valueColVal) {
        const newValueData = {
          obj,
          columnName,
          value,
          publishData,
          currIndex,
          isScopeTableSelected,
          SCOPE1,
          countryNameAndCodeAmericas,
        };
        updateDataWithNewValue(newValueData);
      } else {
        const [updatedNewDirtyData, updatedPublishData] = updateRecordWithRevertedChange(data);
        data.newDirtyData = updatedNewDirtyData;
        data.publishData = updatedPublishData as IEditTableData[];
      }
    }
  }
  return [newDirtyData, publishData];
};

export const updateRecordWithRevertedChange = (data: {
  stateVars: {
    setIsQtyExceedThreeWholeDigits: Dispatch<SetStateAction<boolean>>;
    dispatch: Dispatch<Action>;
    masterData: IEditTableData[];
    state: IEditFeatureDataState;
  };
  ID1: string;
  obj: IEditDirtyData;
  columnName: string;
  value: IEditTableData;
  isScopeTableSelected: boolean;
  SCOPE1: string;
  QTY1: string;
  publishData: IEditTableData[];
  currIndex: number;
  countryNameAndCodeAmericas: ICountry[];
  nonDirtyRow: boolean;
  COMMENTS1: string;
  newDirtyData: IEditDirtyData[];
}) => {
  const {
    obj,
    columnName,
    publishData,
    currIndex,
    value,
    isScopeTableSelected,
    SCOPE1,
    ID1,
    COMMENTS1,
    newDirtyData,
  } = data;
  // else if updated value matches with master data, remove the key from dirty data
  delete obj[columnName];
  publishData[currIndex] = value;
  // delete country details for scope table if same as master row
  if (isScopeTableSelected && columnName === SCOPE1) {
    delete obj[COUNTRY_NAME];
    delete obj[COUNTRY_CODE];
  } else if (isScopeTableSelected && columnName === COUNTRY_NAME) {
    delete obj[COUNTRY_CODE];
  }
  // if the object has no updated columns, deleted the entire object from dirty data
  //  OR the dirty object just has ID and COMMENTS columns, delete that entry
  const isFirstIndexAvailableAsId = Object.keys(obj).length === 1 && Object.keys(obj)[0] === ID1;
  const isSecondIndexAvailableAsComments =
    Object.keys(obj).length === TWO &&
    Object.keys(obj)[0] === ID1 &&
    Object.keys(obj)[1] === COMMENTS1;
  data.nonDirtyRow = isFirstIndexAvailableAsId || isSecondIndexAvailableAsComments;
  // during validation error mode, remove the row from dirtyData if all changes are reverted
  if (data.nonDirtyRow) {
    newDirtyData.splice(currIndex, 1);
    publishData.splice(currIndex, 1);
  }
  return [newDirtyData, publishData];
};

export const updateDataWithNewValue = (data: {
  obj: IEditDirtyData;
  columnName: string;
  value: IEditTableData;
  publishData: IEditTableData[];
  currIndex: number;
  isScopeTableSelected: boolean;
  SCOPE1: string;
  countryNameAndCodeAmericas: ICountry[];
}) => {
  const {
    obj,
    columnName,
    value,
    publishData,
    currIndex,
    isScopeTableSelected,
    SCOPE1,
    countryNameAndCodeAmericas,
  } = data;
  // update or add column with new column name
  obj[columnName] = value[columnName];
  publishData[currIndex] = value;
  // updating country details for scope table
  if (isScopeTableSelected && columnName === SCOPE1) {
    obj[COUNTRY_NAME as keyof typeof obj] = value[COUNTRY_NAME];
    obj[COUNTRY_CODE] = getCountryCode(value[COUNTRY_NAME] as string, countryNameAndCodeAmericas);
  } else if (isScopeTableSelected && columnName === COUNTRY_NAME) {
    obj[COUNTRY_CODE] = getCountryCode(value[COUNTRY_NAME] as string, countryNameAndCodeAmericas);
  }
};

export const setCurrCellValue = (
  isScopeTableSelected: boolean,
  columnName: string,
  SCOPE1: string,
  QTY1: string,
  actualValue: string | number,
) => {
  // if scope is changed for an existing record, convert scope value from string to number
  if (isScopeTableSelected && columnName === SCOPE1 && typeof actualValue === 'string') {
    return Number(actualValue.slice(0, 1));
  } else if (columnName === QTY1) {
    return Number(actualValue);
  } else {
    return actualValue;
  }
};

export const addFirstRecord = (data: {
  columnName: string;
  COMMENTS1: string;
  isScopeTableSelected: boolean;
  options: InewRecordOptions;
  value: IEditTableData;
  countryNameAndCodeAmericas: ICountry[];
  SCOPE1: string;
  newDirtyData: IEditDirtyData[];
  publishData: IEditTableData[];
}) => {
  const {
    columnName,
    COMMENTS1,
    isScopeTableSelected,
    options,
    value,
    countryNameAndCodeAmericas,
    SCOPE1,
    newDirtyData,
    publishData,
  } = data;
  if (!areStringsEqual(columnName, COMMENTS1)) {
    // update country code and country name for changes in scope tables
    if (isScopeTableSelected) {
      options.newDirtyDataObj = insertCountryDetailsToDirtyData(
        options.newDirtyDataObj,
        value,
        columnName,
        countryNameAndCodeAmericas,
        SCOPE1,
      );
    }
    //  A First Entry is done when an editable field is changed, not when COMMENTS col is changed first
    newDirtyData.push(options.newDirtyDataObj);
    publishData.push(value);
  }
  return [newDirtyData, publishData];
};

export const addNewRecord = (data: {
  columnName: string;
  COMMENTS1: string;
  isScopeTableSelected?: boolean;
  options: InewRecordOptions;
  value: IEditTableData;
  countryNameAndCodeAmericas: ICountry[];
  SCOPE1: string;
  newDirtyData: IEditDirtyData[];
  publishData: IEditTableData[];
}) => {
  const {
    columnName,
    COMMENTS1,
    isScopeTableSelected,
    newDirtyData,
    publishData,
    value,
    countryNameAndCodeAmericas,
    SCOPE1,
  } = data;
  if (columnName !== COMMENTS1) {
    // update country code and country name for changes in scope tables
    if (isScopeTableSelected) {
      data.options.newDirtyDataObj = insertCountryDetailsToDirtyData(
        data.options.newDirtyDataObj,
        value,
        columnName,
        countryNameAndCodeAmericas,
        SCOPE1,
      );
    }
    //  A new Entry is done when an editable field is changed, not when COMMENTS col is changed first
    newDirtyData?.push(data.options.newDirtyDataObj);
    publishData?.push(value);
  }
  return [newDirtyData, publishData];
};

export const maintainDirtyData = (
  colData: { columnName: string; value: IEditTableData; countryNameAndCodeAmericas: ICountry[] },
  colNames: {
    COMMENTS1: string;
    ID1: string;
    QTY1: string;
    SCOPE1: string;
    DEAL_QUANTITY1: string;
  },
  booleanVars: { isEditError: boolean; isScopeTableSelected: boolean },
  stateVars: {
    setIsQtyExceedThreeWholeDigits: Dispatch<SetStateAction<boolean>>;
    dispatch: Dispatch<Action>;
    masterData: IEditTableData[];
    state: IEditFeatureDataState;
  },
) => {
  const { columnName, value, countryNameAndCodeAmericas } = colData;
  const { COMMENTS1, ID1, QTY1, SCOPE1, DEAL_QUANTITY1 } = colNames;
  const { isEditError, isScopeTableSelected } = booleanVars;
  const { setIsQtyExceedThreeWholeDigits, dispatch, state } = stateVars;
  const newDirtyData = [...state.dirtyData];
  const publishData = [...state.publishRows];
  // in case data is already in master Data but not in dirty data.
  // It happens when a dirty data is reverted to actual with COMMENTS being different
  const newDirtyDataObj = setDirtyDataObj(stateVars, ID1, COMMENTS1, value, columnName);
  if (isEditError) {
    // during validation error, masterErroredRows is the master data
    stateVars.masterData = [...state.masterErroredRows];
  } else {
    stateVars.masterData = [...state.masterPaginationData];
  }
  const options = {
    newDirtyData,
    ID1,
    SCOPE1,
    COMMENTS1,
    QTY1,
    value,
    stateVars,
    columnName,
    isScopeTableSelected,
    publishData,
    countryNameAndCodeAmericas,
    newDirtyDataObj,
  };
  const [updatedNewDirtyData, updatedPublishData] = setDirtyDataAndPublishData(options);

  dispatch({ type: 'setDirtyData', payload: updatedNewDirtyData });
  dispatch({ type: 'setPublishRows', payload: updatedPublishData });
  qtyCheckForThreeWholeDigits(
    columnName,
    QTY1,
    DEAL_QUANTITY1,
    newDirtyData,
    setIsQtyExceedThreeWholeDigits,
  );
};

export const qtyCheckForThreeWholeDigits = (
  columnName: string,
  QTY1: string,
  DEAL_QUANTITY1: string,
  newDirtyData: IEditDirtyData[],
  setIsQtyExceedThreeWholeDigits: Dispatch<SetStateAction<boolean>>,
) => {
  // soft check for whole number having more than 3 characters
  let qtyLength = 0;
  let doesAnyQtyExceedThreeWholeDigits = false;
  if (areStringsEqual(columnName, QTY1) || areStringsEqual(columnName, DEAL_QUANTITY1)) {
    for (let i = 0; i < newDirtyData.length && newDirtyData[i].hasOwnProperty(columnName); i++) {
      qtyLength =
        newDirtyData[i][columnName as keyof IEditDirtyData]?.toString().split('.')[0].length ?? 0;
      if (qtyLength > THREE) {
        doesAnyQtyExceedThreeWholeDigits = true;
        break;
      }
    }
    if (doesAnyQtyExceedThreeWholeDigits) {
      setIsQtyExceedThreeWholeDigits(true);
    } else {
      setIsQtyExceedThreeWholeDigits(false);
    }
  }
};

export const isCommentsError = (masterComment: string, currentComment: string) => {
  if (masterComment === '' && masterComment !== currentComment) {
    // if master comment is already empty
    return false;
  } else if (masterComment !== currentComment && currentComment !== '') {
    return false;
  } else {
    return true;
  }
};

export const maintainErrorData = (
  value: IEditTableData,
  state: IEditFeatureDataState,
  isEditError: boolean,
  isScopeTableSelected: boolean,
  colNames: {
    COMMENTS1: string;
    QTY1: string;
    DEAL_QUANTITY1: string;
    ID1: string;
    SCOPE1: string;
  },
  dispatch: Dispatch<Action>,
) => {
  const { COMMENTS1, QTY1, DEAL_QUANTITY1, ID1, SCOPE1 } = colNames;
  let masterData: IEditTableData[] = [];
  const errorRows = [...state.erroredRows];
  let currentRow = value;
  let isCurrentRowErrored = false;
  let isCurrentRowExistsInErrorData = false;
  let currentRowIndexInErrorData = -1;
  let isCommentsChanged = false;

  const [isCommentsEmpty, isQtyNotvalid, isDealQtyNotvalid] = getCommentsQtyDealQtyValidity(
    currentRow,
    COMMENTS1,
    QTY1,
    DEAL_QUANTITY1,
    value,
  );
  if (isEditError) {
    masterData = [...state.masterErroredRows];
  } else {
    masterData = [...state.masterPaginationData];
  }

  // get the index of master data for current row
  const masterDataRow = masterData.find(
    (masterDataObj) => masterDataObj[ID1] === currentRow[ID1],
  ) as IEditTableData;
  // check if comment is changed
  isCommentsChanged = currentRow[COMMENTS1 as keyof typeof currentRow] !== masterDataRow[COMMENTS1];
  // check if country name is empty when IN_SCOPE
  const isCountryEmptyWhenInScope = checkCountryEmptyWhenInScope(
    isScopeTableSelected,
    currentRow,
    SCOPE1,
  );

  currentRow = makeCurrentRowScopeToNumeric(isScopeTableSelected, currentRow, SCOPE1);
  const isAnyEditableColChangedExceptComments = isColChangedExceptComments(
    state,
    QTY1,
    currentRow,
    COMMENTS1,
    masterDataRow,
  );
  isCurrentRowErrored = checkIfCurrentRowErrored(
    isAnyEditableColChangedExceptComments,
    isCommentsChanged,
    isCommentsEmpty,
    isQtyNotvalid,
    isDealQtyNotvalid,
    isCountryEmptyWhenInScope,
  );
  for (let i = 0; i < errorRows.length; i++) {
    if (errorRows[i][ID1] === currentRow[ID1 as keyof typeof currentRow]) {
      currentRowIndexInErrorData = i;
      isCurrentRowExistsInErrorData = true;
      break;
    }
  }
  // if its an errored row and ID already present in Error data then, replace the old row with current incoming Row
  updateExistingRowData(
    isCurrentRowErrored,
    isCurrentRowExistsInErrorData,
    errorRows,
    currentRowIndexInErrorData,
    currentRow,
    dispatch,
  );

  // if its an errored row and ID is not in Error data, push this as new row
  addNewErroredRowData(
    isCurrentRowErrored,
    isCurrentRowExistsInErrorData,
    errorRows,
    currentRow,
    masterDataRow,
    dispatch,
    state,
  );

  // if its NOT an errored row and ID already present in Error data, remove the row from erroredRow
  removeExistingRowIfNotErrored(
    isCurrentRowErrored,
    isCurrentRowExistsInErrorData,
    errorRows,
    currentRowIndexInErrorData,
    dispatch,
  );
};

export const checkIfCurrentRowErrored = (
  isAnyEditableColChangedExceptComments: boolean,
  isCommentsChanged: boolean,
  isCommentsEmpty: boolean,
  isQtyNotvalid: boolean,
  isDealQtyNotvalid: boolean,
  isCountryEmptyWhenInScope: boolean,
) => {
  const areQtyDealQtyCountryGood = isQtyNotvalid || isDealQtyNotvalid || isCountryEmptyWhenInScope;
  return (
    (isAnyEditableColChangedExceptComments && (!isCommentsChanged || isCommentsEmpty)) ||
    areQtyDealQtyCountryGood
  );
};

export const isColChangedExceptComments = (
  state: IEditFeatureDataState,
  QTY1: string,
  currentRow: IEditTableData,
  COMMENTS1: string,
  masterDataRow: IEditTableData,
) => {
  for (const tableData of state.editableCol) {
    const col = tableData;
    if (col === QTY1) {
      currentRow[col] = Number(currentRow[col]);
    }
    // check if editable columns are changed other than comments
    if (col.toLowerCase() !== COMMENTS1.toLowerCase() && currentRow[col] !== masterDataRow[col]) {
      return true;
    }
  }
  return false;
};

export const makeCurrentRowScopeToNumeric = (
  isScopeTableSelected: boolean,
  currentRow: IEditTableData,
  SCOPE1: string,
) => {
  // convert scope value to number else it will always error out for being different from master scope value, which is a number
  if (isScopeTableSelected && typeof currentRow[SCOPE1] === 'string') {
    currentRow[SCOPE1] = currentRow[SCOPE1] === IN_SCOPE ? 1 : 0;
  }
  return currentRow;
};

export const checkCountryEmptyWhenInScope = (
  isScopeTableSelected: boolean,
  currentRow: IEditTableData,
  SCOPE1: string,
) => {
  if (isScopeTableSelected && (currentRow[SCOPE1] === IN_SCOPE || currentRow[SCOPE1] === 1)) {
    return currentRow[COUNTRY_NAME] === '';
  }
  return false;
};

export const getCommentsQtyDealQtyValidity = (
  currentRow: IEditTableData,
  COMMENTS1: string,
  QTY1: string | number,
  DEAL_QUANTITY1: string,
  value: IEditTableData,
) => {
  return [
    Object.hasOwn(currentRow, COMMENTS1) && value[COMMENTS1] === '',
    Object.hasOwn(currentRow, QTY1) && !isQtyValid(value[QTY1]),
    Object.hasOwn(currentRow, DEAL_QUANTITY1) && !isQtyValid(value[DEAL_QUANTITY1]),
  ];
};

export const updateExistingRowData = (
  isCurrentRowErrored: boolean,
  isCurrentRowExistsInErrorData: boolean,
  errorRows: IEditTableData[],
  currentRowIndexInErrorData: number,
  currentRow: IEditTableData,
  dispatch: Dispatch<Action>,
) => {
  if (isCurrentRowErrored && isCurrentRowExistsInErrorData) {
    errorRows[currentRowIndexInErrorData] = currentRow;
    dispatch({ type: 'setErroredRows', payload: errorRows });
  }
};

export const addNewErroredRowData = (
  isCurrentRowErrored: boolean,
  isCurrentRowExistsInErrorData: boolean,
  errorRows: IEditTableData[],
  currentRow: IEditTableData,
  masterDataRow: IEditTableData,
  dispatch: Dispatch<Action>,
  state: IEditFeatureDataState,
) => {
  if (isCurrentRowErrored && !isCurrentRowExistsInErrorData) {
    errorRows.push(currentRow);
    dispatch({ type: 'setErroredRows', payload: errorRows });
    //  entry of same data to master error data
    state.masterErroredRows.push(masterDataRow);
    dispatch({
      type: 'setMasterErroredRows',
      payload: state.masterErroredRows,
    });
  }
};

export const removeExistingRowIfNotErrored = (
  isCurrentRowErrored: boolean,
  isCurrentRowExistsInErrorData: boolean,
  errorRows: IEditTableData[],
  currentRowIndexInErrorData: number,
  dispatch: Dispatch<Action>,
) => {
  if (!isCurrentRowErrored && isCurrentRowExistsInErrorData) {
    errorRows.splice(currentRowIndexInErrorData, 1);
    dispatch({ type: 'setErroredRows', payload: errorRows });
  }
};

// insertCountryDetailsToDirtyData function is to update dirty data with country name and country code automatically.
// the function works only for new entry in dirty data, not to update existing record in dirty data.
export const insertCountryDetailsToDirtyData = (
  currDirtyRow: IEditDirtyData,
  row: IEditTableData,
  column: string,
  countryNameAndCodeAmericas: ICountry[],
  SCOPE1: string,
) => {
  const updatedDirtyData = currDirtyRow;
  // if Country name is changed, update dirty data with Country Code
  if (areStringsEqual(column, COUNTRY_NAME)) {
    updatedDirtyData[COUNTRY_CODE] = getCountryCode(
      row[COUNTRY_NAME] as string,
      countryNameAndCodeAmericas,
    );
  }

  // if scope is changed to 0, update dirty data with Country Name and Country Code as empty values
  if (areStringsEqual(column, SCOPE1) && (row[SCOPE1] === 0 || row[SCOPE1] === OUT_OF_SCOPE)) {
    updatedDirtyData[COUNTRY_CODE] = getCountryCode('', countryNameAndCodeAmericas);
    updatedDirtyData[COUNTRY_NAME] = '';
  }

  // if scope is changed to 1, update dirty data with Country Name and Country Code values
  if (areStringsEqual(column, SCOPE1) && (row[SCOPE1] === 1 || row[SCOPE1] === IN_SCOPE)) {
    updatedDirtyData[COUNTRY_CODE] = getCountryCode(
      row[COUNTRY_NAME] as string,
      countryNameAndCodeAmericas,
    );
    updatedDirtyData[COUNTRY_NAME] = row[COUNTRY_NAME] as string;
  }
  return updatedDirtyData;
};

export const getCountryCode = (countryName: string, countryNameAndCodeAmericas: ICountry[]) => {
  let countryCode = '';
  for (const key of countryNameAndCodeAmericas) {
    if (key.COUNTRY_NAME === countryName) {
      countryCode = key.COUNTRY_CODE;
      break;
    }
  }
  return countryCode;
};

// handleEditData is called whenever a data is changed
export const handleEditData = (
  colData: {
    e: React.ChangeEvent<HTMLSelectElement> | string | number;
    val: string;
    index: number;
    row: IEditTableData;
  },
  booleanVar: { isEditError: boolean; isScopeTableSelected: boolean },
  colNames: string[],
  stateVars: {
    state: IEditFeatureDataState;
    setIsDirtyData: Dispatch<SetStateAction<boolean>>;
    dispatch: Dispatch<Action>;
    setUpdatedVal: Dispatch<SetStateAction<string | number>>;
    setOriginalVal: Dispatch<SetStateAction<string | number>>;
    setUpdatedCol: Dispatch<SetStateAction<string>>;
    countryNameAndCodeAmericas: ICountry[];
    setIsQtyExceedThreeWholeDigits: Dispatch<SetStateAction<boolean>>;
    errorTableData: IEditTableData[];
  },
) => {
  const { e, val, index, row } = colData;
  const { isEditError, isScopeTableSelected } = booleanVar;
  const [ID1, QTY1, DEAL_QUANTITY1, , , , COMMENTS1, SCOPE1] = colNames;
  const {
    state,
    setIsDirtyData,
    dispatch,
    setUpdatedVal,
    setOriginalVal,
    setUpdatedCol,
    countryNameAndCodeAmericas,
    setIsQtyExceedThreeWholeDigits,
    errorTableData,
  } = stateVars;
  const columnName: string = val;
  setIsDirtyData(true);
  //  currPageData is the data being shown currently on the page. during validation error, the current data is errored data
  const { currPageData, masterData } = getData(isEditError, errorTableData, state);
  const info = {
    e,
    row,
    columnName,
    QTY1,
    DEAL_QUANTITY1,
    setUpdatedCol,
    masterData,
    ID1,
    setOriginalVal,
    setUpdatedVal,
  };
  handleEditForQtyAndDealQty(info);
  const updatedPageData = currPageData.map((value, ind: number) => {
    if (ind === index) {
      if (typeof e === 'string' || typeof e === 'number') {
        value[columnName] = e;
      } else {
        value[columnName] = e.target.value;
      }
      const columnData = { columnName, value, countryNameAndCodeAmericas };
      const columnNames = { COMMENTS1, ID1, QTY1, SCOPE1, DEAL_QUANTITY1 };
      const booleanVars = { isEditError, isScopeTableSelected };
      const stateVar = {
        setIsQtyExceedThreeWholeDigits,
        dispatch,
        masterData,
        state,
      };
      // maintain dirty data
      maintainDirtyData(columnData, columnNames, booleanVars, stateVar);

      // maintain error data
      maintainErrorData(
        value,
        state,
        isEditError,
        isScopeTableSelected,
        { COMMENTS1, QTY1, DEAL_QUANTITY1, ID1, SCOPE1 },
        dispatch,
      );
    }
    return value;
  });
  dispatch({ type: 'setViewEditPaginationData', payload: updatedPageData });
};

export const handleEditForQtyAndDealQty = (data: {
  e: React.ChangeEvent<HTMLSelectElement> | string | number;
  row: IEditTableData;
  columnName: string;
  QTY1: string;
  DEAL_QUANTITY1: string;
  setUpdatedCol: Dispatch<SetStateAction<string>>;
  masterData: IEditTableData[];
  ID1: string;
  setOriginalVal: Dispatch<SetStateAction<string | number>>;
  setUpdatedVal: Dispatch<SetStateAction<string | number>>;
}) => {
  const {
    e,
    row,
    columnName,
    QTY1,
    DEAL_QUANTITY1,
    setUpdatedCol,
    masterData,
    ID1,
    setOriginalVal,
    setUpdatedVal,
  } = data;
  if (areStringsEqual(columnName, QTY1) || areStringsEqual(columnName, DEAL_QUANTITY1)) {
    let masterVal: string | number = '';
    setUpdatedCol(columnName);
    for (const masterDataObj of masterData) {
      if (masterDataObj[ID1] === row[ID1]) {
        masterVal = masterDataObj[columnName];
      }
    }
    setOriginalVal(masterVal);
    let newVal: string | number;
    if (typeof e === 'string' || typeof e === 'number') {
      newVal = e;
    } else {
      newVal = e.target.value;
    }
    if (masterVal === newVal) {
      setUpdatedVal(NOT_UPDATED);
    } else {
      setUpdatedVal(newVal);
    }
  }
};

export const getData = (
  isEditError: boolean,
  errorTableData: IEditTableData[],
  state: IEditFeatureDataState,
) => {
  let currPageData;
  let masterData;

  if (isEditError) {
    currPageData = [...errorTableData];
    masterData = [...state.masterErroredRows];
  } else {
    currPageData = [...state.viewEditPaginationData];
    masterData = [...state.masterPaginationData];
  }
  return { currPageData, masterData };
};

// check if corresponding row has any changes for allowed editable columns
export const isCommentsColDirty = (
  row: IEditTableData,
  state: IEditFeatureDataState,
  isEditError: boolean,
  ID1: string,
  COMMENTS1: string,
) => {
  const dirtydata = [...state.dirtyData];
  const masterComments: string = getMasterComments(
    isEditError,
    state,
    ID1,
    row,
    COMMENTS1,
  ) as string;
  // iterate the dirty data and check if current row ID is present in it and if present
  //  then if the comments are not updated, then return true. Coz comment is mandatory for any changed row
  for (const dirtData of dirtydata) {
    const rowComment = row[COMMENTS1 as keyof typeof row] ?? '';
    if (
      dirtData[ID1 as keyof typeof dirtData] === row[ID1 as keyof typeof row] &&
      isCommentsError(masterComments, rowComment.toString())
    ) {
      return true;
    }
  }
  return false;
};

export const getMasterComments = (
  isEditError: boolean,
  state: IEditFeatureDataState,
  ID1: string | number,
  row: IEditTableData,
  COMMENTS1: string | number,
) => {
  const dataSource = isEditError ? state.masterErroredRows : state.masterPaginationData;
  for (const data of dataSource) {
    if (data[ID1] === row[ID1]) {
      return data[COMMENTS1];
    }
  }
  return '';
};

export const isScopeTableCountryNameEmptyWhenInScope = (row: IEditTableData, SCOPE1: string) => {
  let isDirty = false;
  if ((row[SCOPE1] === IN_SCOPE || row[SCOPE1] === 1) && row[COUNTRY_NAME] === '') {
    isDirty = true;
  }
  return isDirty;
};

//  check if the particular box is dirty(different from master data)
export const isBoxDirty = (
  row: IEditTableData,
  value: string | number,
  val: string,
  isReadyToPublish: boolean,
  state: IEditFeatureDataState,
  booleanVars: { QTY1: string; DEAL_QUANTITY1: string; ID1: string },
  isEditError: boolean,
) => {
  const { QTY1, DEAL_QUANTITY1, ID1 } = booleanVars;
  const masterData = isReadyToPublish
    ? [...state.masterErroredRows]
    : [...state.masterPaginationData];

  const columnName = val;

  if ((areStringsEqual(val, QTY1) || areStringsEqual(val, DEAL_QUANTITY1)) && !isQtyValid(value)) {
    return false;
  }

  const dataToCheck = isEditError ? state.masterErroredRows : masterData;

  for (const checkData of dataToCheck) {
    if (
      checkData[ID1] === row[ID1] &&
      columnName.toLowerCase().includes('scope') &&
      Number(checkData[columnName]) === Number(value.toString().charAt(0))
    ) {
      return false;
    }
    if (checkData[ID1] === row[ID1] && checkData[columnName] === value) {
      return false;
    }
  }

  return true;
};

export const customOptionsFunc = (
  row: IEditTableData,
  columnName: string,
  colNames: { MARKET1: string; NCF_CATEGORY1: string; ID1: string },
  state: IEditFeatureDataState,
  booleanVars: { isAsiaJapanTableSelected: boolean; isEditError: boolean },
  options: { ncfCatOptionsForAsiaJapanTable: OptionI[]; countryOptionsAmericas: OptionI[] },
) => {
  let customOptions = [];
  const { isAsiaJapanTableSelected, isEditError } = booleanVars;
  const { ncfCatOptionsForAsiaJapanTable, countryOptionsAmericas } = options;
  const { MARKET1, NCF_CATEGORY1, ID1 } = colNames;

  if (columnName === MARKET1) {
    customOptions = JSON.parse(JSON.stringify(state.marketOptions));
  } else if (columnName === NCF_CATEGORY1) {
    if (isAsiaJapanTableSelected) {
      customOptions = JSON.parse(JSON.stringify(ncfCatOptionsForAsiaJapanTable));
    } else {
      customOptions = JSON.parse(JSON.stringify(state.ncfCategoryOptions));
    }
  } else if (columnName === COUNTRY_NAME) {
    customOptions = JSON.parse(JSON.stringify(countryOptionsAmericas));
  }

  let masterData = [];
  let masterColValue = '';
  if (isEditError) {
    masterData = [...state.masterErroredRows];
  } else {
    masterData = [...state.masterPaginationData];
  }

  for (const masterDataObj of masterData) {
    if (masterDataObj[ID1] === row[ID1]) {
      masterColValue = masterDataObj[columnName] as string;
    }
  }

  for (const optionData of customOptions) {
    if (optionData['label']?.toString().toUpperCase() === masterColValue?.toUpperCase()) {
      optionData['className'] = tableStyles.masterOption;
    }
  }

  return customOptions;
};

export const updateColorHelper = (
  columnName: string,
  row: IEditTableData,
  isEditError: boolean,
  state: IEditFeatureDataState,
  COMMENTS1: string,
  setStateFuncs: {
    setUpdatedCol: Dispatch<SetStateAction<string>>;
    setOriginalVal: Dispatch<SetStateAction<string | number>>;
    setUpdatedVal: Dispatch<SetStateAction<string | number>>;
  },
  ID1: string | number,
) => {
  const { setUpdatedCol, setOriginalVal, setUpdatedVal } = setStateFuncs;
  let masterData: IEditTableData[] = [];
  let masterVal: string | number = '';
  if (isEditError) {
    masterData = [...state.masterErroredRows];
  } else {
    masterData = [...state.masterPaginationData];
  }
  //  for showing helpers to user
  if (!areStringsEqual(columnName, COMMENTS1)) {
    setUpdatedCol(columnName);
    for (const masterDataObj of masterData) {
      if (masterDataObj[ID1] === row[ID1]) {
        masterVal = masterDataObj[columnName];
      }
    }
    setOriginalVal(masterVal);
    if (masterVal === row[columnName]) {
      setUpdatedVal(NOT_UPDATED);
    } else {
      setUpdatedVal(row[columnName]);
    }
  }
};

export const setCountryCodeValue = (
  row: IEditTableData,
  SCOPE1: string,
  countryNameAndCodeAmericas: ICountry[],
) => {
  const countryName = row[COUNTRY_NAME];
  let countryCode = '';
  const scope = row[SCOPE1];

  if (scope === OUT_OF_SCOPE) {
    countryCode = countryNameAndCodeAmericas[0].COUNTRY_CODE;
  } else {
    for (const ind of countryNameAndCodeAmericas) {
      if (ind.COUNTRY_NAME === countryName) {
        countryCode = ind.COUNTRY_CODE;
      }
    }
  }

  return countryCode;
};

export const clearHelper = (
  setUpdatedCol: Dispatch<SetStateAction<string>>,
  setOriginalVal: Dispatch<SetStateAction<string | number>>,
  setUpdatedVal: Dispatch<SetStateAction<string | number>>,
) => {
  setUpdatedCol('');
  setOriginalVal('');
  setUpdatedVal('');
};

export const updateCols = (
  colData: { cols: string[]; colObj: IColumnRender[]; colNames: string[] },
  booleanOptions: {
    isEditable: boolean;
    isReadyToPublish: boolean;
    isEditError: boolean;
    isAsiaJapanTableSelected: boolean;
    isScopeTableSelected: boolean;
  },
  setFuncs: {
    setUpdatedCol: Dispatch<SetStateAction<string>>;
    setOriginalVal: Dispatch<SetStateAction<string | number>>;
    setUpdatedVal: Dispatch<SetStateAction<string | number>>;
    setIsQtyExceedThreeWholeDigits: Dispatch<SetStateAction<boolean>>;
    setIsDirtyData: Dispatch<SetStateAction<boolean>>;
  },
  optionsData: {
    countryNameAndCodeAmericas: ICountry[];
    scopeOptions: OptionI[];
    ncfCatOptionsForAsiaJapanTable: OptionI[];
    countryOptionsAmericas: OptionI[];
  },
  actions: { state: IEditFeatureDataState; dispatch: Dispatch<Action> },
  errorTableData: IEditTableData[],
) => {
  const { cols, colObj, colNames } = colData;
  const {
    isEditable,
    isReadyToPublish,
    isEditError,
    isAsiaJapanTableSelected,
    isScopeTableSelected,
  } = booleanOptions;
  const {
    setUpdatedCol,
    setOriginalVal,
    setUpdatedVal,
    setIsQtyExceedThreeWholeDigits,
    setIsDirtyData,
  } = setFuncs;
  const {
    countryNameAndCodeAmericas,
    scopeOptions,
    ncfCatOptionsForAsiaJapanTable,
    countryOptionsAmericas,
  } = optionsData;
  const { state, dispatch } = actions;

  const setStateFuncs = { setUpdatedCol, setOriginalVal, setUpdatedVal };
  const booleanVar = { isEditError, isScopeTableSelected };
  const stateVars = {
    state,
    setIsDirtyData,
    dispatch,
    setUpdatedVal,
    setOriginalVal,
    setUpdatedCol,
    countryNameAndCodeAmericas,
    setIsQtyExceedThreeWholeDigits,
    errorTableData,
  };

  const [
    ID1,
    QTY1,
    DEAL_QUANTITY1,
    MARKET1,
    NCF_CATEGORY1,
    BUYSELL1,
    COMMENTS1,
    SCOPE1,
    VOLUME1,
    NCF_SCOPE1,
  ] = colNames;

  const createInputComponent = (props: {
    Component: React.ElementType;
    val: string;
    row: IEditTableData;
    value: string | number;
    index: number;
    disabled: boolean;
    ariaLabel: string | null;
    options: OptionI[] | null;
    type?: string;
    invalid?: boolean;
  }) => {
    const { Component, val, row, value, disabled, ariaLabel, options, type, invalid, index } =
      props;
    return (
      <Component
        onMouseEnter={() =>
          updateColorHelper(val, row, isEditError, state, COMMENTS1, setStateFuncs, ID1)
        }
        onMouseLeave={() => clearHelper(setUpdatedCol, setOriginalVal, setUpdatedVal)}
        value={value}
        style={
          isBoxDirty(
            row,
            value,
            val,
            isReadyToPublish,
            state,
            { QTY1, DEAL_QUANTITY1, ID1 },
            isEditError,
          )
            ? styles.colChanged
            : styles.nothing
        }
        disabled={disabled}
        size='small'
        aria-label={ariaLabel}
        allowClear={false}
        options={options}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
          handleEditData({ e, val, index, row }, booleanVar, colNames, stateVars)
        }
        type={type}
        invalid={invalid}
      />
    );
  };

  const columnIdentifiers: ColumnIdentifiers = {
    MARKET1,
    NCF_CATEGORY1,
    ID1,
    BUYSELL1,
    QTY1,
    DEAL_QUANTITY1,
    SCOPE1,
    VOLUME1,
    NCF_SCOPE1,
    COMMENTS1,
  };

  const configOptions: ConfigOptions = {
    createInputComponent,
    isReadyToPublish,
    state,
    isAsiaJapanTableSelected,
    isEditError,
  };

  const optionLists: OptionLists = {
    ncfCatOptionsForAsiaJapanTable,
    countryOptionsAmericas,
    countryNameAndCodeAmericas,
    scopeOptions,
  };

  const columnsConfig = getColConfig(columnIdentifiers, configOptions, optionLists);

  cols.map((val: string) => {
    if ((isEditable || isReadyToPublish) && state.editableCol.indexOf(val) > -1) {
      columnsConfig.forEach((config) => {
        if (config.condition(val)) {
          colObj.push({
            dataIndex: val,
            key: val,
            title: <div>{decorateColName(val)}</div>,
            width: 100,
            className: tableStyles.reportPublishTableCols,
            render: config.render(val),
          });
        }
      });
    } else if ((BUYSELL1 && areStringsEqual(val, BUYSELL1)) || val.toLowerCase() === 'region') {
      colObj.push({
        dataIndex: val,
        key: val,
        width: 100,
        title: <div>{decorateColName(val)}</div>,
        className: tableStyles.nonEditableCols,
        render: (value: string): ReactElement => <>{value.toUpperCase()}</>,
      });
    } else {
      colObj.push({
        dataIndex: val,
        key: val,
        title: <div>{decorateColName(val)}</div>,
        width: 50,
        className: tableStyles.nonEditableCols,
      });
    }
  });
  return colObj;
};

export const getColConfig = (
  columnIdentifiers: ColumnIdentifiers,
  configOptions: ConfigOptions,
  optionLists: OptionLists,
) => {
  const {
    MARKET1,
    NCF_CATEGORY1,
    ID1,
    BUYSELL1,
    QTY1,
    DEAL_QUANTITY1,
    SCOPE1,
    VOLUME1,
    NCF_SCOPE1,
    COMMENTS1,
  } = columnIdentifiers;

  const { createInputComponent, isReadyToPublish, state, isAsiaJapanTableSelected, isEditError } =
    configOptions;

  const {
    ncfCatOptionsForAsiaJapanTable,
    countryOptionsAmericas,
    countryNameAndCodeAmericas,
    scopeOptions,
  } = optionLists;
  return [
    {
      condition: (val: string) => areStringsEqual(val, MARKET1),
      render:
        (val: string) =>
        (value: string | number, row: IEditTableData, index: number): ReactElement => {
          return createInputComponent({
            Component: Select,
            val,
            row,
            value,
            index,
            disabled: isReadyToPublish,
            ariaLabel: 'Select',
            options: customOptionsFunc(
              row,
              val,
              { MARKET1, NCF_CATEGORY1, ID1 },
              state,
              { isAsiaJapanTableSelected, isEditError },
              { ncfCatOptionsForAsiaJapanTable, countryOptionsAmericas },
            ),
          });
        },
    },
    {
      condition: (val: string) => areStringsEqual(val, NCF_CATEGORY1),
      render:
        (val: string) =>
        (value: string | number, row: IEditTableData, index: number): ReactElement =>
          createInputComponent({
            Component: Select,
            val,
            row,
            value,
            index,
            disabled: isReadyToPublish,
            ariaLabel: 'Select',
            options: customOptionsFunc(
              row,
              val,
              { MARKET1, NCF_CATEGORY1, ID1 },
              state,
              { isAsiaJapanTableSelected, isEditError },
              { ncfCatOptionsForAsiaJapanTable, countryOptionsAmericas },
            ),
          }),
    },
    {
      condition: (val: string) => areStringsEqual(val, BUYSELL1),
      render:
        (val: string) =>
        (value: string | number, row: IEditTableData, index: number): ReactElement =>
          createInputComponent({
            Component: Select,
            val,
            row,
            value,
            index,
            disabled: isReadyToPublish,
            ariaLabel: 'Select',
            options: state.buySellOptions,
          }),
    },
    {
      condition: (val: string) => areStringsEqual(val, QTY1),
      render:
        (val: string) =>
        (value: string | number, row: IEditTableData, index: number): ReactElement =>
          createInputComponent({
            Component: TextInput,
            val,
            row,
            value,
            index,
            disabled: isReadyToPublish,
            type: 'number',
            ariaLabel: null,
            options: null,
            invalid: !isQtyValid(value),
          }),
    },
    {
      condition: (val: string) => areStringsEqual(val, DEAL_QUANTITY1),
      render:
        (val: string) =>
        (value: string | number, row: IEditTableData, index: number): ReactElement =>
          createInputComponent({
            Component: TextInput,
            val,
            row,
            value,
            index,
            disabled: isReadyToPublish,
            type: 'number',
            ariaLabel: null,
            options: null,
            invalid: !isQtyValid(value),
          }),
    },
    {
      condition: (val: string) => areStringsEqual(val, VOLUME1),
      render:
        (val: string) =>
        (value: string | number, row: IEditTableData, index: number): ReactElement =>
          createInputComponent({
            Component: TextInput,
            val,
            row,
            value,
            index,
            disabled: isReadyToPublish,
            type: 'number',
            ariaLabel: null,
            options: null,
            invalid: !isQtyValid(value),
          }),
    },
    {
      condition: (val: string) => areStringsEqual(val, COUNTRY_NAME),
      render:
        (val: string) =>
        (value: string | number, row: IEditTableData, index: number): ReactElement =>
          createInputComponent({
            Component: Select,
            val,
            row,
            value,
            index,
            disabled: isReadyToPublish || row[SCOPE1] === OUT_OF_SCOPE || row[SCOPE1] === 0,
            ariaLabel: 'Select',
            options: customOptionsFunc(
              row,
              val,
              { MARKET1, NCF_CATEGORY1, ID1 },
              state,
              { isAsiaJapanTableSelected, isEditError },
              { ncfCatOptionsForAsiaJapanTable, countryOptionsAmericas },
            ),
            invalid: isScopeTableCountryNameEmptyWhenInScope(row, SCOPE1),
          }),
    },
    {
      condition: (val: string) => areStringsEqual(val, COUNTRY_CODE),
      render:
        (val: string) =>
        (_value: string | number, row: IEditTableData, index: number): ReactElement =>
          createInputComponent({
            Component: TextInput,
            val,
            row,
            value: setCountryCodeValue(row, SCOPE1, countryNameAndCodeAmericas),
            index,
            disabled: true,
            ariaLabel: null,
            options: null,
          }),
    },
    {
      condition: (val: string) => areStringsEqual(val, SCOPE1),
      render:
        (val: string) =>
        (value: string | number, row: IEditTableData, index: number): ReactElement =>
          createInputComponent({
            Component: Select,
            val,
            row,
            value: value === 0 || value === OUT_OF_SCOPE ? OUT_OF_SCOPE : IN_SCOPE,
            index,
            disabled: isReadyToPublish,
            ariaLabel: 'Select',
            options: scopeOptions,
          }),
    },
    {
      condition: (val: string) => areStringsEqual(val, NCF_SCOPE1),
      render:
        (val: string) =>
        (value: string | number, row: IEditTableData, index: number): ReactElement =>
          createInputComponent({
            Component: Select,
            val,
            row,
            value: value === 0 || value === OUT_OF_SCOPE || value === '0' ? OUT_OF_SCOPE : IN_SCOPE,
            index,
            disabled: isReadyToPublish,
            ariaLabel: 'Select',
            options: scopeOptions,
          }),
    },
    {
      condition: (val: string) => areStringsEqual(val, COMMENTS1),
      render:
        (val: string) =>
        (value: string | number, row: IEditTableData, index: number): ReactElement =>
          createInputComponent({
            Component: TextArea,
            val,
            row,
            value,
            index,
            disabled: isReadyToPublish,
            ariaLabel: null,
            options: null,
            invalid: isCommentsColDirty(row, state, isEditError, ID1, COMMENTS1),
          }),
    },
  ];
};

const styles = {
  colChanged: {
    border: '2px solid #8ef4b7',
  },
  nothing: {},
  countryCode: {
    color: 'black',
  },
  countryCodeChanged: {
    border: '2px solid #8ef4b7',
    color: 'black',
  },
};
