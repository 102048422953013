import { Alert, Sentiments } from '@sede-x/shell-ds-react-framework';
import React, { useEffect, useState } from 'react';
import {
  REPORT_FAILURE_MESSAGE,
  REPORT_IN_PROGRESS_MESSAGE,
  REPORT_SUCCESS_MESSAGE,
  SOM_MAIL_ID,
  FIVE_THOUSAND,
} from '../../../api/constants';

export const ReportStatusInfo = ({ reportStatus }: { reportStatus: string }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (reportStatus !== '') {
      setIsVisible(true);
      const timeout = setTimeout(() => {
        setIsVisible(false);
      }, FIVE_THOUSAND);

      return () => clearTimeout(timeout);
    }
    return undefined;
  }, [reportStatus]);

  const isReportSuccess = reportStatus?.toLocaleLowerCase() === 'success';
  const isReportFailure = reportStatus?.toLocaleLowerCase() === 'fail';
  const getMessage = () => {
    let message;
    if (isReportSuccess) {
      message = REPORT_SUCCESS_MESSAGE;
    } else if (isReportFailure) {
      message = `${REPORT_FAILURE_MESSAGE}${SOM_MAIL_ID}`;
    } else {
      message = REPORT_IN_PROGRESS_MESSAGE;
    }
    return message;
  };

  if (reportStatus === '' || !isVisible) {
    return null; // Return null when there's no report status or it's not visible
  }

  const getSentiment = () => {
    if (isReportSuccess) {
      return Sentiments.Positive;
    } else if (isReportFailure) {
      return Sentiments.Negative;
    } else {
      return Sentiments.Information;
    }
  };

  return (
    <Alert
      sentiment={getSentiment()}
      style={{ marginBottom: '20px', width: 'auto', marginTop: '20px' }}
      elevation
    >
      {getMessage()}
    </Alert>
  );
};
