import styled from 'styled-components';

export const StyledDiv = styled.div`
  width: 99%;
  margin-top: 10px;
`;

export const FooterNotesRen = styled.div`
  padding-left: 10px;
  padding-top: 5px;
  font-style: italic;
  color: #757575;
  font-size: 12px;
  margin-top: -7px;
  background: #ffffff;
`;
