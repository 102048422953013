import './App.css';
import { ShellThemeProvider } from '@sede-x/shell-ds-react-framework';
import { Provider } from 'react-redux';
import store from './CarbonDashboard/redux/store';
import { AppContext } from './Tanso/Context/AppContext';
import React, { useReducer, useMemo } from 'react';
import { DataEditReducers } from './Tanso/Reducers/ActionHandlers/DataEditReducers';
import { EditFeatureInitialState } from './Tanso/Reducers/InitialStates/EditFeatureInitialState';
import AppContent from './commonUtils/authPages/AppContent';
import { PpaInsightInitialState } from './Tanso/Reducers/InitialStates/PpaInsightInitialState';
import { PpaInsightReducers } from './Tanso/Reducers/ActionHandlers/PpaInsightReducers';
import { AnalyticsHomeReducers } from './Tanso/Reducers/ActionHandlers/AnalyticsHomeReducers';
import { AnalyticsSnapshotReportReducers } from './Tanso/Reducers/ActionHandlers/AnalyticsSnapshotReportReducers';
import { BiaReportsReducers } from './Tanso/Reducers/ActionHandlers/BiaReportsReducers';
import { CiTradeAndVolumeReducers } from './Tanso/Reducers/ActionHandlers/CiTradeAndVolumeReducers';
import { PptReportsReducers } from './Tanso/Reducers/ActionHandlers/PptReducers';
import { ReconReportsReducers } from './Tanso/Reducers/ActionHandlers/ReconReducers';
import { AnalyticsHomeInitialState } from './Tanso/Reducers/InitialStates/AnalyticsHomeInitialState';
import { AnalyticsSnapshotReportInitialState } from './Tanso/Reducers/InitialStates/AnalyticsSnapshotReportInitialState';
import { BiaReportsInitialState } from './Tanso/Reducers/InitialStates/BiaReportsInitialState';
import { CiTradeAndVolumeInitialState } from './Tanso/Reducers/InitialStates/CiTradeAndVolumeInitialState';
import { PptReportsInitialState } from './Tanso/Reducers/InitialStates/PptInitialState';
import { ReconReportsInitialState } from './Tanso/Reducers/InitialStates/ReconInitialState';

function App() {
  const [state, dispatch] = useReducer(DataEditReducers, EditFeatureInitialState);
  const [ppaInsightState, ppaInsightDispatch] = useReducer(
    PpaInsightReducers,
    PpaInsightInitialState,
  );
  const [biaReportState, biaReportDispatch] = useReducer(
    BiaReportsReducers,
    BiaReportsInitialState,
  );
  const [analyticsHomeState, analyticsHomeDispatch] = useReducer(
    AnalyticsHomeReducers,
    AnalyticsHomeInitialState,
  );
  const [ciTradeAndVolumeState, ciTradeAndVolumeDispatch] = useReducer(
    CiTradeAndVolumeReducers,
    CiTradeAndVolumeInitialState,
  );

  const [analyticsSnapshotReportState, analyticsSnapshotReportDispatch] = useReducer(
    AnalyticsSnapshotReportReducers,
    AnalyticsSnapshotReportInitialState,
  );
  const [pptReportsState, pptReportsDispatch] = useReducer(
    PptReportsReducers,
    PptReportsInitialState,
  );
  const [reconReportsState, reconReportsDispatch] = useReducer(
    ReconReportsReducers,
    ReconReportsInitialState,
  );

  const value = useMemo(
    () => ({
      appData: { state, dispatch },
      ppaInsight: { state: ppaInsightState, dispatch: ppaInsightDispatch },
      biaReports: {
        state: biaReportState,
        dispatch: biaReportDispatch,
      },
      analyticsHome: {
        state: analyticsHomeState,
        dispatch: analyticsHomeDispatch,
      },
      ciTradeAndVolume: {
        state: ciTradeAndVolumeState,
        dispatch: ciTradeAndVolumeDispatch,
      },
      analyticsSnapshotReport: {
        state: analyticsSnapshotReportState,
        dispatch: analyticsSnapshotReportDispatch,
      },
      pptReports: {
        state: pptReportsState,
        dispatch: pptReportsDispatch,
      },
      reconReports: {
        state: reconReportsState,
        dispatch: reconReportsDispatch,
      },
    }),
    [
      state,
      dispatch,
      ppaInsightState,
      ppaInsightDispatch,
      biaReportState,
      biaReportDispatch,
      analyticsHomeState,
      analyticsHomeDispatch,
      ciTradeAndVolumeState,
      ciTradeAndVolumeDispatch,
      analyticsSnapshotReportState,
      analyticsSnapshotReportDispatch,
      pptReportsState,
      pptReportsDispatch,
      reconReportsState,
      reconReportsDispatch,
    ],
  );

  return (
    <div className='App'>
      <ShellThemeProvider theme='light'>
        <Provider store={store}>
          <AppContext.Provider value={value}>
            <AppContent />
          </AppContext.Provider>
        </Provider>
      </ShellThemeProvider>
    </div>
  );
}

export default React.memo(App);
