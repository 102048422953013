import {
  Grid,
  Flexbox,
  Card,
  FormField,
  Button,
  useFileXhrUploader,
  Modal,
} from '@sede-x/shell-ds-react-framework';
import {
  OnError,
  OnProgress,
} from '@sede-x/shell-ds-react-framework/build/esm/components/FileUploader/FileUploader.types.js';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../../../Context/AppContext';
import { useFileUploadEffect } from '../../../../SectoralPerformance/useFileUploadEffect';
import { removeDeleteLinkInFileUploader } from '../../../../SectoralPerformance/SectoralInfoUtils';
import { hideSpinner, showSpinner } from '../../../../../Utils/utils';
import { uploadETRMFile, getReconETRMFileUploadLog } from '../../../../../../api/services';
import { THOUSAND_FIVE_HUNDRED } from '../../../../../../api/constants';
import ReusableFileUploader from '../../../../SectoralPerformance/ReusableFileUploader';
import { UpdateInfo } from '../../../../SectoralPerformance/cardUtils';
import { handleOnUpload } from './DataReconETRMFileUploadUtils';
import moment from 'moment';
import { Container } from './DataReconETRMFileUpload.style';

export const DataReconETRMFileUpload = () => {
  const { dispatch } = useContext(AppContext).appData;
  const [isEtrmFileUploadSuccess, setIsEtrmFileUploadSuccess] = useState(false);
  const [etrmFileUploadState, setEtrmFileUploadState] = useState('none'); // none, success, invalid
  const [etrmFileUploadFailureMessage, setEtrmFileUploadFailureMessage] = useState('');
  const [etrmFile, setEtrmFile] = useState<File | null>(null);
  const [isFileUploadSuccessModalOpen, setIsFileUploadSuccessModalOpen] = useState(false);
  const [uploadLog, setUploadLog] = useState<{ createdAt: string } | null>(null);

  const url = '';
  const { handleOnDrop } = useFileXhrUploader({
    url,
  });

  useFileUploadEffect('etrmFileId', isEtrmFileUploadSuccess, etrmFileUploadState);

  const resetFileUploader = (newEtrmFile: File | null) => {
    if (newEtrmFile) {
      removeDeleteLinkInFileUploader('etrmFileId');
    }
  };

  const onRemoveEtrmFile = () => {
    setEtrmFile(null);
    setIsEtrmFileUploadSuccess(false);
    setEtrmFileUploadFailureMessage('');
    setEtrmFileUploadState('none');
  };

  const uploadFilesToDb = async () => {
    const formData = new FormData();
    if (etrmFile) {
      formData.append('etrmFile', etrmFile);
    }
    showSpinner(dispatch);
    try {
      await uploadETRMFile(formData);
      setIsFileUploadSuccessModalOpen(true);
      setTimeout(() => {
        setIsFileUploadSuccessModalOpen(false);
      }, THOUSAND_FIVE_HUNDRED);
      resetFileUploader(etrmFile);
      onRemoveEtrmFile();
      removeDeleteLinkInFileUploader('etrmFileId');
    } catch (e) {
      // Log error once toast feature is implemented
    }
    hideSpinner(dispatch);
  };

  const fetchUploadLog = async () => {
    try {
      const log = await getReconETRMFileUploadLog();
      const formattedDate = moment(log.data.createdAt).format('hh:mm A, DD MMM YYYY');
      setUploadLog({ ...log.data, createdAt: formattedDate });
    } catch (error) {
      // Log error once toast feature is implemented
    }
  };

  useEffect(() => {
    fetchUploadLog(); // Fetch the upload log when the component mounts
  }, []);

  return (
    <Card
      className='card'
      data-testid='upload-mapping-sheet-card'
      bodyPadding={true}
      style={{
        borderRadius: '4px',
        background: 'var(--Background-Surface, #FFF)',

        /* Elevation/Level 2 */
        boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0.06), 0px 0px 0px 0px rgba(0, 0, 0, 0.10)',
      }}
    >
      <Container
        style={{
          borderTop: '1px solid var(--Border-Subtle, rgba(28, 28, 28, 0.04))',
          paddingTop: '16px',
        }}
      >
        <Modal
          open={isFileUploadSuccessModalOpen}
          onClose={() => {
            setIsFileUploadSuccessModalOpen(false);
          }}
        >
          File(s) uploaded successfully.
        </Modal>
        <Flexbox gap='80px' justifyContent='center'>
          <FormField>
            <ReusableFileUploader
              fileId='etrmFileId'
              onDrop={(files: File[], onProgress: OnProgress, onError: OnError) =>
                handleOnDrop(files, onProgress, onError)
              }
              onUpload={(files: File[]) =>
                handleOnUpload(files, 'ETRMFile', {
                  setETRMFile: setEtrmFile,
                  setETRMFileUploadSuccess: setIsEtrmFileUploadSuccess,
                  setETRMFileUploadState: setEtrmFileUploadState,
                  setETRMFileUploadFailureMessage: setEtrmFileUploadFailureMessage,
                })
              }
              onRemoveFile={onRemoveEtrmFile}
              uploadState={etrmFileUploadState}
              uploadFailureMessage={etrmFileUploadFailureMessage}
            />
          </FormField>
        </Flexbox>
        <Grid flow='column' justifyContent='space-between'>
          <Grid.Cell>
            <UpdateInfo text={`Last successful upload on ${uploadLog?.createdAt}`} />
          </Grid.Cell>
          <Grid.Cell>
            <Flexbox gap='20px' justifyContent='center'>
              <Button
                variant='filled'
                onClick={() => {
                  uploadFilesToDb();
                }}
                disabled={!isEtrmFileUploadSuccess}
                data-testid='upload-mapping-sheet-button'
                style={{ fontSize: 'inherit', fontWeight: 'inherit' }}
              >
                Upload ETRM File
              </Button>
            </Flexbox>
          </Grid.Cell>
        </Grid>
      </Container>
    </Card>
  );
};
