import React, { useContext, useEffect } from 'react';
import { Grid, Flexbox, Divider } from '@sede-x/shell-ds-react-framework';
import 'leaflet/dist/leaflet.css';
import { SnapshotReportFilterSection } from '../SnapshotReportFilterSection/SnapshotReportFilterSection';
import { AppContext } from '../../../../../Context/AppContext';
import RegionVolumeDetailsTable from './RegionVolumeDetailsTable/RegionVolumeDetailsTable';
import NcfReportingHomeHeader from '../../NcfReportingUtils/NcfReportingHomeHeader/NcfReportingHomeHeader';

export const RegionVolumeDetails = () => {
  const { dispatch: appDataDispatch } = useContext(AppContext).appData;
  useEffect(() => {
    appDataDispatch({ type: 'setCurrentPage', payload: '' });
  }, [appDataDispatch]);

  const breadcrumbItems = [
    { name: 'Master Data Records', path: '/' },
    { name: 'Snapshot Report', path: '/snapshot-report' },
    { name: 'Volume Details' },
  ];

  return (
    <div
      style={{ height: '100%', background: '#F5F5F5' }}
      data-testid='snapshot-volume-details-page'
    >
      <Flexbox gap='24px 24px' style={{ marginLeft: '15px', justifyContent: 'start' }}>
        <SnapshotReportFilterSection />
        <Grid gap='10px'>
          <Grid.Cell>
            <Flexbox justifyContent='space-between'>
              <NcfReportingHomeHeader
                title='Data Snapshot Report'
                description='Data Snapshot Report'
                breadcrumbItems={breadcrumbItems}
              />
            </Flexbox>
          </Grid.Cell>
          <Divider size='medium' direction='horizontal' />
          <Grid.Cell>
            <RegionVolumeDetailsTable />
          </Grid.Cell>
        </Grid>
      </Flexbox>
    </div>
  );
};
