import styled from 'styled-components';

export const DisabledTextarea = styled.textarea`
  border-color: rgba(0, 0, 0, 0.06);
  cursor: not-allowed;
  width: 100%;
  resize: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
`;
