import { useContext } from 'react';
import { Heading } from '@sede-x/shell-ds-react-framework';
import { AppContext } from '../../Context/AppContext';

const HeaderBar = () => {
  const { state } = useContext(AppContext).appData;
  return (
    <div data-testid='header_bar'>
      {state.currentPage && (
        <div style={styles.headingContainer}>
          <Heading style={styles.heading}>{state.currentPage}</Heading>
          <hr style={styles.underline} />
        </div>
      )}
    </div>
  );
};
export default HeaderBar;

const styles = {
  headingContainer: {
    display: 'inline-block', // Ensures the underline only spans the text width
    position: 'relative' as const, // Specify the correct type for the position property
  },
  heading: {
    margin: '30px 0 0 20px',
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '24px',
    fontFamily: 'Shell Font',
  },
  underline: {
    width: '100%',
    border: 'none',
    height: '2px',
    marginLeft: '20px',
    background: 'linear-gradient(to right, #000, rgba(0, 0, 0, 0))', // Fading effect
    marginTop: '10px', // Adjust as needed to fit under the text
  },
};
