import { Text } from '@sede-x/shell-ds-react-framework';
import React, { Dispatch, SetStateAction } from 'react';
import { FOUR, THREE } from '../../../api/constants';

export const UpdateInfo = ({ text }: { text: string }) => {
  return (
    <Text className='update-text' size='small'>
      {text}
    </Text>
  );
};

export const setFileUpload = (
  value: boolean,
  isGoldTierFile: boolean,
  setGoldTierFileUploadSuccess: Dispatch<SetStateAction<boolean>>,
) => {
  if (isGoldTierFile) {
    setGoldTierFileUploadSuccess(value);
  }
};

export const getYearValue = () => {
  const year = new Date().getFullYear();
  const quarter = getLastQuarter();
  return quarter === FOUR ? year - 1 : year;
};

export const getLastQuarter = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1; // Adding 1 because months are zero-based
  const currentQuarter = Math.ceil(currentMonth / THREE);
  return currentQuarter === 1 ? FOUR : currentQuarter - 1;
};
