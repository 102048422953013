/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { AuthService } from './AuthService';
import RoleBasedUser from './RoleBasedUser';
import { IUserInfo } from '../../CarbonDashboard/Types/types';
import appInsights from '../appInsights';
import { AppContext } from '../../Tanso/Context/AppContext';
import { useAppDispatch, useAppSelector } from '../../CarbonDashboard/redux/hooks';
import { mapUserRoles } from './AppContentFunctions';

const AppContent: React.FC = () => {
  const { loggedInUserRoles } = useAppSelector((state) => state.dashboard);
  const authService = useMemo(() => new AuthService(), []);
  const [user, setUser] = useState<IUserInfo>({} as IUserInfo);

  //Tanso dispatch
  const { dispatch } = useContext(AppContext).appData;
  const cpmDispatch = useAppDispatch();
  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const login = async () => {
    await authService.login();
  };

  const logout = async () => {
    localStorage.setItem('activeTab', 'Dashboard');
    appInsights.trackEvent({
      name: 'User logged out',
      properties: {
        email: user?.email,
        username: user?.preferred_username,
        role: loggedInUserRoles,
      },
    });
    localStorage.removeItem('isAlreadyLoggedInStorage');
    await authService.logout();
  };

  const getUser = () => {
    authService
      .getUser()
      .then((userDetails) => {
        if (userDetails) {
          mapUserRoles(setUser, userDetails, dispatch, cpmDispatch);
        }
      })
      .catch(() => {
        // Catch error here
      });
  };

  return <RoleBasedUser user={user} login={login} logout={logout} />;
};

export default AppContent;
