// PPAToggle.tsx
import React, { useState } from 'react';
import { Flexbox, Toggle } from '@sede-x/shell-ds-react-framework';
import { ToggleText } from './PPAToggle.style';

type PPAToggleProps = {
  setView: React.Dispatch<React.SetStateAction<string>>;
};

const PPAToggle: React.FC<PPAToggleProps> = ({ setView }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleOnChange = () => {
    isChecked ? setView('Chart') : setView('Table');
    setIsChecked(!isChecked);
  };

  return (
    <Flexbox>
      <Toggle
        checked={isChecked}
        onChange={handleOnChange}
        style={{ marginRight: '10px' }}
        data-testid="PPA-toggle-switch"
        label="Chart View"
        mirrored
      />
      <ToggleText>Table View</ToggleText>
    </Flexbox>
  );
};

export default PPAToggle;
