/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-unused-vars */
import { RadioButton, Select, Option, SingleDatePicker } from '@sede-x/shell-ds-react-framework';
import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import {
  BUY_SLASH_SELL,
  CHANGE_ATTRIBUTE,
  CHANGE_TO,
  COUNTERPARTY_SHORT_NAME,
  END_DATE,
  I_ALIGNE_TABLE,
  MARKET,
  RULE_CONFIG_ID,
  START_DATE,
  STATUS,
  STORAGE,
  TRADE_TYPE,
  EIGHTY,
  THOUSAND,
  THREE,
  CHANGE_FROM,
} from '../../../api/constants';
import tableEdiOptionI from '../../../api/TableEditOptions';
import debounce from 'lodash/debounce';
import { fetchCptySearchResult, fetchMarketSearchResult } from '../../../api/services';
import dayjs, { Dayjs } from 'dayjs';
import { areStringsEqual, decorateColName, formatDate } from '../../Utils/utils';
import '../tableHeadingStyle.css';
import {
  RadioButtonSelected,
  Cross,
} from '@sede-x/shell-ds-react-framework/build/esm/components/Icon/components';
import {
  IColumnRender,
  IConfigRuleTableData,
  IEditFeatureData,
  OptionI,
  TConfigRow,
} from '../../../Types/types';

export interface IConfigRuleTableColStateVariables {
  isAllFieldsValidated: boolean;
  isAddNewRecord: boolean;
  newCptyShortName: string;
  configRulePaginationData: IConfigRuleTableData[];
  isSearchingCpty: boolean;
  cptyOptions: string[];
  newTradeType: string;
  newMarket: string;
  newStorage: string;
  storageOptions: OptionI[];
  newStartDate: string;
  newChangeAttribute: string;
  newChangeFrom: string;
  newChangeTo: string;
  buySellOption: OptionI[];
  marketSearchOptions: string[];
  isSearchingMarket: boolean;
  showRadioButton: boolean;
  radioChecked: number;
  tradeTypeOptions: OptionI[];
}

export interface IConfigRuleTableColSetStateVariables {
  setIsAllFieldsValidated: (value: boolean) => void;
  setNewCptyShortName: (name: string) => void;
  setConfigRulePaginationData: Dispatch<SetStateAction<IConfigRuleTableData[]>>;
  setIsAddNewRecord: (value: boolean) => void;
  setIsSearchingCpty: (value: boolean) => void;
  setCptyOptions: (value: []) => void;
  setNewTradeType: (value: string) => void;
  setNewMarket: (value: string) => void;
  setNewStorage: (value: string) => void;
  setNewStartDate: (value: string) => void;
  setNewEndDate: (value: string) => void;
  setNewChangeAttribute: (value: string) => void;
  setNewChangeFrom: (value: string) => void;
  setNewChangeTo: (value: string) => void;
  setMarketSearchOptions: (value: []) => void;
  setIsSearchingMarket: (value: boolean) => void;
  setIsShowMessage: (value: boolean) => void;
  setIsValAndSaveBtnClickedWithoutErrors: (value: boolean) => void;
  setIsValidationError: (value: boolean) => void;
  setRadioChecked: (value: number) => void;
}

export interface IStateVars {
  isAllFieldsValidated: boolean;
  isAddNewRecord: boolean;
  newCptyShortName: string;
  configRulePaginationData: IConfigRuleTableData[];
  isSearchingCpty: boolean;
  cptyOptions: string[];
  tradeTypeOptions: OptionI[];
  newTradeType: string;
  newMarket: string;
  newStorage: string;
  storageOptions: OptionI[];
  newStartDate: string;
  newChangeAttribute: string;
  newChangeFrom: string;
  newChangeTo: string;
  buySellOption: OptionI[];
  marketSearchOptions: string[];
  isSearchingMarket: boolean;
  showRadioButton: boolean;
  radioChecked: number;
}

export interface ISetStateVars {
  setNewCptyShortName?: (name: string) => void;
  setConfigRulePaginationData?: React.Dispatch<React.SetStateAction<IConfigRuleTableData[]>>;
  setIsAddNewRecord?: (value: boolean) => void;
  setIsSearchingCpty?: (value: boolean) => void;
  setCptyOptions?: (value: []) => void;
  setNewTradeType?: (value: string) => void;
  setNewMarket?: (value: string) => void;
  setNewStorage?: (value: string) => void;
  setNewStartDate?: (value: string) => void;
  setNewEndDate?: (value: string) => void;
  setNewChangeAttribute?: (value: string) => void;
  setNewChangeFrom?: (value: string) => void;
  setNewChangeTo?: (value: string) => void;
  setMarketSearchOptions?: (value: []) => void;
  setIsSearchingMarket?: (value: boolean) => void;
  setIsShowMessage?: (value: boolean) => void;
  setIsValAndSaveBtnClickedWithoutErrors?: (value: boolean) => void;
  setIsValidationError?: (value: boolean) => void;
  setRadioChecked?: (value: number) => void;
  setIsAllFieldsValidated?: (value: boolean) => void;
}

const changeAttributeMasterValue = { value: '', label: '' };

export const configRuleTableCols = (
  cols: string[],
  stateVariables: IConfigRuleTableColStateVariables,
  setStateVariables: IConfigRuleTableColSetStateVariables,
  state: IEditFeatureData,
) => {
  // Destructure state variables
  const {
    isAllFieldsValidated,
    isAddNewRecord,
    newCptyShortName,
    configRulePaginationData,
    isSearchingCpty,
    cptyOptions,
    newTradeType,
    newMarket,
    newStorage,
    storageOptions,
    newStartDate,
    newChangeAttribute,
    newChangeFrom,
    newChangeTo,
    buySellOption,
    marketSearchOptions,
    isSearchingMarket,
    showRadioButton,
    radioChecked,
    tradeTypeOptions,
  } = stateVariables;

  // Destructure state setters
  const {
    setIsAllFieldsValidated,
    setNewCptyShortName,
    setConfigRulePaginationData,
    setIsAddNewRecord,
    setIsSearchingCpty,
    setCptyOptions,
    setNewTradeType,
    setNewMarket,
    setNewStorage,
    setNewStartDate,
    setNewEndDate,
    setNewChangeAttribute,
    setNewChangeFrom,
    setNewChangeTo,
    setMarketSearchOptions,
    setIsSearchingMarket,
    setIsShowMessage,
    setIsValAndSaveBtnClickedWithoutErrors,
    setIsValidationError,
    setRadioChecked,
  } = setStateVariables;

  const colObj: IColumnRender[] = [];
  const d = new Date();
  const threeYearBack = d.getFullYear() - THREE;
  d.setFullYear(threeYearBack);

  const stateVars = {
    isAllFieldsValidated,
    isAddNewRecord,
    newCptyShortName,
    configRulePaginationData,
    isSearchingCpty,
    cptyOptions,
    tradeTypeOptions,
    newTradeType,
    newMarket,
    newStorage,
    storageOptions,
    newStartDate,
    newChangeAttribute,
    newChangeFrom,
    newChangeTo,
    buySellOption,
    marketSearchOptions,
    isSearchingMarket,
    showRadioButton,
    radioChecked,
  };
  const setStateVars = {
    setNewCptyShortName,
    setConfigRulePaginationData,
    setIsAddNewRecord,
    setIsSearchingCpty,
    setCptyOptions,
    setNewTradeType,
    setNewMarket,
    setNewStorage,
    setNewStartDate,
    setNewEndDate,
    setNewChangeAttribute,
    setNewChangeFrom,
    setNewChangeTo,
    setMarketSearchOptions,
    setIsSearchingMarket,
    setIsShowMessage,
    setIsValAndSaveBtnClickedWithoutErrors,
    setIsValidationError,
    setRadioChecked,
    setIsAllFieldsValidated,
  };

  cols.forEach((val: string) => {
    if (isAddNewRecord) {
      setCellsForNewRecord(val, colObj, stateVars, setStateVars, state);
    } else {
      defaultRenderColumns(colObj, val, stateVars, setStateVars);
    }
  });
  return colObj;
};

export const setCellsForNewRecord = (
  val: string,
  colObj: IColumnRender[],
  stateVars: IStateVars,
  setStateVars: ISetStateVars,
  state: IEditFeatureData,
) => {
  const isColRuleConfigId = areStringsEqual(val, RULE_CONFIG_ID);
  const isColCptyShortName = areStringsEqual(val, COUNTERPARTY_SHORT_NAME);
  const isColTradeType = areStringsEqual(val, TRADE_TYPE);
  const isColMarket = areStringsEqual(val, MARKET);
  const isColStorage = areStringsEqual(val, STORAGE);
  const isColStartDate = areStringsEqual(val, START_DATE);
  const isColEndDate = areStringsEqual(val, END_DATE);
  const isColChangeAttribute = areStringsEqual(val, CHANGE_ATTRIBUTE);
  const isColChangeFrom = areStringsEqual(val, CHANGE_FROM);
  const isColChangeTo = areStringsEqual(val, CHANGE_TO);
  const isColCreatedByOrUpdatedBy =
    areStringsEqual(val, 'CREATED_BY') || areStringsEqual(val, 'UPDATED_BY');
  const isColInsertedDate = areStringsEqual(val, 'INSERTED_DATE');
  const isColLastModifiedDate = areStringsEqual(val, 'LAST_MODIFIED_DATE');

  switch (true) {
    case isColRuleConfigId:
      setCellForRuleConfigIdInNewRecord(val, colObj, setStateVars, stateVars);
      break;
    case isColCptyShortName:
      setCellForCptyShortNameInNewRecord(val, colObj, setStateVars, stateVars);
      break;
    case isColTradeType:
      setCellForTradeTypeInNewRecord(val, colObj, setStateVars, stateVars);
      break;
    case isColMarket:
      setCellForMarketInNewRecord(val, colObj, setStateVars, stateVars);
      break;
    case isColStorage:
      setCellForStorageInNewRecord(val, colObj, setStateVars, stateVars);
      break;
    case isColStartDate:
      setCellForStartDateInNewRecord(val, colObj, setStateVars, stateVars);
      break;
    case isColEndDate:
      setCellForEndDateInNewRecord(val, colObj, setStateVars, stateVars);
      break;
    case isColChangeAttribute:
      setCellForChangeAttributeInNewRecord(val, colObj, stateVars, setStateVars);
      break;
    case isColChangeFrom:
      setCellForChangeFrom(val, colObj, setStateVars, stateVars);
      break;
    case isColChangeTo:
      setCellForChangeToInNewRecord(val, colObj, setStateVars, stateVars);
      break;
    case isColCreatedByOrUpdatedBy:
      setCellForCreatedByOrUpdatedByInNewRecord(val, colObj, state);
      break;
    case isColInsertedDate:
      setCellForInsertedDateInNewRecord(val, colObj);
      break;
    case isColLastModifiedDate:
      setCellForLastModifiedDateInNewRecord(val, colObj);
      break;
    default:
      defaultRenderColumns(colObj, val, stateVars, setStateVars);
      break;
  }
};

// end date can be any date after the start date and future dates are enabled to choose.
export const disabledFutureAndPrevDatesForEndDate = (current: Dayjs, stateVars: IStateVars) => {
  return current ? current.isBefore(stateVars.newStartDate, 'day') : false;
};

export const handleCancelNewRecord = (setStateVars: ISetStateVars, stateVars: IStateVars) => {
  const {
    setConfigRulePaginationData,
    setIsAddNewRecord,
    setIsValAndSaveBtnClickedWithoutErrors,
    setNewCptyShortName,
    setNewTradeType,
    setNewMarket,
    setNewChangeAttribute,
    setNewChangeFrom,
    setNewChangeTo,
    setIsAllFieldsValidated,
    setIsValidationError,
  } = setStateVars;
  const data = [...stateVars.configRulePaginationData];
  data.shift();
  setConfigRulePaginationData?.(data);
  setIsAddNewRecord?.(false);
  setIsValAndSaveBtnClickedWithoutErrors?.(false);
  setNewCptyShortName?.('');
  setNewTradeType?.('');
  setNewMarket?.('');
  setNewChangeAttribute?.('');
  setNewChangeFrom?.('');
  setNewChangeTo?.('');
  setIsAllFieldsValidated?.(false);
  setIsValidationError?.(false);
};

export const getStatusVal = (status: string) => {
  // Earlier backend was sending 0,1,-1. But on the day of go live it was changed to direct message. Hence returning the status as it is.
  if (status === '0' || status === null) {
    return 'Rule Initiated';
  }
  if (status === '1') {
    return 'Rule applied successfully';
  }
  if (status === '-1') {
    return 'Rule unsuccessful';
  } else {
    return status;
  }
};

export const getTradeVal = (trade: string) => {
  if (trade === 'PW') {
    return 'Power';
  } else if (trade === 'NG') {
    return 'Natural Gas';
  } else {
    return trade;
  }
};

export const handleOnRadioChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  setStateVars: ISetStateVars,
) => {
  setStateVars.setRadioChecked?.(Number(e.target.value));
};

export const defaultRenderColumns = (
  colObjFunction: IColumnRender[],
  val: string,
  stateVars: IStateVars,
  setStateVars: ISetStateVars,
) => {
  let width = 100;
  if (
    areStringsEqual(val, RULE_CONFIG_ID) ||
    areStringsEqual(val, TRADE_TYPE) ||
    areStringsEqual(val, MARKET) ||
    areStringsEqual(val, STATUS)
  ) {
    width = EIGHTY;
  }
  if (stateVars.showRadioButton && colObjFunction.length === 0) {
    colObjFunction.push({
      key: val,
      width: 60,
      className: 'reportPublishTableCols',
      title: (
        <div>
          <RadioButtonSelected width={20} />
        </div>
      ),
      render: (_value: string, row: TConfigRow): ReactElement => (
        <RadioButton
          name='T1'
          data-testid='radioButton'
          checked={stateVars.radioChecked === row[RULE_CONFIG_ID]}
          onChange={(e) => handleOnRadioChange(e, setStateVars)}
          value={row[RULE_CONFIG_ID]}
        />
      ),
    });
  }
  colObjFunction.push({
    dataIndex: val,
    key: val,
    width,
    title: <div>{decorateColName(val)}</div>,
    className: 'reportPublishTableCols',
    render: (value: string): ReactElement => {
      let element;
      if (areStringsEqual(val, STATUS)) {
        element = <>{getStatusVal(value)}</>;
      } else if (areStringsEqual(val, TRADE_TYPE)) {
        element = <>{getTradeVal(value)}</>;
      } else {
        element = <>{value}</>;
      }
      return element;
    },
  });
};

export const onCptySearch = debounce(async (query: string, setStateVars) => {
  const options = {
    tableName: I_ALIGNE_TABLE,
    keyword: query.toUpperCase(),
  };
  if (query.length > 1) {
    try {
      setStateVars.setIsSearchingCpty?.(true);
      const cptyDescSearch = await fetchCptySearchResult(options);
      const cptySearchResult = cptyDescSearch.data;
      setStateVars.setCptyOptions?.(cptySearchResult);
    } catch (e) {
      //error statement
    }
    setStateVars.setIsSearchingCpty?.(false);
  }
}, THOUSAND);

export const resetMarketSearchResult = (
  marketSearchResult: string[],
  columnName: string,
  stateVars: IStateVars,
) => {
  //remove existing market value from the option list
  if (columnName !== undefined && areStringsEqual(columnName, CHANGE_TO)) {
    marketSearchResult.splice(marketSearchResult.indexOf(stateVars.newChangeFrom), 1);
  }
};

export const onMarketSearch = debounce(
  async (query: string, columnName: string, stateVars: IStateVars, setStateVars: ISetStateVars) => {
    const options = {
      tableName: I_ALIGNE_TABLE,
      keyword: query.toUpperCase(),
    };
    if (query.length > 1) {
      try {
        setStateVars.setIsSearchingMarket?.(true);
        const marketSearch = await fetchMarketSearchResult(options);
        const marketSearchResult = marketSearch.data;
        resetMarketSearchResult(marketSearchResult, columnName, stateVars);
        setStateVars.setMarketSearchOptions?.(marketSearchResult);
      } catch (e) {
        //error statement
      }
      setStateVars.setIsSearchingMarket?.(false);
    }
  },
  THOUSAND,
);

export const handleChangeAttribute = async (e: string, setStateVars: ISetStateVars) => {
  setStateVars.setNewChangeAttribute?.(e);
  setStateVars.setNewChangeFrom?.('');
  setStateVars.setNewChangeTo?.('');
};

export const setChangeFromOptions = (stateVars: IStateVars) => {
  const currMarketVal = stateVars.newMarket;
  let finalOptions: OptionI[] = [];
  if (areStringsEqual(stateVars.newChangeAttribute, BUY_SLASH_SELL)) {
    finalOptions = [...stateVars.buySellOption];
  }
  if (stateVars.newChangeAttribute === MARKET) {
    finalOptions = [{ value: currMarketVal, label: currMarketVal }];
  }
  return finalOptions;
};

// setChangeToOptions function is called when BUY_SLASH_SELL is selected under Change Attribute Column
export const setChangeToOptions = (stateVars: IStateVars) => {
  const currentBuySellOption = [...stateVars.buySellOption];
  let option;
  if (stateVars.newChangeFrom === stateVars.buySellOption[0].value) {
    option = currentBuySellOption.slice(1);
  }
  if (stateVars.newChangeFrom === stateVars.buySellOption[1].value) {
    option = currentBuySellOption.slice(0, 1);
  }
  return option;
};

export const setChangeFromValueOnEdit = (stateVars: IStateVars, setStateVars: ISetStateVars) => {
  if (stateVars.newChangeAttribute === MARKET) {
    setStateVars.setNewChangeFrom?.(setChangeFromOptions(stateVars)[0].value);
    return setChangeFromOptions(stateVars)[0].value; // set default market value to the value of Market column
  } else {
    setStateVars.setNewChangeFrom?.(stateVars.newChangeFrom);
    return stateVars.newChangeFrom;
  }
};

export const setCellForLastModifiedDateInNewRecord = (val: string, colObj: IColumnRender[]) => {
  colObj.push({
    dataIndex: val,
    key: val,
    title: <div>{decorateColName(val)}</div>,
    className: 'reportPublishTableCols',
    render: (value: string, row: TConfigRow): ReactElement => {
      return row[RULE_CONFIG_ID] === -1 ? <>{formatDate(new Date())}</> : <>{value}</>;
    },
  });
};

export const setCellForRuleConfigIdInNewRecord = (
  val: string,
  colObj: IColumnRender[],
  setStateVars: ISetStateVars,
  stateVars: IStateVars,
) => {
  colObj.push({
    dataIndex: val,
    key: val,
    width: 90,
    title: <div>{decorateColName(val)}</div>,
    className: 'reportPublishTableCols',
    render: (value: string, row: TConfigRow): ReactElement => {
      return row[RULE_CONFIG_ID] === -1 ? (
        <Cross
          onClick={() => handleCancelNewRecord(setStateVars, stateVars)}
          style={{ height: 30 }}
        />
      ) : (
        <>{value}</>
      );
    },
  });
};

export const setCellForCptyShortNameInNewRecord = (
  val: string,
  colObj: IColumnRender[],
  setStateVars: ISetStateVars,
  stateVars: IStateVars,
) => {
  colObj.push({
    dataIndex: val,
    key: val,
    width: 180,
    title: <div>{decorateColName(val)}</div>,
    className: 'reportPublishTableCols',
    render: (value: string, row: TConfigRow): ReactElement => {
      return row[RULE_CONFIG_ID] === -1 ? (
        <Select
          size='medium'
          allowClear={false}
          placeholder='Enter min 2 characters'
          value={stateVars.newCptyShortName}
          onSearch={(query: string) => onCptySearch(query, setStateVars)}
          id='counterPartyId'
          disabled={stateVars.isAllFieldsValidated}
          loading={stateVars.isSearchingCpty}
          onChange={(e: string) => {
            setStateVars.setNewCptyShortName?.(e);
          }}
          autoClearSearchValue={false}
          onMouseEnter={() => setStateVars.setIsShowMessage?.(true)}
          onMouseLeave={() => setStateVars.setIsShowMessage?.(false)}
        >
          {stateVars.cptyOptions.map((cptyOptionval: string) => {
            return (
              <Option key={cptyOptionval} value={cptyOptionval}>
                {cptyOptionval}
              </Option>
            );
          })}
        </Select>
      ) : (
        <>{value}</>
      );
    },
  });
};

export const setCellForTradeTypeInNewRecord = (
  val: string,
  colObj: IColumnRender[],
  setStateVars: ISetStateVars,
  stateVars: IStateVars,
) => {
  colObj.push({
    dataIndex: val,
    key: val,
    width: 150,
    title: <div>{decorateColName(val)}</div>,
    className: 'reportPublishTableCols',
    render: (value: string, row: TConfigRow): ReactElement => {
      return row[RULE_CONFIG_ID] === -1 ? (
        <Select
          value={stateVars.newTradeType}
          size='medium'
          aria-label='Select'
          allowClear={false}
          options={stateVars.tradeTypeOptions}
          disabled={stateVars.isAllFieldsValidated}
          onChange={(e: string) => setStateVars.setNewTradeType?.(e)}
        />
      ) : (
        <>{getTradeVal(value)}</>
      );
    },
  });
};

export const setCellForMarketInNewRecord = (
  val: string,
  colObj: IColumnRender[],
  setStateVars: ISetStateVars,
  stateVars: IStateVars,
) => {
  const {
    setIsShowMessage,
    setNewMarket,
    setNewChangeFrom,
    setNewChangeTo,
    setNewChangeAttribute,
  } = setStateVars;
  const { newMarket, isSearchingMarket, isAllFieldsValidated, marketSearchOptions } = stateVars;
  colObj.push({
    dataIndex: val,
    key: val,
    width: 160,
    title: <div>{decorateColName(val)}</div>,
    className: 'reportPublishTableCols',
    render: (value: string, row: TConfigRow): ReactElement => {
      return row[RULE_CONFIG_ID] === -1 ? (
        <Select
          size='medium'
          allowClear={false}
          placeholder='Enter min 2 characters'
          value={newMarket}
          onSearch={(query: string) => onMarketSearch(query, val, stateVars, setStateVars)}
          loading={isSearchingMarket}
          disabled={isAllFieldsValidated}
          onMouseEnter={() => setIsShowMessage?.(true)}
          onMouseLeave={() => setIsShowMessage?.(false)}
          onChange={(e: string) => {
            setNewMarket?.(e);
            setNewChangeFrom?.('');
            setNewChangeTo?.('');
            setNewChangeAttribute?.('');
          }}
          autoClearSearchValue={false}
        >
          {marketSearchOptions.map((marketOptionVal: string) => {
            return (
              <Option key={marketOptionVal} value={marketOptionVal}>
                {marketOptionVal}
              </Option>
            );
          })}
        </Select>
      ) : (
        <>{value}</>
      );
    },
  });
};

export const setCellForStorageInNewRecord = (
  valStorage: string,
  colObj: IColumnRender[],
  setStateVars: ISetStateVars,
  stateVars: IStateVars,
) => {
  colObj.push({
    dataIndex: valStorage,
    key: valStorage,
    width: 150,
    title: <div>{decorateColName(valStorage)}</div>,
    className: 'reportPublishTableCols',
    render: (value: string, row: TConfigRow): ReactElement => {
      return row[RULE_CONFIG_ID] === -1 ? (
        <Select
          value={stateVars.newStorage}
          size='medium'
          aria-label='Select'
          allowClear={false}
          options={stateVars.storageOptions}
          disabled={stateVars.isAllFieldsValidated}
          onChange={(e: string) => setStateVars.setNewStorage?.(e)}
        />
      ) : (
        <>{value}</>
      );
    },
  });
};

export const setCellForStartDateInNewRecord = (
  val: string,
  colObj: IColumnRender[],
  setStateVars: ISetStateVars,
  stateVars: IStateVars,
) => {
  // start date can be upto 3 years back, only 1st of every month can be selected and future dates disabled
  const disabledFutureDatesForStartDate = (current: Dayjs) => {
    const maxDate = dayjs().subtract(THREE, 'year').startOf('month');
    return (
      current &&
      (!current.startOf('month').isSame(current, 'date') ||
        current.isBefore(maxDate, 'date') ||
        current.isAfter(dayjs().endOf('month')))
    );
  };
  colObj.push({
    dataIndex: val,
    key: val,
    width: 150,
    title: <div>{decorateColName(val)}</div>,
    className: 'reportPublishTableCols',
    render: (value: string, row: TConfigRow): ReactElement => {
      return row[RULE_CONFIG_ID] === -1 ? (
        <SingleDatePicker
          placeholder='DD MMM YYYY'
          disabledDate={disabledFutureDatesForStartDate}
          disabled={stateVars.isAllFieldsValidated}
          onChange={(date: Dayjs | null) => {
            let dateStr = '';
            if (date) {
              // Type assertion to match the expected type
              const event = date as unknown as { $d: Date };
              dateStr = formatDate(event.$d);
            }

            const parts = dateStr.split('/');
            const formattedDate = `${parts[1]}/${parts[0]}/${parts[2]}`;
            setStateVars.setNewStartDate?.(formattedDate);
          }}
          elevation={true}
        />
      ) : (
        <>{value}</>
      );
    },
  });
};

export const setCellForEndDateInNewRecord = (
  val: string,
  colObj: IColumnRender[],
  setStateVars: ISetStateVars,
  stateVars: IStateVars,
) => {
  colObj.push({
    dataIndex: val,
    key: val,
    width: 150,
    title: <div>{decorateColName(val)}</div>,
    className: 'reportPublishTableCols',
    render: (value: string, row: TConfigRow): ReactElement => {
      return row[RULE_CONFIG_ID] === -1 ? (
        <SingleDatePicker
          placeholder='DD MMM YYYY'
          disabledDate={(current: Dayjs) =>
            disabledFutureAndPrevDatesForEndDate(current, stateVars)
          }
          disabled={stateVars.isAllFieldsValidated}
          onChange={(date: Dayjs | null) => {
            if (date) {
              // Type assertion to match the expected type
              const event = date as unknown as { $d: Date };
              setStateVars.setNewEndDate?.(formatDate(event.$d));
            }
          }}
          elevation={true}
        />
      ) : (
        <>{value}</>
      );
    },
  });
};

export const setCellForChangeAttributeInNewRecord = (
  val: string,
  colObj: IColumnRender[],
  stateVars: IStateVars,
  setStateVars: ISetStateVars,
) => {
  colObj.push({
    dataIndex: val,
    key: val,
    title: <div>{decorateColName(val)}</div>,
    className: 'reportPublishTableCols',
    render: (value: string, row: TConfigRow): ReactElement => {
      return row[RULE_CONFIG_ID] === -1 ? (
        <Select
          value={stateVars.newChangeAttribute}
          defaultValue={tableEdiOptionI.configRuleChangeAttributeOptions[0].value}
          size='medium'
          aria-label='Select'
          allowClear={false}
          options={tableEdiOptionI.configRuleChangeAttributeOptions}
          onChange={(e: string) => handleChangeAttribute(e, setStateVars)}
          disabled={stateVars.newMarket === '' || stateVars.isAllFieldsValidated} //enable the field after user picks up a value for Market column
        />
      ) : (
        <>{value}</>
      );
    },
  });
};

export const setCellForChangeFrom = (
  val: string,
  colObj: IColumnRender[],
  setStateVars: ISetStateVars,
  stateVars: IStateVars,
) => {
  colObj.push({
    dataIndex: val,
    key: val,

    title: <div>{decorateColName(val)}</div>,
    className: 'reportPublishTableCols',
    render: (value: string, row: TConfigRow): ReactElement => {
      return row[RULE_CONFIG_ID] === -1 ? (
        <Select
          value={setChangeFromValueOnEdit(stateVars, setStateVars)}
          size='medium'
          aria-label='Select'
          allowClear={false}
          options={setChangeFromOptions(stateVars)}
          onChange={(e: string) => {
            setStateVars.setNewChangeFrom?.(e);
            setStateVars.setNewChangeTo?.('');
          }}
          disabled={
            stateVars.newChangeAttribute === '' ||
            stateVars.newChangeAttribute === MARKET ||
            stateVars.isAllFieldsValidated
          }
        />
      ) : (
        <>{value}</>
      );
    },
  });
};

export const setCellForChangeToInNewRecord = (
  val: string,
  colObj: IColumnRender[],
  setStateVars: ISetStateVars,
  stateVars: IStateVars,
) => {
  const { setIsShowMessage, setNewChangeTo } = setStateVars;
  const {
    newChangeTo,
    isSearchingMarket,
    newChangeAttribute,
    isAllFieldsValidated,
    marketSearchOptions,
    buySellOption,
    newChangeFrom,
  } = stateVars;
  if (newChangeAttribute === MARKET) {
    // implement searching for market
    colObj.push({
      dataIndex: val,
      key: val,
      width: 130,
      title: <div>{decorateColName(val)}</div>,
      className: 'reportPublishTableCols',
      render: (value: string, row: TConfigRow): ReactElement => {
        return row[RULE_CONFIG_ID] === -1 ? (
          <Select
            value={newChangeTo}
            size='small'
            aria-label='Select'
            allowClear={false}
            onSearch={(query: string) => onMarketSearch(query, val, stateVars, setStateVars)}
            loading={isSearchingMarket}
            onMouseEnter={() => setIsShowMessage?.(true)}
            onMouseLeave={() => setIsShowMessage?.(false)}
            onChange={(e: string) => setNewChangeTo?.(e)}
            disabled={
              isAllFieldsValidated ||
              (areStringsEqual(newChangeAttribute, BUY_SLASH_SELL) &&
                Object.values(buySellOption).includes(changeAttributeMasterValue))
            }
          >
            {marketSearchOptions.map((marketOptionVal: string) => {
              return (
                <Option key={marketOptionVal} value={marketOptionVal}>
                  {marketOptionVal}
                </Option>
              );
            })}
          </Select>
        ) : (
          <>{value}</>
        );
      },
    });
  } else {
    colObj.push({
      dataIndex: val,
      key: val,
      title: <div>{decorateColName(val)}</div>,
      className: 'reportPublishTableCols',
      render: (value: string, row: TConfigRow): ReactElement => {
        return row[RULE_CONFIG_ID] === -1 ? (
          <Select
            value={newChangeTo}
            size='medium'
            aria-label='Select'
            allowClear={false}
            options={setChangeToOptions(stateVars)}
            onChange={(e: string) => setNewChangeTo?.(e)}
            disabled={newChangeFrom === '' || isAllFieldsValidated}
          />
        ) : (
          <>{value}</>
        );
      },
    });
  }
};

export const setCellForCreatedByOrUpdatedByInNewRecord = (
  val: string,
  colObj: IColumnRender[],
  state: IEditFeatureData,
) => {
  colObj.push({
    dataIndex: val,
    key: val,
    title: <div>{decorateColName(val)}</div>,
    className: 'reportPublishTableCols',
    render: (value: string, row: TConfigRow): ReactElement => {
      return row[RULE_CONFIG_ID] === -1 ? <>{state.mail}</> : <>{value}</>;
    },
  });
};

export const setCellForInsertedDateInNewRecord = (cellVal: string, colObj: IColumnRender[]) => {
  colObj.push({
    dataIndex: cellVal,
    key: cellVal,
    title: <div>{decorateColName(cellVal)}</div>,
    className: 'reportPublishTableCols',
    render: (value: string, row: TConfigRow): ReactElement => {
      return row[RULE_CONFIG_ID] === -1 ? <>{formatDate(new Date())}</> : <>{value}</>;
    },
  });
};
