import { Grid, Flexbox, Pecten, Heading, Button } from '@sede-x/shell-ds-react-framework';
import {
  GridContainer,
  LoginContainer,
  BackgroundVideo,
  LoginGrid,
  Login,
} from '../AppContent.styles';

export const LoginPage = (props: { login: () => void }) => {
  return (
    <LoginContainer data-testid='login-page'>
      <BackgroundVideo autoPlay loop muted>
        <source src='/pulsating-pecten.mp4' type='video/mp4' />
      </BackgroundVideo>
      <LoginGrid justifyContent='center' alignItems='center'>
        <Login>
          <Grid gap='24px' areas={['blank1', 'login', 'blank2']}>
            <Grid.Cell area='blank1' />
            <Grid.Cell area='login'>
              <Flexbox justifyContent='center'>
                <GridContainer>
                  <Grid
                    gap='24px'
                    areas={['spinner', 'pecten', 'signonText', 'authText', 'button']}
                  >
                    <Grid.Cell area='pecten'>
                      <Flexbox justifyContent='center'>
                        <Pecten size='medium' />
                      </Flexbox>
                    </Grid.Cell>
                    <Grid.Cell area='signonText'>
                      <Flexbox justifyContent='center'>
                        <Heading type='h1'>
                          Single sign-on to <strong>Carbon Dashboard</strong>
                        </Heading>
                      </Flexbox>
                    </Grid.Cell>
                    <Grid.Cell area='authText'>
                      <Flexbox justifyContent='center'>
                        <p>
                          Authenticate your account by logging into Shell&apos;s single sign-on
                          provider.
                        </p>
                      </Flexbox>
                    </Grid.Cell>
                    <Grid.Cell area='button'>
                      <Flexbox justifyContent='center'>
                        <Button onClick={props.login} size='medium'>
                          Continue
                        </Button>
                      </Flexbox>
                    </Grid.Cell>
                  </Grid>
                </GridContainer>
              </Flexbox>
            </Grid.Cell>
            <Grid.Cell area='blank2' />
          </Grid>
        </Login>
      </LoginGrid>
    </LoginContainer>
  );
};
