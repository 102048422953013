import { IMenuItem } from '../../Tanso/Types/types';

export const getActiveKey = (menuItems: IMenuItem[], pathname: string) => {
  const flatMenuItems = menuItems.reduce((acc: IMenuItem[], item: IMenuItem) => {
    acc.push(item);
    if (item.children) {
      acc.push(
        ...item.children.reduce((childAcc: IMenuItem[], childItem: IMenuItem) => {
          childAcc.push(childItem);
          return childAcc;
        }, []),
      );
    }
    return acc;
  }, []);

  return flatMenuItems.find((item) => pathname.startsWith(item.path as string))?.key ?? '0';
};
