import {
  getAnalyticsSnapshotVolumeByRegionChartData,
  getAnalyticsSnapshotVolumeByRegionTableData,
} from '../../../../../api/services';
import {
  IAnalyticsSnapshotReportType,
  ParameterQuantity,
} from '../../../../../Types/IAnalyticsSnapshotReportType';

const initialColors = {
  colorRed: '#ff887b',
  colorYellow: '#FBCF09',
  colorGreen: '#6FB993',
};

const greyOutColors = {
  colorRed: '#ffc3bd',
  colorYellow: '#fde683',
  colorGreen: '#b7dcc9',
};

export const WHOLESALE_CHURN_PARAMETER_NAME = 'Wholesale Churn';
export const OUT_OF_SCOPE_VOLUME_PARAMETER_NAME = 'out of scope volume';

export const generateSeries = (responseData: ParameterQuantity[], selectedParameter: string) => {
  return [
    {
      name: 'Volume',
      data: generateSeriesData(responseData, selectedParameter),
      dataLabels: {
        enabled: true,
        format: '{point.y:.2f}',
        verticalAlign: 'top',
        y: -20,
      },
      pointPadding: 0,
      borderWidth: 0,
    },
  ];
};

export const getColor = (item: ParameterQuantity, selectedParameter: string): string => {
  const isParameterSelected = !selectedParameter || item.PARAMETER_NAME === selectedParameter;
  const isParameterSelectedForChurn =
    WHOLESALE_CHURN_PARAMETER_NAME === selectedParameter &&
    (!selectedParameter ||
      item.PARAMETER_NAME?.toLowerCase() === OUT_OF_SCOPE_VOLUME_PARAMETER_NAME);

  if (item.QTY === undefined) {
    return isParameterSelected ? initialColors.colorRed : greyOutColors.colorRed;
  }

  if (item.QTY > 0) {
    const colorName = isParameterSelectedForChurn
      ? initialColors.colorYellow
      : greyOutColors.colorYellow;
    return isParameterSelected ? initialColors.colorYellow : colorName;
  }

  const color = isParameterSelectedForChurn ? initialColors.colorRed : greyOutColors.colorRed;
  return isParameterSelected ? initialColors.colorRed : color;
};

export const generateSeriesData = (
  responseData: ParameterQuantity[],
  selectedParameter: string,
) => {
  const specialProduct = responseData
    ?.filter((el: ParameterQuantity) => el.PARAMETER_NAME === 'NCF Volume')
    .map((el: ParameterQuantity) => ({
      name: el.PARAMETER_NAME,
      isSum: true,
      color:
        !selectedParameter || el.PARAMETER_NAME === selectedParameter
          ? initialColors.colorGreen
          : greyOutColors.colorGreen,
      y: el.QTY,
    }));

  const otherProducts = responseData
    ?.filter((el: ParameterQuantity) => el.PARAMETER_NAME !== 'NCF Volume')
    .map((el: ParameterQuantity) => ({
      name:
        el.PARAMETER_NAME?.toLowerCase() === OUT_OF_SCOPE_VOLUME_PARAMETER_NAME
          ? WHOLESALE_CHURN_PARAMETER_NAME
          : el.PARAMETER_NAME,
      y: el.QTY,
      color: getColor(el, selectedParameter),
    }));

  return [...(otherProducts || []), ...(specialProduct || [])];
};

export const getCommodityName = (abbreviation: string) => {
  switch (abbreviation) {
    case 'PW':
      return 'Power';
    case 'NG':
      return 'Natural Gas';
    default:
      return 'Power';
  }
};

export const fetchDataBasedOnView = (
  view: string,
  analyticsState: IAnalyticsSnapshotReportType,
  region: string,
) => {
  const payload = {
    ...analyticsState.analyticsSnapshotReportFilteredValues,
    region: [region],
  };
  if (view === 'Table') {
    return getAnalyticsSnapshotVolumeByRegionTableData(payload);
  }
  return getAnalyticsSnapshotVolumeByRegionChartData(payload);
};

export const generateColumns = (tableTotals: Record<string, number>) => {
  //Prepare columns for the table and add footer for the totals
  const staticColumns = ['YEAR', 'QUARTER', 'MONTH'];
  let dynamicColumns = [] as string[];
  if (tableTotals) {
    dynamicColumns = Object.keys(tableTotals);
  }

  const columnNames = [...staticColumns, ...dynamicColumns];

  return columnNames?.map((key) => ({
    header: key,
    accessorKey: key,
    footer: () => {
      if (staticColumns.includes(key)) {
        return key === 'YEAR' ? 'Total' : '';
      }
      return tableTotals[key] || 0;
    },
  }));
};
