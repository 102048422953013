// useFileUploadEffect.js
import { useEffect } from 'react';
import { INVALID } from '../../../api/constants';

export const useFileUploadEffect = (
  fileId: string,
  uploadSuccess: boolean,
  fileUploadState: string,
) => {
  useEffect(() => {
    const fileElement = document.getElementById(fileId) as HTMLElement;
    const fileUploadingTextElement = fileElement.firstChild?.childNodes[1] as HTMLElement;
    const fileProgressBarElement = fileElement.firstChild?.childNodes[0] as HTMLElement;
    if (fileProgressBarElement && (uploadSuccess || fileUploadState === INVALID)) {
      fileUploadingTextElement.style.display = 'none';
      fileProgressBarElement.style.display = 'none';
    } else {
      const fileDragDropTextElement = fileElement.firstChild?.childNodes[1] as HTMLElement;
      fileDragDropTextElement.style.display = '';
    }
  }, [fileId, fileUploadState, uploadSuccess]);
};
