import { NCF_VOLUME } from '../../../../../../api/constants';
import {
  getVolumeByRegionTableData,
  getVolumeByRegionChartData,
} from '../../../../../../api/services';

const initialColors = {
  colorRed: '#ff887b',
  colorYellow: '#FBCF09',
  colorGreen: '#6FB993',
};

const greyOutColors = {
  colorRed: '#ffc3bd',
  colorYellow: '#fde683',
  colorGreen: '#b7dcc9',
};

export const WHOLESALE_CHURN_PARAMETER_NAME = 'Wholesale Churn';
export const OUT_OF_SCOPE_VOLUME_PARAMETER_NAME = 'out of scope volume';

export interface MapDataItem {
  PARAMETER_NAME: string;
  QTY?: number;
}

export interface IRegionData {
  PARAMETER_NAME: string;
  QTY?: number;
  isSum?: boolean;
  color?: string;
}

export interface ISeriesData {
  name: string;
  y?: number;
}

export interface IRegionQuarterData extends IRegionData {
  QUARTER: string;
  YEAR: number;
}

interface FilteredValues {
  year: string[];
  quarter: string[];
  month: string[];
  region: string[];
  countryCode: string[];
  commodity: string[];
  uom: string;
  ncfType: string;
}

export interface AnalyticsState {
  filteredValues: FilteredValues;
}

interface CellInfo {
  getValue: () => number | string;
}

export const generateSeries = (responseData: IRegionData[], selectedParameter: string) => {
  return [
    {
      name: 'Volume',
      data: generateSeriesData(responseData, selectedParameter),
      dataLabels: {
        enabled: true,
        format: '{point.y:.2f}',
        verticalAlign: 'top',
        y: -20,
      },
      pointPadding: 0,
      borderWidth: 0,
    },
  ];
};

export const getColor = (item: IRegionData, selectedParameter: string): string => {
  const isParameterSelected = !selectedParameter || item.PARAMETER_NAME === selectedParameter;
  const isParameterSelectedForChurn =
    WHOLESALE_CHURN_PARAMETER_NAME === selectedParameter &&
    (!selectedParameter ||
      item.PARAMETER_NAME.toLowerCase() === OUT_OF_SCOPE_VOLUME_PARAMETER_NAME);

  if (item.QTY === undefined) {
    return isParameterSelected ? initialColors.colorRed : greyOutColors.colorRed;
  }

  if (item.QTY > 0) {
    const colorName = isParameterSelectedForChurn
      ? initialColors.colorYellow
      : greyOutColors.colorYellow;
    return isParameterSelected ? initialColors.colorYellow : colorName;
  }

  const color = isParameterSelectedForChurn ? initialColors.colorRed : greyOutColors.colorRed;
  return isParameterSelected ? initialColors.colorRed : color;
};

const generateSeriesData = (
  responseData: IRegionData[],
  selectedParameter: string,
): ISeriesData[] => {
  const specialProduct = responseData
    ?.filter((el: IRegionData) => el.PARAMETER_NAME === NCF_VOLUME)
    .map((el: IRegionData) => ({
      name: el.PARAMETER_NAME,
      color:
        !selectedParameter || el.PARAMETER_NAME === selectedParameter
          ? initialColors.colorGreen
          : greyOutColors.colorGreen,
      y: el.QTY,
      isSum: true,
    }));

  const otherProducts = responseData
    ?.filter((el: IRegionData) => el.PARAMETER_NAME !== NCF_VOLUME)
    .map((el: IRegionData) => ({
      name:
        el.PARAMETER_NAME.toLowerCase() === OUT_OF_SCOPE_VOLUME_PARAMETER_NAME
          ? WHOLESALE_CHURN_PARAMETER_NAME
          : el.PARAMETER_NAME,
      y: el.QTY,
      color: getColor(el, selectedParameter),
    }));

  return [...(otherProducts || []), ...(specialProduct || [])];
};

export const getCommodityName = (abbreviation: string) => {
  switch (abbreviation) {
    case 'PW':
      return 'Power';
    case 'NG':
      return 'Natural Gas';
    default:
      return 'Power';
  }
};

export const fetchDataBasedOnView = (
  view: string,
  analyticsState: AnalyticsState,
  region: string,
) => {
  const payload = {
    ...analyticsState.filteredValues,
    region: [region],
  };
  if (view === 'Table') {
    return getVolumeByRegionTableData(payload);
  }
  return getVolumeByRegionChartData(payload);
};

const getFooterValue = (
  key: string,
  tableTotals: Record<string, number>,
  staticColumns: string[],
) => {
  if (staticColumns.includes(key)) {
    return key === 'YEAR' ? 'Total' : '';
  }
  if (key === 'Buy/Sell') {
    return '';
  }
  return tableTotals[key] || 0;
};

const getCellValue = (key: string, info: CellInfo) => {
  if (key === 'Buy/Sell') {
    return info.getValue() === 1 ? 'BUY' : 'SELL';
  }
  return info.getValue();
};

export const generateColumns = (tableTotals: Record<string, number>) => {
  //Prepare columns for the table and add footer for the totals
  const staticColumns = ['YEAR', 'QUARTER', 'MONTH'];
  let dynamicColumns = [] as string[];
  if (tableTotals) {
    dynamicColumns = Object.keys(tableTotals);
  }

  const columnNames = [...staticColumns, ...dynamicColumns];

  return columnNames?.map((key) => ({
    header: key,
    accessorKey: key,
    footer: () => getFooterValue(key, tableTotals, staticColumns),
    cell: (info: CellInfo) => getCellValue(key, info),
  }));
};
