import { Button, Variants } from '@sede-x/shell-ds-react-framework';
import React, { Dispatch, SetStateAction } from 'react';
import {
  downloadEntireCtpyList,
  downloadUnmappedCtpyList,
  downloadSecotrWiseNcfVol,
  downloadCptyWiseNcfVol,
  getStep01Log,
} from '../../../api/services';
import { getYearValue, getLastQuarter } from './cardUtils';
import { IFileHttpResponse } from '../../../Types/types';

export const ActionButton = ({
  content,
  variants,
  buttonKey,
  setIsServerError,
  setLogInfo,
  isDisabled,
  setIsStep03DownlaodInProgress,
  setIsStep01DownlaodInProgress,
  downloadReportOptions,
}: {
  content: string;
  variants: string;
  buttonKey: string;
  setIsServerError: Dispatch<SetStateAction<boolean>>;
  setLogInfo?: Dispatch<SetStateAction<string>>;
  isDisabled?: boolean;
  setIsStep03DownlaodInProgress?: Dispatch<SetStateAction<boolean>>;
  setIsStep01DownlaodInProgress?: Dispatch<SetStateAction<boolean>>;
  downloadReportOptions?: {
    startYear: string;
    startMonth: string;
    endYear: string;
    endMonth: string;
  };
}) => {
  const { startYear, startMonth, endYear, endMonth } = downloadReportOptions ?? {
    startYear: '',
    startMonth: '',
    endYear: '',
    endMonth: '',
  };
  const handleButtonClick = async () => {
    const isDownlaodEntireCtpyList = buttonKey === 'entire';
    const isDownloadUnmappedCtpyList = buttonKey === 'unmapped';
    const isStep03DownloadReport = buttonKey === 'downloadReport';
    const setDownloadProgress =
      isDownlaodEntireCtpyList || isDownloadUnmappedCtpyList
        ? (setIsStep01DownlaodInProgress as Dispatch<SetStateAction<boolean>>)
        : (setIsStep03DownlaodInProgress as Dispatch<SetStateAction<boolean>>);
    await handleDownload(
      isDownlaodEntireCtpyList,
      isDownloadUnmappedCtpyList,
      isStep03DownloadReport,
      setDownloadProgress,
    );
    if (setLogInfo) {
      fetchUpdateInfo(setLogInfo);
    }
  };

  const handleDownload = async (
    isDownlaodEntireCtpyList: boolean,
    isDownloadUnmappedCtpyList: boolean,
    isStep03DownloadReport: boolean,
    setDownloadProgress: Dispatch<SetStateAction<boolean>>,
  ) => {
    let filename = '';
    if (isDownlaodEntireCtpyList) {
      filename = 'Entire_CPTY';
    } else if (isDownloadUnmappedCtpyList) {
      filename = 'Ctpy_Unmapped';
    } else if (isStep03DownloadReport) {
      filename = 'Output';
    }
    let ctpyListResp, sectoralNcfVolResp, cptyNcfVolResp;
    setDownloadProgress(true);
    try {
      if (isDownlaodEntireCtpyList) {
        ctpyListResp = await downloadEntireCtpyList();
      } else if (isDownloadUnmappedCtpyList) {
        const currentYear: number = getYearValue();
        const currentQtr = getLastQuarter();
        ctpyListResp = await downloadUnmappedCtpyList(currentYear, currentQtr);
      } else {
        [sectoralNcfVolResp, cptyNcfVolResp] = (await Promise.all([
          downloadSecotrWiseNcfVol(startYear, startMonth, endYear, endMonth),
          downloadCptyWiseNcfVol(startYear, startMonth, endYear, endMonth),
        ])) as unknown as [IFileHttpResponse, IFileHttpResponse];
      }

      const handleBlob = (response: IFileHttpResponse) => {
        const blob = createBlobFromResponse(response);
        initiateDownload(blob, filename);
      };
      const resp: IFileHttpResponse =
        (ctpyListResp as unknown as IFileHttpResponse) ??
        (sectoralNcfVolResp as unknown as IFileHttpResponse);
      handleBlob(resp);

      if (cptyNcfVolResp) {
        handleBlob(cptyNcfVolResp);
      }

      setIsServerError(false);
    } catch (error) {
      //console error
      setIsServerError(true);
    }
    setDownloadProgress(false);
  };

  // Helper function to create a Blob from response data
  const createBlobFromResponse = (response: IFileHttpResponse) => {
    const encoder = new TextEncoder();
    const utf8EncodedData = response?.data ? encoder.encode(response.data) : [];
    const bom0xef = 0xef;
    const bom0xbb = 0xbb;
    const bom0xbf = 0xbf;
    const bom = new Uint8Array([bom0xef, bom0xbb, bom0xbf]); // UTF-8 BOM bytes
    const blobData = new Uint8Array(bom.length + utf8EncodedData.length);
    blobData.set(bom, 0);
    blobData.set(utf8EncodedData, bom.length);
    return new Blob([blobData], { type: 'text/csv' });
  };

  // Helper function to initiate the download of a Blob
  const initiateDownload = (blob: Blob | MediaSource, filename: string) => {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  };

  const fetchUpdateInfo = async (setLog: Dispatch<SetStateAction<string>>) => {
    try {
      const step01LogResp = await getStep01Log();
      const stepLogDate = step01LogResp.data.Last_Modified_Date ?? new Date();
      const stepDateObj = new Date(stepLogDate);
      const options: Intl.DateTimeFormatOptions = {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      };
      const logDate = stepDateObj.toLocaleDateString(undefined, options);
      const logInfo = 'Updated: Last extracted on ';
      setLog(`${logInfo}${logDate}`);
      setIsServerError(false);
    } catch (e) {
      //console error statement
      setIsServerError(true);
    }
  };

  return (
    <Button
      iconPosition='left'
      data-testid='sectoralButton'
      variant={variants === 'outlined' ? Variants.Outlined : Variants.Filled}
      onClick={() => handleButtonClick()}
      className='action-button'
      disabled={isDisabled}
      style={{ fontSize: 'inherit', fontWeight: 'inherit' }}
    >
      {content}
    </Button>
  );
};
