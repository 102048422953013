import { Flexbox, Toggle } from '@sede-x/shell-ds-react-framework';
import { ImportedDeals } from '../../../../Types/types';

const AddToChart = (
  { row, getValue }: { row: { original: ImportedDeals }; getValue: () => string },
  // eslint-disable-next-line no-unused-vars
  handleToggle: (selectedItem: ImportedDeals) => void,
) => {
  const toggleLabel = Number(getValue()) === 0 ? 'No' : 'Yes';
  return (
    <Flexbox justifyContent='space-between'>
      {toggleLabel as string}
      <Toggle
        size='medium'
        data-testid='toggle'
        checked={row.original.ADD_TO_CHART === 1}
        onChange={() => handleToggle(row.original)}
      />
    </Flexbox>
  );
};

export default AddToChart;
