import styled from 'styled-components';

export const ToggleListContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: 16px;
  color: #343434;
  font-family: 'Shell Font';
`;

export const ToggleList1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px 8px 0px;
`;

export const ToggleButtonParent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

export const ToggleButton = styled.div`
  border-radius: 20px;
  background-color: rgba(0, 151, 168, 0.1);
  border: 2px solid rgba(0, 131, 148, 0.78);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ToggleButton1 = styled.div`
  border-radius: 20px;
  border: 1px solid rgba(28, 28, 28, 0.38);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const Inner = styled.div`
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
`;

export const Label2 = styled.div`
  position: relative;
  line-height: 20px;
`;

export const Note = styled.i`
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 22px;
  color: #757575;
`;