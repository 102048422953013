import { axiosInstanceCPM } from '../../commonUtils/apiAxiosInstance/axiosInstance';
import { ICarbonStatus } from '../Types/types';
const API_VERSION_1 = 'api/v1/';

const fetchSauApi = `${API_VERSION_1}sau`;
const fetchEvpApi = `${API_VERSION_1}evp/?`;
const fetchIpuApi = `${API_VERSION_1}ipu/?`;
const fetchVpApi = `${API_VERSION_1}vp/?`;
const fetchBusiness = `${API_VERSION_1}business/?`;
const fetchCarbonRagStatus = `${API_VERSION_1}carbonemissionragstatus`;
const _fetchMCRStatus = `${API_VERSION_1}margintocarbonratiostatus`;
const _fetchCommodityOverview = `${API_VERSION_1}commodityoverview`;
const _fetchCarbonemissionDeltaView = `${API_VERSION_1}carbonemissiondeltaview`;
const countryLevelAverageMCR = `${API_VERSION_1}countrylevelaveragemcr`;
const plannedVolume = `${API_VERSION_1}plannedvolume`;
const committedVolume = `${API_VERSION_1}committedvolume`;
const _fetchcenariomodelingkpisurl = `${API_VERSION_1}scenario-modeling-kpis`;
const fetchOverallportfolio = `${API_VERSION_1}overallportfolio/`;
const _fetchCarbonBudgetLine = `${API_VERSION_1}carbonbudgetline`;
const _fetchMarginToCarbonRatio = `${API_VERSION_1}margintocarbonratio/`;
const _fetchAvgmMarginToCarbonRatio = `${API_VERSION_1}avgmargintocarbonratio/`;
const fetchOverallCarbonStatus = `${API_VERSION_1}overallcarbonstatus/`;
const saveOverAllCarbonStatus = `${API_VERSION_1}saveoverallcarbonstatus/`;
const _fatchCarbonIntensityTarget = `${API_VERSION_1}carbonintensitytarget/`;
const fetchBaseCaseOverAllPortfolio = `${API_VERSION_1}basecase-overall-portfolio`;
const getKpiMovedDeals = `${API_VERSION_1}kpi-moved-deals`;
const netCarbonIntensity = `${API_VERSION_1}netcarbonintensity`;
const importedDeals = `${API_VERSION_1}imported-deals`;

//API used in UC1 Scenario modelling
export const overAllPortfolio = (region: string, evp: string) => {
  const url = `${fetchOverallportfolio}REGION=${region}&EVP=${evp}`;
  return axiosInstanceCPM.get(url);
};

export const fetchCarbonBudgetLine = (region: string, evp: string) => {
  const url = `${_fetchCarbonBudgetLine}?REGION=${region}&EVP=${evp}`;
  return axiosInstanceCPM.get(url);
};

export const fetchMarginToCarbonRatio = (region: string, evp: string) => {
  const url = `${_fetchMarginToCarbonRatio}?REGION=${region}&EVP=${evp}`;
  return axiosInstanceCPM.get(url);
};

export const fetchAvgMarginToCarbonRatio = (region: string, evp: string) => {
  const url = `${_fetchAvgmMarginToCarbonRatio}?REGION=${region}&EVP=${evp}`;
  return axiosInstanceCPM.get(url);
};

export const fetchIPUFilterData = (url: string) => {
  url = `${url}`;
  return axiosInstanceCPM.get(url);
};

export const fetchevpFiltered = (url: string) => {
  url = `${url}`;
  return axiosInstanceCPM.get(url);
};

export const fetchSeIpuRegionFiltered = (url: string) => {
  url = `${url}`;
  return axiosInstanceCPM.get(url);
};

export const fetchVPFilterData = (url: string) => {
  url = `${url}`;
  return axiosInstanceCPM.get(url);
};

export const fetchBusinessFilterData = (url: string) => {
  url = `${url}`;
  return axiosInstanceCPM.get(url);
};

export const fetchSauFilterData = (region?: string, evp?: string) => {
  const url = `${fetchSauApi}?REGION=${region}&EVP=${evp}`;
  return axiosInstanceCPM.get(url);
};

export const fetchEVP = (region: string) => {
  const url = `${fetchEvpApi}region=${region}`;
  return axiosInstanceCPM.get(url);
};

export const fetchIPU = (region: string, evp: string) => {
  const url = `${fetchIpuApi}region=${region}&evp=` + encodeURIComponent(evp);
  return axiosInstanceCPM.get(url);
};

export const fetchVP = (region: string, evp: string, ipu: string) => {
  const url = `${fetchVpApi}region=${region}&evp=
  ${encodeURIComponent(evp)}&ipu=${encodeURIComponent(ipu)}`;
  return axiosInstanceCPM.get(url);
};

export const fetchBUsiness = (region: string, evp: string, ipu: string, vp: string) => {
  const url = `${fetchBusiness}region=${region}&evp=
  ${encodeURIComponent(evp)}&ipu=${encodeURIComponent(ipu)}&vp=${encodeURIComponent(vp)}`;
  return axiosInstanceCPM.get(url);
};

export const fetchRagStatus = (region: string, evp: string) => {
  const url = `${fetchCarbonRagStatus}?REGION=${region}&EVP=${evp}`;
  return axiosInstanceCPM.get(url);
};

export const fetchMCRStatus = (region: string, evp: string) => {
  const url = `${_fetchMCRStatus}?REGION=${region}&EVP=${evp}`;
  return axiosInstanceCPM.get(url);
};

export const fetchCommodityOverview = (region: string, evp: string) => {
  const url = `${_fetchCommodityOverview}?REGION=${region}&EVP=${evp}`;
  return axiosInstanceCPM.get(url);
};

export const fetchCarbonemissionDeltaView = (region: string, evp: string) => {
  const url = `${_fetchCarbonemissionDeltaView}?REGION=${region}&EVP=${evp}`;
  return axiosInstanceCPM.get(url);
};

export const overallCarbonStatus = (region: string, evp: string) => {
  const url = `${fetchOverallCarbonStatus}?REGION=${region}&EVP=${evp}`;
  return axiosInstanceCPM.get(url);
};

export const saveCarbonStatus = (options: ICarbonStatus) => {
  return axiosInstanceCPM.post(saveOverAllCarbonStatus, options);
};

export const fetchEVPCommodityOverview = (sau: string) => {
  const url = `${_fetchCommodityOverview}?sau=${encodeURIComponent(sau)}`;
  return axiosInstanceCPM.get(url);
};

export const fetchSeIPURegionCommodityOverview = (sau: string, evp: string) => {
  const url = `${_fetchCommodityOverview}?sau=${encodeURIComponent(sau)}
  &evp=${encodeURIComponent(evp)}`;
  return axiosInstanceCPM.get(url);
};

export const fetchIPUCommodityOverview = (sau: string, evp: string, seIpuRegion: string) => {
  const url = `${_fetchCommodityOverview}?sau=${encodeURIComponent(sau)}
  &evp=${encodeURIComponent(evp)}&seIpuRegion=${encodeURIComponent(seIpuRegion)}`;
  return axiosInstanceCPM.get(url);
};

export const fetchVPCommodityOverview = (
  sau: string,
  evp: string,
  seIpuRegion: string,
  ipu: string,
) => {
  const url = `${_fetchCommodityOverview}?sau=${encodeURIComponent(sau)}
  &evp=${encodeURIComponent(evp)}&seIpuRegion=${encodeURIComponent(seIpuRegion)}
  &ipu=${encodeURIComponent(ipu)}`;
  return axiosInstanceCPM.get(url);
};

export const fetchBusinessCommodityOverview = (
  sau: string,
  evp: string,
  seIpuRegion: string,
  ipu: string,
  vp: string,
) => {
  const url = `${_fetchCommodityOverview}?sau=${encodeURIComponent(sau)}
  &evp=${encodeURIComponent(evp)}&seIpuRegion=${encodeURIComponent(seIpuRegion)}
  &ipu=${encodeURIComponent(ipu)}&vp=${encodeURIComponent(vp)}`;
  return axiosInstanceCPM.get(url);
};

export const fetchCarbonIntensityTarget = (region: string, evp: string) => {
  const url = `${_fatchCarbonIntensityTarget}?REGION=${region}&EVP=${evp}`;
  return axiosInstanceCPM.get(url);
};

export const fetchCountryLevelAverageMCR = (region: string, evp: string) => {
  const url = `${countryLevelAverageMCR}?REGION=${region}&EVP=${evp}`;
  return axiosInstanceCPM.get(url);
};

export const fetchplannedVolume = (region: string, evp: string) => {
  const url = `${plannedVolume}?REGION=${region}&EVP=${evp}`;
  return axiosInstanceCPM.get(url);
};
export const fetchCommittedVolume = (region: string, evp: string) => {
  const url = `${committedVolume}?REGION=${region}&EVP=${evp}`;
  return axiosInstanceCPM.get(url);
};
export const fetchbasecaseOverallPortfolio = (region: string, evp: string) => {
  const url = `${fetchBaseCaseOverAllPortfolio}?REGION=${region}&EVP=${evp}`;
  return axiosInstanceCPM.get(url);
};

export const fetchbasecasetabledata = (regions: string[], evp: string) => {
  const regionParams = regions.map((region) => `regions=${region}`).join('&');
  const url = `${_fetchcenariomodelingkpisurl}?${regionParams}&EVP=${evp}`;
  return axiosInstanceCPM.get(url);
};

export const fetchKpiMovedDeals = (region: string, evp: string) => {
  const url = `${getKpiMovedDeals}?REGION=${region}&EVP=${evp}`;
  return axiosInstanceCPM.get(url);
};

export const fetchNetCarbonIntensity = (region: string, evp: string) => {
  const url = `${netCarbonIntensity}?REGION=${region}&EVP=${evp}`;
  return axiosInstanceCPM.get(url);
};

export const getImportedDeals = (region: string, evp: string) => {
  const url = `${importedDeals}?REGION=${region}&EVP=${evp}`;
  return axiosInstanceCPM.get(url);
};
