import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ICpmUserType, IDashboardState, IShowEvpData } from './dashboard.types';
import { IGroupNCI, ImportedDeals, IuserRegionFlags, PlannedVsCommitted } from '../../Types/types';
import { BUSINESS_PLAN_KEY } from '../../constants';

export const initialState: IDashboardState = {
  plannedVolumeData: [],
  committedVolumeData: [],
  nciData: [],
  loggedInUserRegion: '',
  loggedInUserRoles: [''],
  loggedInUserName: '',
  loggedInUserEmail: '',
  importedDeals: [],
  addedImportedDealsId: [],
  userRegionFlags: {
    isAsiaUser: false,
    isAmericasUser: false,
    isAustraliaUser: false,
    isEuropeUser: false,
  },
  isGlobalUser: false,
  isUserRegional: false,
  tabKey: BUSINESS_PLAN_KEY,
  cpmUserType: {
    isUserFromCmfTeam: false,
    isUserSeVpGm: false,
    isUserSetVpGm: false,
  },
  isCpmRegionFieldDisabled: false,
  isCpmEvpFieldDisabled: false,
  showEvpData: {
    showSeData: false,
    showSetData: false,
    showAllData: true,
  },
  userEvp: '',
  showScenarioModellingPage: true,
  isUserGlobalOrSetAsia: false,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setPlannedVolumeData(state, action: PayloadAction<PlannedVsCommitted[]>) {
      state.plannedVolumeData = action.payload;
    },
    setCommittedVolumeData(state, action: PayloadAction<PlannedVsCommitted[]>) {
      state.committedVolumeData = action.payload;
    },
    setNciData(state, action: PayloadAction<IGroupNCI[]>) {
      state.nciData = action.payload;
    },
    setLoggedInUserRegion(state, action: PayloadAction<string>) {
      state.loggedInUserRegion = action.payload;
    },
    setLoggedInUserRoles(state, action: PayloadAction<string | string[]>) {
      state.loggedInUserRoles = action.payload;
    },
    setLoggedInUserName(state, action: PayloadAction<string>) {
      state.loggedInUserName = action.payload;
    },
    setLoggedInUserEmail(state, action: PayloadAction<string>) {
      state.loggedInUserEmail = action.payload;
    },
    setImportedDealsData(state, action: PayloadAction<ImportedDeals[]>) {
      state.importedDeals = action.payload;
    },
    setAddedImportedDealsId(state, action: PayloadAction<number[]>) {
      state.addedImportedDealsId = action.payload;
    },
    setTabKey(state, action: PayloadAction<string>) {
      state.tabKey = action.payload;
    },
    setUserRegionFlags(state, action: PayloadAction<IuserRegionFlags>) {
      state.userRegionFlags = action.payload;
    },
    setCpmUserType(state, action: PayloadAction<ICpmUserType>) {
      state.cpmUserType = action.payload;
    },
    setIsCpmRegionFieldDisabled(state, action: PayloadAction<boolean>) {
      state.isCpmRegionFieldDisabled = action.payload;
    },
    setIsCpmEvpFieldDisabled(state, action: PayloadAction<boolean>) {
      state.isCpmEvpFieldDisabled = action.payload;
    },
    setShowEvpData(state, action: PayloadAction<IShowEvpData>) {
      state.showEvpData = action.payload;
    },
    setUserEvp(state, action: PayloadAction<string>) {
      state.userEvp = action.payload;
    },
    setIsUserRegional(state, action: PayloadAction<boolean>) {
      state.isUserRegional = action.payload;
    },
    setShowScenarioModellingPage(state, action: PayloadAction<boolean>) {
      state.showScenarioModellingPage = action.payload;
    },
    setIsGlobalUser(state, action: PayloadAction<boolean>) {
      state.isGlobalUser = action.payload;
    },
    setIsUserGlobalOrSetAsia(state, action: PayloadAction<boolean>) {
      state.isUserGlobalOrSetAsia = action.payload;
    },
  },
});

export const {
  setPlannedVolumeData,
  setCommittedVolumeData,
  setNciData,
  setLoggedInUserRegion,
  setLoggedInUserRoles,
  setLoggedInUserName,
  setLoggedInUserEmail,
  setImportedDealsData,
  setAddedImportedDealsId,
  setUserRegionFlags,
  setTabKey,
  setCpmUserType,
  setIsCpmRegionFieldDisabled,
  setIsCpmEvpFieldDisabled,
  setShowEvpData,
  setUserEvp,
  setIsUserRegional,
  setShowScenarioModellingPage,
  setIsGlobalUser,
  setIsUserGlobalOrSetAsia,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
